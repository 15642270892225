import React from 'react';
import { Grid } from 'semantic-ui-react'
import { I18n, Auth } from 'aws-amplify';
import CTAButton from '../../components/widgets/button/CTAButton';
import Forms from '../../components/widgets/form/Forms';
import PageGrid from '../../components/widgets/grid/PageGrid';
import PanelGridColumn from '../../components/widgets/grid/PanelGridColumn';
import ApiUtil from "../../components/utils/ApiUtil";
import Moment from 'moment';

import '../../pages/Page.css';
import '../../components/widgets/checkbox/Checkbox.css';
import '../../components/widgets/label/Label.css';

class Summary extends React.Component {
    constructor(props) {
        super(props)
        this.submitButton = React.createRef()
        this.state = {
            privacyPolicy: false,
            ageAgreement: false,
            authAgremment: false,
            validationPass: false
        }
    }

    componentDidMount() {
        this.setState(this.props.location.state);
        //const self = this;
        //self.setState(this.props.location.state);
        /*
        ApiUtil.checkAndHandleLogOut().then(function(response) {
            if(response) {
                self.setState(this.props.location.state);
            } else {
                self.props.history.replace('/signIn');
            }
        });
        */
    }

    handleAcceptButtonClick = () => {
        this.getKits();
    }

    async getKits() {
        this.submitButton.current.setLoading(true)
        this.setState({ errors: [] });

        let chosenAlias = this.state.request.chosenAlias;
        let collectedDate = Moment(this.state.request.collectedDate, 'MMM DD, YYYY').format('MM-DD-YYYY');

        if (collectedDate == 'Invalid date') {
            collectedDate = Moment().format('MM-DD-YYYY');
        }

        let body = {}
        if (this.state.request.profileId === I18n.get('ActivateKitProfileDropdownNewProfile')) {

            body = {
                activationCode: this.state.request.kitCode,
                chosenAlias: this.state.request.chosenAlias,
		        cliaApproved: "",
                collectedDate: collectedDate,
                firstName: this.state.request.firstName,
                lastName: this.state.request.lastName,
                gender: this.state.request.gender,
                dateOfBirth: Moment(this.state.request.dateOfBirth, 'MMM DD, YYYY').format('MM-DD-YYYY'),
                tosConsent: this.state.request.tosConsent,
                tosIsMyself: this.state.request.tosIsMyself,
                tosIsLegallyAuthorized: this.state.request.tosIsLegallyAuthorized,
                tosTimestamp: this.state.request.tosTimestamp,
                researchConsent: this.state.request.researchConsent,
                researchIsMyself: this.state.request.researchIsMyself,
                researchIsLegallyAuthorized: this.state.request.researchIsLegallyAuthorized,
                researchConsentTimestamp: this.state.request.researchConsentTimestamp,
                storeSample: this.state.request.storeSample,
                storeIsMyself: this.state.request.storeIsMyself,
                storeIsLegallyAuthorized: this.state.request.storeIsLegallyAuthorized,
                biobankingTimestamp: this.state.request.biobankingTimestamp
            }

        } else {
            body = {
                activationCode: this.state.request.kitCode,
                profileId: this.state.request.profileId,
                chosenAlias: chosenAlias,
		        cliaApproved: "",
                collectedDate: collectedDate,
                tosConsent: this.state.request.tosConsent,
                tosIsMyself: this.state.request.tosIsMyself,
                tosIsLegallyAuthorized: this.state.request.tosIsLegallyAuthorized,
                tosTimestamp: this.state.request.tosTimestamp,
                researchConsent: this.state.request.researchConsent,
                researchIsMyself: this.state.request.researchIsMyself,
                researchIsLegallyAuthorized: this.state.request.researchIsLegallyAuthorized,
                researchConsentTimestamp: this.state.request.researchConsentTimestamp,
                storeSample: this.state.request.storeSample,
                storeIsMyself: this.state.request.storeIsMyself,
                storeIsLegallyAuthorized: this.state.request.storeIsLegallyAuthorized,
                biobankingTimestamp: this.state.request.biobankingTimestamp
            }
        }

        try {
            await ApiUtil.activateKit(body);
            this.submitButton.current.setLoading(false)
            this.setState({ errors: [] });
            this.props.history.push("/myGenetics");

        } catch (error) {
            this.submitButton.current.setLoading(false)
            this.setState({ errors: error });
            this.checkAndHandleLogOut(error);
        }
    }
    
    checkAndHandleLogOut = (error) => {
        var logout = false
        if (error.data != null) {
            console.error("Error status : " + error.data.status);
            if (error.data.status === 401) {
                logout = true;
            }
        } else if (error.status != null) {
            console.error("Error status : " + error.status);
            if (error.status === 401) {
                logout = true;
            }
        }
        
        if (logout) {
            Auth.signOut()
            .then(() => {
                this.props.history.replace('/signIn');
            })
            .catch(error => {
                console.error(error);
                if (error.code === 'NotAuthorizedException') {
                    this.props.history.replace('/signIn');
                }
            });
        }
    }


    render() {
        const name = this.state.request ? this.state.request.name : ""
        const kitCode = this.state.request ? this.state.request.kitCode : ""
        const gender = this.state.request ? this.state.request.gender : I18n.get('tosppDateodSexUnknown')
        const dateOfBirth = this.state.request ? `${gender}${I18n.get('tosppDateodBirthDes')}${this.state.request.dateOfBirth}` : "";
        const researchConsent = (this.state.request && this.state.request.researchConsent) ? I18n.get("researchPpConsentButton") : I18n.get("researchPpCancelButton");
        const storeSample = (this.state.request && this.state.request.storeSample) ? I18n.get("biobankingConsentButton") : I18n.get("biobankingCancelButton");

        return (
            <PageGrid className="allPage-rootPanel">
                <Grid.Row>
                    <PanelGridColumn>
                        <Forms header={I18n.get('summaryTitle')}>
                            <div className='section24'>
                                <div className="sectionTitleLabel section4">{name}</div>
                                <div className='bodyLabel' hint='true'>{dateOfBirth}</div>
                            </div>

                            <div className='section36'>
                                <div className="section12">
                                    <div className="bodyTitleLabel">{I18n.get("summaryActivationCode")}</div>
                                    <div className='bodyLabel' hint='true'>{kitCode}</div>
                                </div>

                                <div className="section12">
                                    <div className="bodyTitleLabel">{I18n.get("summaryResearchConsent")}</div>
                                    <div className='bodyLabel' hint='true'>{researchConsent}</div>
                                </div>
                            </div>

                            <CTAButton ref={this.submitButton} submit='true' margintop='true' onClick={this.handleAcceptButtonClick}>
                                {I18n.get('summarySubmit')}
                            </CTAButton>

                        </Forms>
                    </PanelGridColumn>
                </Grid.Row>
            </PageGrid>
        )
    }
}

export default Summary;
