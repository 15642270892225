import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './Container.css';
import NavigatorDestop from '../navigator/NavigatorDesktop';
import NavigatorMobile from '../navigator/NavigatorMobile';
import { Responsive } from 'semantic-ui-react'

class ResponsiveContainer extends Component {

    render() {
        return (
            <div className='height-fix'>
                <Responsive minWidth="1024">
                    <NavigatorDestop>{this.props.children}</NavigatorDestop>
                </Responsive>
                <Responsive maxWidth="1023">
                    <NavigatorMobile>{this.props.children}</NavigatorMobile>
                </Responsive>
            </div>
        );
    }
}

ResponsiveContainer.propTypes = {
    children: PropTypes.node
};

export default ResponsiveContainer;