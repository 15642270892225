import { Auth } from 'aws-amplify';
import { I18n, ConsoleLogger as Logger } from '@aws-amplify/core';
import {SelectMFAType, TOTPSetupComp} from "aws-amplify-react";
import {Form, Message} from "semantic-ui-react";
import * as React from "react";
import { Header, Container, Label } from 'semantic-ui-react'
import QRCode from 'qrcode.react';
import Input from "../Input/Input";

import { totpQrcode } from '@aws-amplify/ui';
import CTAButton from "../button/CTAButton";

const logger = new Logger('CustomTOTPSetupComp');

export class CustomTOTPSetupComp extends TOTPSetupComp
{

    componentDidMount() {
        TOTPSetupComp.prototype.setup.call(this);
    }

    handleInputChange(evt) {
        this.setState({ setupMessage: null });
        this.inputs = {};
        const { name, value, type, checked } = evt.target;
        // @ts-ignore
        const check_type = ['radio', 'checkbox'].includes(type);
        console.log('mj', evt.target)
        this.inputs[name] = check_type ? checked : value;

        console.log('mj', this.inputs)
    }

    showSecretCode(code) {
        if (!code) return null;
        console.log('code', code);
        return (
            <div>
                <Form.Field>
                    <div className={totpQrcode}>
                        <QRCode value={code} />
                    </div>
                </Form.Field>
                <Form.Field>
                    <Input autoFocus key="totpCode" type='text' name="totpCode" placeholder={I18n.get('Enter Security Code')} onChange={this.handleInputChange} />
                </Form.Field>
            </div>
        );
    }

    verifyTotpToken() {
        if (!this.inputs) {
            logger.debug('no input');
            return;
        }
        const user = this.props.authData;
        const { totpCode } = this.inputs;
        if (
            !Auth ||
            typeof Auth.verifyTotpToken !== 'function' ||
            typeof Auth.setPreferredMFA !== 'function'
        ) {
            throw new Error(
                'No Auth module found, please ensure @aws-amplify/auth is imported'
            );
        }
        return Auth.verifyTotpToken(user, totpCode)
            .then(() => {
                // set it to preferred mfa
                return Auth.setPreferredMFA(user, 'TOTP')
                    .then(() => {
                        console.log('mj', 'set to TOTP');
                        this.setState({ setupMessage: 'Setup TOTP successfully!' });
                        logger.debug('set up totp success!');
                        this.triggerTOTPEvent('Setup TOTP', 'SUCCESS', user);
                    })
                    .catch(err => {
                        console.log('mj', err)
                        this.setState({ setupMessage: 'Setup TOTP failed!' });
                        logger.error(err);
                    });
            })
            .catch(err => {
                this.setState({ setupMessage: 'Setup TOTP failed!' });
                logger.error(err);
            });
    }

    render() {
        const code = this.state.code;
        const theme = '';

        return (
            <React.Fragment>
                <Header as='h2'>
                    {I18n.get('Scan then enter verification code')}
                </Header>
                <Form.Field>
                    {this.showSecretCode(code, theme)}
                </Form.Field>
                <Form.Field>
                    <CTAButton onClick={this.verifyTotpToken}>
                        {I18n.get('Verify Security Token')}
                    </CTAButton>
                </Form.Field>
                {this.state.setupMessage && (
                    <Form.Field>
                        <Message color='red'>
                            {I18n.get(this.state.setupMessage)}
                        </Message>
                    </Form.Field>
                )}
            </React.Fragment>
        );
    }

}

export default CustomTOTPSetupComp;