import React, { Component } from 'react';
import { Auth, JS } from 'aws-amplify/lib/index';
import { withRouter } from 'react-router-dom';
import { Grid, Segment } from 'semantic-ui-react';
import {Hub, I18n} from 'aws-amplify';
import ApiUtil from "../components/utils/ApiUtil";
import CTAButton from '../components/widgets/button/CTAButton';
import PageGrid from '../components/widgets/grid/PageGrid';
import PanelGridColumn from '../components/widgets/grid/PanelGridColumn';
import ResearchAuthCard from '../components/widgets/card/reserchAuthCard/ResearchAuthCard';
import AccountInfoCard from '../components/widgets/card/accountInfoCard/AccountInfoCard';

import '../pages/policy/Policy.css';
import './Account.css';

class Account extends Component {
    state = {
        user: null,
        kitProfileList: [],
        emailVerified: '',
        disableDeleteAccount: true,
        mfaStatus: null
    };

    constructor(props, context) {
        super(props, context);
        this.handleResetPassword = this.handleResetPassword.bind(this);
        this.handleChangeEmail = this.handleChangeEmail.bind(this);
        this.handleDeleteAccount = this.handleDeleteAccount.bind(this);
        this.handleSetMFA = this.handleSetMFA.bind(this);
    }

    componentWillMount() {
        const self = this;
        ApiUtil.checkAndHandleLogOut().then(function(response) {
            if(response) {
                self.getUser();
                self.fetchMyProfile();
            } else {
                self.props.history.replace('/signIn');
            }
        });
    }

    getUser = async () => {
        try {
            const user = await Auth.currentAuthenticatedUser();
            if (!user) {
                return;
            }
            const data = await Auth.verifiedContact(user);
            if (!JS.isEmpty(data.verified)) {
                this.setState({ user: user, emailVerified: I18n.get('emailVerified'), disableDeleteAccount: false });
            } else {
                this.setState({ user: user, emailVerified: I18n.get('emailNotVerified'), disableDeleteAccount: true });
            }

            await Auth.getPreferredMFA(user).then((data) => {
                //console.log('getPreferredMFA', data)
                this.setState({ mfaStatus: I18n.get('account' + data) });
            })
        } catch (e) {
            console.error(e);
            this.setState({ user: null });
            this.checkAndHandleLogOut(e);
        }
    }

    fetchMyProfile = async () => {
        try {
            /*const response = ApiUtil.initProfile().then(function(data) {
                this.setState({
                    kitProfileList: data
                });
                Hub.dispatch( 'loadEducation', {});
            }.bind(this));*/
            const response = await ApiUtil.initProfile();

            await this.setState({
                kitProfileList: response
            });
            Hub.dispatch( 'loadEducation', {});


        } catch (error) {
            //console.log('fetchMyProfile', error);
            this.checkAndHandleLogOut(error);
        }
    }

    checkAndHandleLogOut = (error) => {
        var logout = false
        if (error.data != null) {
            console.error("Error status : " + error.data.status);
            if (error.data.status === 401) {
                logout = true;
            }
        } else if (error.status != null) {
            console.error("Error status : " + error.status);
            if (error.status === 401) {
                logout = true;
            }
        }
        
        if (logout) {
            Auth.signOut()
            .then(() => {
                this.props.history.replace('/signIn');
            })
            .catch(error => {
                console.error(error);
                if (error.code === 'NotAuthorizedException') {
                    this.props.history.replace('/signIn');
                }
            });
        }
    }

    handleResetPassword() {
        Auth.forgotPassword(this.state.user.attributes.name)
            .then(data => {
                this.props.history.entries = [];
                this.props.history.index = -1;
                this.props.history.push('/resetAccountPassword');
            })
            .catch(err => {
                this.setState({ errors: [err.message] });
            });
    }

    handleChangeEmail() {
        this.props.history.push('/changeEmail');
    }

    handleDeleteAccount() {
        this.props.history.push('/deleteAccountVerify');
    }

    handleSetMFA() {
        this.props.inputs.user = this.state.user;
        this.props.history.push('/changeMFAStatus');
    }

    renderKitProfileCard = () => {
        const researchAuthCardList = this.state.kitProfileList ? this.state.kitProfileList.map((kitProfile, index) => {
            return (
                <Grid.Row key={index} className='researchAuthCard-gridRow'>
                    <ResearchAuthCard kitProfile={kitProfile} onClick={() => {
                        this.props.history.push({
                            pathname: '/updateResearchConsent',
                            state: {
                                kitProfile: kitProfile
                            }
                        });
                    }} />
                </Grid.Row>
            )
        }) : null;
        return researchAuthCardList;
    }

    renderUserInFoCard = () => {
        const username = this.state.user
            ? this.state.user.attributes.name
            : '';

        const email = this.state.user
            ? this.state.user.attributes.email
            : '';

        return (
            <div>
                <AccountInfoCard title={I18n.get('accountUserName')} subTitle={username} />

                <AccountInfoCard title={I18n.get('accountEmail')} subTitle={email} subTitleDec={this.state.emailVerified}
                    isConfigurable='true' onClick={this.handleChangeEmail} />

                <AccountInfoCard title={I18n.get('accountPassword')} subTitle={I18n.get('accountPasswordHint')}
                    isConfigurable='true' onClick={this.handleResetPassword} />

                <AccountInfoCard title={I18n.get('accountMFA')} subTitle={this.state.mfaStatus}
                     isConfigurable='true' onClick={this.handleSetMFA} />
            </div>

        )
    }

    render() {
        return (
            <Segment vertical className="allPage-rootSegment">
                <PageGrid className="allPage-rootPanel">
                    <Grid.Row columns={2}>
                        <PanelGridColumn margin='true' className='accountPageGridColumn section36'>
                            <Segment basic>
                                <Grid columns={2}>
                                    <Grid.Row className='titleCoulum section24'>
                                        <Grid.Column textAlign='left'>
                                            <div className='sectionTitleLabel'>{I18n.get('accountTitle')}</div>
                                            <div className='bodyLabel' hint='true'>{I18n.get('accountSubTitle')}</div>
                                        </Grid.Column>
                                        <Grid.Column textAlign='right' className='gridColumn'>
                                            <CTAButton disabled={this.state.disableDeleteAccount} warning='true' small='true'
                                                onClick={this.handleDeleteAccount}>
                                                {I18n.get('deleteAccountButton')}
                                            </CTAButton>
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                            </Segment>
                            {this.renderUserInFoCard()}
                        </PanelGridColumn>
                        <PanelGridColumn margin='true' className='accountPageGridColumn'>
                            <Segment basic>
                                <Grid>
                                    <Grid.Row className='titleCoulum section24' >
                                        <Grid.Column>
                                            <div className='sectionTitleLabel'>{I18n.get('accountProfileTitle')}</div>
                                            <div className='bodyLabel' hint='true'>{I18n.get('accountProfileSubTitle')}</div>
                                        </Grid.Column>
                                    </Grid.Row>

                                    {this.renderKitProfileCard()}

                                </Grid>
                            </Segment>
                        </PanelGridColumn>
                    </Grid.Row>
                </PageGrid>
            </Segment>
        );
    }
}

export default withRouter(Account);
