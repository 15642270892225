import React from 'react';
import PropTypes from 'prop-types';
import { Form, Image } from 'semantic-ui-react';
import './Form.css';

class Forms extends React.Component {
    render() {
        const className = (this.props.className) ? this.props.className : "";
        const imageName = (this.props.size === 'large') ? "../images/Header-Dialog684.png" : "../images/Header-Dialog492.png";
        return (
            <Form {...this.props} className={className} size="large">
                <div className='form-headerPanel'>
                    <Image src={imageName} />
                    <div className='form-header' as='h1'>{this.props.header}</div>
                </div>

                <div className='form-contentPanel'>
                    {this.props.children}
                </div>
            </Form>
        )
    }
}

Forms.prototypes = {
    className: PropTypes.string,
    header: PropTypes.string,
    size: PropTypes.string,
};

export default Forms;