import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Image } from 'semantic-ui-react'
import { I18n } from 'aws-amplify';
import Moment from 'moment';

import '../Card.css';

class ResearchAuthCard extends React.Component {
    render() {
        const profileName = `${this.props.kitProfile.firstName} ${this.props.kitProfile.lastName}`
        const birthDate = Moment(this.props.kitProfile.birthDate, 'MM-DD-YYYY').format('MMMM DD, YYYY')
        const bornText = `${I18n.get('researchAuthCardBorn')} ${this.props.kitProfile.gender} ${birthDate}`
        const authStatusPath = this.props.kitProfile.researchConsent ? './images/icon-selected.svg' : './images/icon-unselected.svg';
        const researchAuthText = this.props.kitProfile.researchConsent ? `${I18n.get('researchAuthCardResearchAuth')}` : `${I18n.get('researchAuthCardResearchNotAuthorized')}`;
        return (
            <Grid className="formCard">
                <Grid.Row columns={2} className='padding0'>
                    <Grid.Column width={9}>
                        <div className='bodyTitleLabel'>{profileName}</div>
                        <div className='fit-content'> 
                            <div className='bodyLabel'>{bornText}</div>
                        </div>
                        <div className='fit-content'>
                            <Image className='card-icon marginRight4' src={authStatusPath}/>
                            <div className='bodyLabel card-statusLabel'>{researchAuthText}</div>
                        </div>
                    </Grid.Column>

                    <Grid.Column width={2} floated='right'>
                        <Image className='card-icon icon-clickable authCardWrenchIcon' src={'../images/icon-configure.svg'} onClick={() =>  this.props.onClick(this.props.kitProfile)}/>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        );
    }
}

ResearchAuthCard.prototypes = {
    kitProfile: PropTypes.object,
    onClick: PropTypes.func
};

export default ResearchAuthCard;
