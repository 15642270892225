import React, { Component } from 'react';
import { I18n } from 'aws-amplify';
import { Grid, Loader } from 'semantic-ui-react';
import { NavLink as Link, withRouter } from 'react-router-dom';

import PageGrid from '../../widgets/grid/PageGrid';
import PanelGridColumn from '../../widgets/grid/PanelGridColumn';
import Forms from '../../widgets/form/Forms';

class LinkExpired extends Component {

    constructor(props) {
        super(props);
        this.state = {
            username: '',
            resendLoading: ''
        }
    }

    render() {
        return (
            <PageGrid className="allPage-rootPanel">
                {
                    this.state.VerifyCodeLoader &&
                    <Loader style={{ marginTop: 50 }} active />
                }
                <Grid.Row>
                    <PanelGridColumn>
                        <Forms className='section18' header={I18n.get('LinkExpiredTitle')}>
                            <div className='bodyLabel section12'>
                                {I18n.get('LinkExpiredDescription1')}
                                <Link className='link ' to='/signIn'>{I18n.get('createAccountConfirmationSignIn')}</Link>
                                {I18n.get('LinkExpiredDescription2')}
                            </div>

                            <div className='bodyLabel'>{I18n.get('createAccountConfirmationMsg3Text')}</div>
                            <a className='blueLink textAlignLeft' href='mailto:support@strategene.me'>
                                {I18n.get('createAccountConfirmationSupport')}
                            </a>
                        </Forms>

                        <Link className='blueLink' to='/signIn'>{I18n.get('createAccountConfirmationSignIn')}</Link>

                    </PanelGridColumn>
                </Grid.Row>
            </PageGrid>
        );
    }
}

export default withRouter(LinkExpired);
