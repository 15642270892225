import React, { Component } from 'react';
import { Auth } from 'aws-amplify/lib/index';
import { I18n } from 'aws-amplify';
import { Grid, Message, Image } from 'semantic-ui-react';
import { NavLink as Link, withRouter } from "react-router-dom";
import Input from '../../widgets/Input/Input';
import ApiUtil from "../../utils/ApiUtil";
import CTAButton from '../../widgets/button/CTAButton';
import PageGrid from '../../widgets/grid/PageGrid';
import PanelGridColumn from '../../widgets/grid/PanelGridColumn';
import Forms from '../../widgets/form/Forms';

import './SignIn.css';
import {Hub} from "aws-amplify/lib";

class SignIn extends Component {

    constructor(props, context) {
        super(props, context);

        this.passwordInput = React.createRef();
        this.submitButton = React.createRef();

        this.state = {
            signedIn: null,
            verifyContact: null,
            user: this.props.user,
            inputEmail: '',
            inputPassword: '',
            errors: [],
            aws_exports: '',
            code: '',
            username: '',
            status: ''
        };
        this.signIn = this.signIn.bind(this);
        this.handleChange = this.handleChange.bind(this);

    }

    componentDidMount() {
        this.setState(this.props.location.state);
    }

    componentWillUnmount() {
        this.setState = (state, callback) => {
            return;
        };
    }

    handleChange(event) {
        this.setState({
            [event.target.id]: event.target.value
        });
    }

    triggerAuthEvent(event) {
        const state = this.props.authState;
        if (this.props.onAuthEvent) {
            this.props.onAuthEvent(state, event);
        }
    }

    changeState(state, data) {
        if (this.props.onStateChange) {
            this.props.onStateChange(state, data);
        }
        this.triggerAuthEvent({
            type: 'stateChange',
            data: state
        });
    }

    async signIn(event) {
        // avoid submitting the form
        event.preventDefault();

        localStorage.clear();

        const username = this.state.inputEmail;
        const password = this.state.inputPassword;

        if (!Auth || typeof Auth.signIn !== 'function') {
            throw new Error('No Auth module found, please ensure @aws-amplify/auth is imported');
        }

        // Clean the error message
        this.submitButton.current.setLoading(true);
        this.setState({ errors: [] });

        try {
            /*
            Auth.signIn(username, password).then(user => {
                console.log('user', user);
            }).catch({

            }).finally({

            });
            */

            const user = await Auth.signIn(username, password);
            if (user.challengeName === 'SMS_MFA' || user.challengeName === 'SOFTWARE_TOKEN_MFA') {
                this.changeState('confirmSignIn', user);
                this.props.switchComponent('confirmSignIn', user);
                this.props.history.push('/confirmSignIn');
            } else if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
                this.changeState('requireNewPassword', user);
            } else if (user.challengeName === 'MFA_SETUP') {
                this.changeState('TOTPSetup', user);
            } else {
                this.setState({ user: user });
                // Update the new idToken for new login user
                await ApiUtil.updateIdToken();
                
                localStorage.setItem('innerFlag', true);
                sessionStorage.setItem('innerFlag', true);
                sessionStorage.setItem('userSubId', user.attributes.sub);

                Hub.dispatch( 'userLoggedIn', {});

                // When the per page is come from change email, it will redirect to change email page
                if (this.state.status !== undefined && this.state.status === 'chnageEmail') {
                    this.props.history.replace('', null)
                    this.props.history.push(`/changeEmailLanding?code=${this.state.code}&username=${this.state.username}`);
                } else if (this.state.status !== undefined && this.state.status === 'activate') {
                    this.props.history.replace('', null)
                    this.props.history.push('/activate');
                } else if (user.attributes['custom:custom_attribute1'] != null) {
                    var attributes = JSON.parse(user.attributes['custom:custom_attribute1']);
                    if (attributes['status'] != null && attributes['status'] === 'activate') {
                        await Auth.updateUserAttributes(user, {
                            'custom:custom_attribute1': ''
                        });
                        await ApiUtil.initProfile();
                        this.props.history.replace('', null)
                        this.props.history.push('/activate');
                    } else {
                        this.props.switchComponent('SignedIn');
                        if(ApiUtil.state.redirectAfterLogin) {
                            this.props.history.replace(ApiUtil.state.redirectAfterLogin);
                            ApiUtil.state.redirectAfterLogin = '';
                        } else {
                            this.props.history.push('/myGenetics');
                        }
                    }
                } else {
                    this.props.switchComponent('SignedIn');
                    if(ApiUtil.state.redirectAfterLogin) {
                        this.props.history.replace(ApiUtil.state.redirectAfterLogin);
                        ApiUtil.state.redirectAfterLogin = '';
                    } else {
                        this.props.history.push('/myGenetics');
                    }
                }
            }
        } catch (err) {
            if (err.code === 'UserNotConfirmedException') {
                this.props.switchComponent('confirmSignUp', username);
                this.props.history.push('/confirmation');
            } else if (err.code === 'PasswordResetRequiredException') {
                this.props.switchComponent('forgotPassword', username);
                this.changeState('forgotPassword', { username });
            } else if (err.code === 'UserNotFoundException' || err.code === 'NotAuthorizedException') {
                this.passwordInput.current.setError(true, I18n.get('loginErrorNotAuthorizedExceptionText'));
            } else {
                if (err.message === undefined) {
                    this.setState({ errors: [err] });
                } else {
                    this.setState({ errors: [err.message] });
                }
            }
            this.checkAndHandleLogOut(err);

        } finally {
            if (this.submitButton.current !== null) {
                this.submitButton.current.setLoading(false);
            }
        }
    }
    
    checkAndHandleLogOut = (error) => {
        var logout = false
        if (error.data != null) {
            console.error("Error status : " + error.data.status);
            if (error.data.status === 401) {
                logout = true;
            }
        } else if (error.status != null) {
            console.error("Error status : " + error.status);
            if (error.status === 401) {
                logout = true;
            }
        }
        
        if (logout) {
            Auth.signOut()
            .then(() => {
                this.props.history.replace('/signIn');
            })
            .catch(error => {
                console.error(error);
                if (error.code === 'NotAuthorizedException') {
                    this.props.history.replace('/signIn');
                }
            });
        }
    }

    render() {
        const showErrorMessage = (this.state.errors.length === 0) ? { display: "none" } : { display: "" };
        return (
            <PageGrid className="allPage-rootPanel">
                <Grid.Row>
                    <PanelGridColumn>
                        <Image className='signin-logo' src='../../images/StrateGene-logo.svg' />
                        <Forms className='section18' header={I18n.get('login')} onSubmit={this.signIn}>

                            <Input type='text' id='inputEmail' placeholder={I18n.get('loginUserNameText')}
                                name={I18n.get('loginUserNameText')} onChange={this.handleChange} autoCapitalize='none' />

                            <Input ref={this.passwordInput} type='password' id='inputPassword' placeholder={I18n.get('loginPasswordText')}
                                name={I18n.get('loginPasswordText')} onChange={this.handleChange} />

                            <Link className='blueLink textAlignLeft' to='/resetPassword'>{I18n.get('forgotPassword')}</Link>
                            <Link className='blueLink textAlignLeft section36' to='/recoverUsername'>{I18n.get('forgotUsername')}</Link>

                            <CTAButton ref={this.submitButton} submit='true' disabled={!this.state.inputEmail || !this.state.inputPassword}> {' '}
                                {I18n.get('login')}
                            </CTAButton>

                            <Message color='red' style={showErrorMessage}>
                                {this.state.errors}
                            </Message>

                        </Forms>

                        <div className='bodyLabel textAlignCenter'>
                            {I18n.get('loginNewStrateText')}<sup>®</sup>?&nbsp;
                            <Link className='blueLink' to='/signUp'>{I18n.get('loginCreateAccountText')}</Link>
                        </div>
                    </PanelGridColumn>
                </Grid.Row>
            </PageGrid>
        );
    }
}

export default withRouter(SignIn);
