import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'semantic-ui-react';
import './Button.css';

class CTAButton extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoadding: false
        }
    }

    setLoading = (loading) => {
        this.setState({loading: loading})
    }

    render() {
        const styleClassName = this.props.submit ? 'form-submitButton' : 'ctaButton'
        const colorClassName = this.props.color === 'red' ? `${styleClassName}-red` : styleClassName;
        const sizeClassName = (this.props.size === 'small') ? `${colorClassName}-small` : colorClassName;
        const className = (this.props.className) ? `${sizeClassName} ${this.props.className}` : sizeClassName;

        return (
            <Button {...this.props} loading={this.state.loading} disabled={this.state.loading || this.props.disabled} className={className} />
        )
    }
}

CTAButton.prototypes = {
    className: PropTypes.string,
    size: PropTypes.string,
    submit: PropTypes.bool
};

export default CTAButton;