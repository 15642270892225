import React from 'react';
import { Form, Grid, Radio, Checkbox } from 'semantic-ui-react'
import { I18n } from 'aws-amplify';
import { Auth } from 'aws-amplify/lib/index';
import CTAButton from '../components/widgets/button/CTAButton';
import Dropdowns from '../components/widgets/dropdown/Dropdowns';
import Forms from '../components/widgets/form/Forms';
import Input from "../components/widgets/Input/Input";
import PageGrid from '../components/widgets/grid/PageGrid';
import PanelGridColumn from '../components/widgets/grid/PanelGridColumn';
import ApiUtil from '../components/utils/ApiUtil';

class ShareResult extends React.Component {
    constructor(props) {
        super(props);
        this.usernameInput = React.createRef();
        this.profileDropdown = React.createRef();
        this.shareButton = React.createRef();
        this.state = {
            username: '',
            profileList: [],
            profileDropdownOptions: [],
            selectedKitId: '',
            sgReportAggrement: true,
            sgReportAndRawDataAggrement: false,
            sharingPolicy: false,
            rawDataAggrement: false,
            validationPass: false
        }
    }

    componentDidMount() {
        const self = this;
        ApiUtil.checkAndHandleLogOut().then(function(response) {
            if(response) {
                self.setState({ ...self.props });
            } else {
                self.props.history.replace('/signIn');
            }
        });
    }

    handleUsernameInput = async (event) => {
        this.checkIfStillLoggedIn();
        event.preventDefault();
        await this.setState({ [event.target.id]: event.target.value });
        this.checkValidation();
    }

    handleProfileDropdownOnClick = async () => {
        this.checkIfStillLoggedIn();
        await this.fetchKitProfiles();
    }

    handleProfileDropdownOnChange = async (event, data) => {
        this.checkIfStillLoggedIn();
        await this.setState({ selectedKitId: data.value });
    }

    handlesgReportAggrementChange = async (event) => {
        this.checkIfStillLoggedIn();
        await this.setState(prevState => ({
            sgReportAggrement: !prevState.sgReportAggrement,
            sgReportAndRawDataAggrement: !prevState.sgReportAndRawDataAggrement,
            rawDataAggrement: (!prevState.sgReportAndRawDataAggrement) ? prevState.rawDataAggrement : !prevState.sgReportAndRawDataAggrement
        }));
        this.checkValidation();
    }

    handlesgReportAndRawDataAggrementChange = async () => {
        this.checkIfStillLoggedIn();
        await this.setState(prevState => ({
            sgReportAggrement: !prevState.sgReportAggrement,
            sgReportAndRawDataAggrement: !prevState.sgReportAndRawDataAggrement,
            rawDataAggrement: (!prevState.sgReportAndRawDataAggrement) ? prevState.rawDataAggrement : !prevState.sgReportAndRawDataAggrement
        }));
        this.checkValidation();
    };

    handleSharingPolicyChange = async () => {
        this.checkIfStillLoggedIn();
        await this.setState(prevState => ({ sharingPolicy: !prevState.sharingPolicy }));
        this.checkValidation();
    };

    handleRawDataAggrementChange = async () => {
        this.checkIfStillLoggedIn();
        await this.setState(prevState => ({ rawDataAggrement: !prevState.rawDataAggrement }));
        this.checkValidation();
    };

    handleShareButtonClick = async () => {
        this.checkIfStillLoggedIn();
        this.shareButton.current.setLoading(true);

        const user = await Auth.currentUserInfo();

        if (this.state.username === user.username) {
            this.usernameInput.current.setError(true, I18n.get('shareResultShareKitToYourselfErrorMsg'));
            this.shareButton.current.setLoading(false);
            return
        }

        const body = {
            userName: this.state.username,
            kitId: this.state.selectedKitId,
            reportShared: true,
            rawDataShared: this.state.sgReportAndRawDataAggrement
        }

        try {
            var xmlHttp = new XMLHttpRequest();
            var shareRaw = "false";
            if (this.state.sgReportAndRawDataAggrement) {
                shareRaw = "true";
            }
            xmlHttp.open( "GET", 'https://clia-approval.strategene-dashboard.montanab.com/send-share/'+this.state.selectedKitId+'/'+this.state.username+'/'+shareRaw, false); // false for synchronous request
            xmlHttp.send( null );
            await ApiUtil.shareKit(body);
            this.shareButton.current.setLoading(false);
            this.props.history.goBack();

        } catch (error) {
            console.error(error);
            if (error.status === 400 || error.status === 404) {
                this.usernameInput.current.setError(true, I18n.get('shareResultQ1Error'));
            }
            this.shareButton.current.setLoading(false);
            this.checkAndHandleLogOut(error);
        }
    }
    
    checkIfStillLoggedIn() {
        if (localStorage.getItem('innerFlag') === null || localStorage.getItem('innerFlag') === false) {
            Auth.signOut()
            .then(() => {
                this.props.history.replace('/signIn');
            })
            .catch(error => {
                console.error(error);
                if (error.code === 'NotAuthorizedException') {
                    this.props.history.replace('/signIn');
                }
            });
        } 
    }
    
    checkAndHandleLogOut = (error) => {
        var logout = false
        if (error.data != null) {
            console.error("Error status : " + error.data.status);
            if (error.data.status === 401) {
                logout = true;
            }
        } else if (error.status != null) {
            console.error("Error status : " + error.status);
            if (error.status === 401) {
                logout = true;
            }
        }
        
        if (logout) {
            Auth.signOut()
            .then(() => {
                this.props.history.replace('/signIn');
            })
            .catch(error => {
                console.error(error);
                if (error.code === 'NotAuthorizedException') {
                    this.props.history.replace('/signIn');
                }
            });
        }
    }

    fetchKitProfiles = async () => {
        this.profileDropdown.current.setLoadding(true);
        try {
            const response = await ApiUtil.getKitAndProfileJointList();
            const profileDropdownOptions = [];
            response.forEach(profile => {
                if (profile.currentStatus === "REPORTS_READY") {
                    profileDropdownOptions.push({ key: profile.id, text: `${profile.firstName} ${profile.lastName}`, value: profile.id });
                }
            });

            this.setState({
                profileList: response,
                profileDropdownOptions: profileDropdownOptions
            });
            this.profileDropdown.current.setLoadding(false);

        } catch (error) {
            console.error(error);
            this.profileDropdown.current.setLoadding(false);
            this.checkAndHandleLogOut(error);
        }
    }

    checkValidation = () => {
        this.setState({
            validationPass:
                this.state.username.length > 0
                && this.state.selectedKitId.length > 0
                && (this.state.sgReportAggrement || this.state.sgReportAndRawDataAggrement)
                && (this.state.sgReportAndRawDataAggrement === this.state.rawDataAggrement)
                && this.state.sharingPolicy
        });
    }

    render() {
        return (
            <PageGrid>
                <Grid.Row>
                    <PanelGridColumn>
                        <Forms header={I18n.get('shareResultTitle')} onSubmit={this.handleAcceptButtonClick}>
                            <div className="bodyTitleLabel">{I18n.get('shareResultQ1')}</div>
                            <Input ref={this.usernameInput} type="text" id="username"
                                placeholder={I18n.get('shareResultUserName')} name={I18n.get('shareResultUserName')}
                                onChange={this.handleUsernameInput} />
                            <div className='bodyLabel section24' hint='true'> {I18n.get('shareResultUsernameDes')} </div>

                            <div className="bodyTitleLabel">{I18n.get('shareResultQ2')}</div>
                            <Form.Field className="form-label-group section24">
                                <Dropdowns ref={this.profileDropdown} selection
                                    options={this.state.profileDropdownOptions}
                                    placeholder={I18n.get('shareResultSelectProfile')}
                                    pointing='top right'
                                    onClick={this.handleProfileDropdownOnClick}
                                    onChange={this.handleProfileDropdownOnChange}
                                />
                            </Form.Field>

                            <div className="bodyTitleLabel">{I18n.get('shareResultQ3')}</div>
                            <Grid textAlign='left' verticalAlign='top'>
                                <Grid.Row>
                                    <Grid.Column>
                                        <Form.Field>
                                            <Radio radio label={<label dangerouslySetInnerHTML={{__html: I18n.get('shareResultQ3SharestrateGeneReport')}}></label>} checked={this.state.sgReportAggrement} onClick={this.handlesgReportAggrementChange} />
                                        </Form.Field>

                                        <Form.Field>
                                            <Radio radio label={<label dangerouslySetInnerHTML={{__html: I18n.get('shareResultQ3ShareSGRowData')}}></label>} checked={this.state.sgReportAndRawDataAggrement} onClick={this.handlesgReportAndRawDataAggrementChange} />
                                        </Form.Field>
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>

                            <div className="bodyLabel section24" hint='true'>{I18n.get('shareResultQ3Des')}</div>

                            <Grid className="section16" textAlign='left' verticalAlign='top'>
                                <Grid.Row>
                                    <Grid.Column>
                                        <Checkbox onChange={this.handleSharingPolicyChange}
                                            checked={this.state.privacyPolicy} id="sharingPolicy"
                                            label={<label className='bodyLabel'>{I18n.get('shareResultQ3Aggrement')}
                                                <a rel="noopener noreferrer" className='blueLink' target='_blank' href={ApiUtil.getSharingPolicyUrl()}>{I18n.get('sharingResultSharingPolicy')}</a>
                                            </label>}>
                                        </Checkbox>

                                        <Checkbox onChange={this.handleRawDataAggrementChange}
                                            checked={this.state.rawDataAggrement} disabled={!this.state.sgReportAndRawDataAggrement} id="rawDataAggrement"
                                            label={<label className='bodyLabel'>{I18n.get('shareResultRawDataAggrement')}</label>}>
                                        </Checkbox>
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>

                            <CTAButton ref={this.shareButton} submit='true' onClick={this.handleShareButtonClick}
                                disabled={!this.state.validationPass}>{I18n.get('shareResultShare')}
                            </CTAButton>

                        </Forms>
                    </PanelGridColumn>
                </Grid.Row>
            </PageGrid>

        )
    }
}

export default ShareResult;
