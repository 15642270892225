import React, { Component } from 'react';
import { Auth } from 'aws-amplify/lib/index';
import { I18n } from 'aws-amplify';
import { Grid, Message } from 'semantic-ui-react';
import { withRouter } from 'react-router-dom';
import ValidationUtil from '../../utils/ValidationUtil';
import CTAButton from '../../widgets/button/CTAButton';
import CancelButton from '../../widgets/button/CancleButton';
import Input from '../../widgets/Input/Input';
import PageGrid from '../../widgets/grid/PageGrid';
import PanelGridColumn from '../../widgets/grid/PanelGridColumn';
import Forms from '../../widgets/form/Forms';
import ApiUtil from "../../utils/ApiUtil";

class ChangeEmail extends Component {
    constructor(props) {
        super(props);
        this.emailInput = React.createRef();
        this.passwordInput = React.createRef();
        this.submitButton = React.createRef();
        this.state = {
            email: '',
            currentEmail: '',
            password: '',
            isFirstTimeValidation: false,
            validationPass: true,
            errors: [],
        };
        this.handleFormInput = this.handleFormInput.bind(this);
        this.changeEmailSubmit = this.changeEmailSubmit.bind(this);
        this.handleGoBack = this.handleGoBack.bind(this);
    }

    componentDidMount() {
        const self = this;
        ApiUtil.checkAndHandleLogOut().then(function(response) {
            if(response) {
                Auth.currentAuthenticatedUser()
                    .then(user => {
                        if (user != null) {
                            self.setState({ currentEmail: user.attributes.email });
                        }
                    })
                    .catch(err => {
                        self.setState({ user: null });
                    });
            } else {
                self.props.history.replace('/signIn');
            }
        });

    }

    async changeEmailSubmit(event) {
        event.preventDefault();
        await this.setState({ errors: [], isFirstTimeValidation: true });

        await this.checkValidation();
        if (!this.state.validationPass) {
            return;
        }

        let result;
        try {
            this.submitButton.current.setLoading(true);
            this.setState({ errors: [] });
            const user = await Auth.currentAuthenticatedUser();
            const signInUser = await Auth.signIn(user.attributes.name, this.state.password);
            console.debug(signInUser)
            result = await Auth.updateUserAttributes(signInUser, { email: this.state.email });

            // Signed user out and then sign in user to update the user information in browser local storage
            await Auth.signOut();
            const signInUserAgain = await Auth.signIn(user.attributes.name, this.state.password);
            console.debug(signInUserAgain)

        } catch (err) {
            if (err.message === undefined) {
                this.setState({ errors: [err] });
            } else {
                this.setState({ errors: [err.message] });
            }
            this.submitButton.current.setLoading(false);

        } finally {
            if (this.submitButton.current !== null) {
                this.submitButton.current.setLoading(false);
            }
            
            if (result === 'SUCCESS') {
                this.props.switchComponent('changeEmail', this.state.email);
                this.props.history.entries = [];
                this.props.history.index = -1;
                this.props.history.push('/changeEmailConfirmation');
            }
        }
    }

    handleFormInput = async event => {
        event.preventDefault();

        await this.setState({
            [event.target.id]: event.target.value,
        });
        this.checkValidation();
    };

    checkValidation = async () => {
        // Don't validate any field until user click submit button at the first time.
        if (!this.state.isFirstTimeValidation) {
            return;
        }

        const emailValidation = await ValidationUtil.validateEmail(this.state.email);
        const passwordValidation = await ValidationUtil.validatePassword(this.state.password);
        this.emailInput.current.setError(!emailValidation.status, emailValidation.msg);
        this.passwordInput.current.setError(!passwordValidation.status, passwordValidation.msg);
        await this.setState({
            validationPass: emailValidation.status && passwordValidation.status,
        });
    };

    handleGoBack() {
        this.props.history.goBack();
    }

    render() {
        const isActivateButtonDisabled = !this.state.validationPass || !this.state.email || !this.state.password;
        const showErrorMessage = this.state.errors.length === 0 ? { display: 'none' } : { display: '' };
        return (
            <PageGrid className="allPage-rootPanel">
                <Grid.Row>
                    <PanelGridColumn>
                        <Forms header={I18n.get('changeEmailTitle')} >

                            <div className='bodyTitleLabel'>{I18n.get('changeEmailCurrentEmail')}</div>
                            <div className='bodyLabel section24'>{this.state.currentEmail}</div>

                            <Input ref={this.emailInput} type='email' id='email'
                                placeholder={I18n.get('ChangeEmailTextBoxHint')} name={I18n.get('ChangeEmailTextBoxHint')}
                                onChange={this.handleFormInput} />

                            <Input ref={this.passwordInput} type='password' id='password' className='section36'
                                placeholder={I18n.get('createAccountPasswordText')} name={I18n.get('createAccountPasswordText')}
                                onChange={this.handleFormInput} autoComplete='new-password' />

                            <CancelButton onClick={this.handleGoBack}>
                                {I18n.get('changePasswordCancel')}
                            </CancelButton>

                            <CTAButton ref={this.submitButton} onClick={this.changeEmailSubmit} submit='true' margintop='true' disabled={isActivateButtonDisabled}>
                                {I18n.get('changeEmailBtn')}
                            </CTAButton>

                            <Message color='red' style={showErrorMessage}>
                                {this.state.errors}
                            </Message>
                        </Forms>
                    </PanelGridColumn>
                </Grid.Row>
            </PageGrid>
        );
    }
}

export default withRouter(ChangeEmail);
