import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Grid } from 'semantic-ui-react';
import { I18n, Auth } from 'aws-amplify';
import CTAButton from '../components/widgets/button/CTAButton';
import ShareAccountCard from '../components/widgets/card/shareAccountCard/ShareAccountCard'
import ApiUtil from "../components/utils/ApiUtil";

import './Page.css';

class SharePage extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            sharedKitInfoList: []
        }
    }

    componentDidMount() {
        const self = this;
        ApiUtil.checkAndHandleLogOut().then(function(response) {
            if(response) {
                self.fetchSharedInfo();
            } else {
                self.props.history.replace('/signIn');
            }
        });
    }

    fetchSharedInfo = async () => {
        this.checkIfStillLoggedIn();
        try {
            const response = await ApiUtil.getSharedKitByKitId(this.props.kitId);
            await this.setState({sharedKitInfoList: response});

        } catch (error) {
            console.error(error);
            this.checkAndHandleLogOut(error);
        }
    }
    
    checkIfStillLoggedIn() {
        if (localStorage.getItem('innerFlag') === null || localStorage.getItem('innerFlag') === false) {
            this.logout();
        } 
    }
    
    checkAndHandleLogOut = (error) => {
        var logout = false
        if (error.data != null) {
            console.error("Error status : " + error.data.status);
            if (error.data.status === 401) {
                logout = true;
            }
        } else if (error.status != null) {
            console.error("Error status : " + error.status);
            if (error.status === 401) {
                logout = true;
            }
        }
        
        if (logout) {
            this.logout();
        }
    }
    
    checkAndHandleLogOut = (error) => {
        console.error("Error status : " + error.data.status);
        if (error.data.status === 401) {
            this.logout();
        }
    }
    
    logout() {
        Auth.signOut()
        .then(() => {
            this.props.history.replace('/signIn');
        })
        .catch(error => {
            console.error(error);
            if (error.code === 'NotAuthorizedException') {
                this.props.history.replace('/signIn');
            }
        });
    }
    
    handleShareOnClick = () => {
        this.checkIfStillLoggedIn();
        this.props.history.push('/shareResult');
    }

    renderShareAccountCard() {
        const shareAccountCardList = this.state.sharedKitInfoList ? this.state.sharedKitInfoList.map((kit, index) => {
            return (
                <Grid.Row key={index}>
                    <ShareAccountCard kitInfo={kit} onClick={() => {
                        this.props.history.push({
                            pathname: '/configureSharedProfile',
                            state: {
                                kit: kit,
                                profileName: this.props.profileName
                            }
                        });
                    }}/>
                </Grid.Row>
            )
        }) : null;
        return shareAccountCardList;
    }

    render() {
        console.log(this.props.kitStatus)
        const disabledShareButton = this.props.kitStatus !== "REPORTS_READY";
        return (
            <Grid className='sharePage-contentPanel'>
                <Grid.Row columns={2}>
                    <Grid.Column width={13}>
                        <div className='sectionTitleLabel section2'>{I18n.get('shareProfilePageTitle')}</div>
                        <div className='bodyLabel' hint='true'>{I18n.get('shareAccountDes')}</div>
                    </Grid.Column>

                    <Grid.Column width={3} floated='right'>
                        {
                            this.props.kitStatus === "REPORTS_READY" && <CTAButton className='sharePage-shareButton' disabled={disabledShareButton} small='true' onClick={this.handleShareOnClick}>{I18n.get('shareAccountSahreButton')}</CTAButton>
                        }
                        {
                            this.props.kitStatus !== "REPORTS_READY" && <CTAButton className='sharePage-shareButton' disabled={disabledShareButton} small='true'>{I18n.get('shareAccountSahreButton')}</CTAButton>
                        }
                    </Grid.Column>
                </Grid.Row>

                {this.renderShareAccountCard()}

            </Grid>
        )
    }
}

SharePage.prototypes = {
    kitId: PropTypes.string,
    kitStatus: PropTypes.string
};

export default withRouter(SharePage);