import React, { Component } from 'react';
import { Grid, Checkbox } from 'semantic-ui-react';
import { withRouter } from 'react-router-dom';
import { Auth } from 'aws-amplify/lib/index';
import ApiUtil from '../../utils/ApiUtil';
import { I18n } from 'aws-amplify';
import CTAButton from '../../widgets/button/CTAButton';
import CancelButton from '../../widgets/button/CancleButton';
import PageGrid from '../../widgets/grid/PageGrid';
import PanelGridColumn from '../../widgets/grid/PanelGridColumn';
import Forms from '../../widgets/form/Forms';

class DeleteAccountConfirmation extends Component {
    constructor(props, context) {
        super(props, context);
        this.deleteButton = React.createRef();

        this.state = {
            profiles: [],
            deleteConfirm: false,
            loading: ''
        };

        this.handleGoAccount = this.handleGoAccount.bind(this);
        this.handleDeleteConfirm = this.handleDeleteConfirm.bind(this);
        this.handleAccountDeletion = this.handleAccountDeletion.bind(this);
    }

    componentDidMount() {
        this.getAllProfiles();
    }

    getAllProfiles = async () => {
        try {
            const profiles = await ApiUtil.getKitAndProfileJointList();
            await this.setState({ profiles: profiles });
        } catch (error) {
            console.error(error);
            this.checkAndHandleLogOut(error);
        }
    };
    
    checkAndHandleLogOut = (error) => {
        var logout = false
        if (error.data != null) {
            console.error("Error status : " + error.data.status);
            if (error.data.status === 401) {
                logout = true;
            }
        } else if (error.status != null) {
            console.error("Error status : " + error.status);
            if (error.status === 401) {
                logout = true;
            }
        }
        
        if (logout) {
            Auth.signOut()
            .then(() => {
                this.props.history.replace('/signIn');
            })
            .catch(error => {
                console.error(error);
                if (error.code === 'NotAuthorizedException') {
                    this.props.history.replace('/signIn');
                }
            });
        }
    }

    handleGoAccount() {
        this.props.history.goBack();
        this.props.history.push('/account');
    }

    async handleAccountDeletion() {
        try {
            this.deleteButton.current.setLoading(true)
            const response = await ApiUtil.deleteAccountConfirmation();
            if (response.status === 200) {
                this.props.history.push('/deleteAccountVerifyCode');
            } else {
                console.debug('Delete account failed.');
            }
            this.deleteButton.current.setLoading(false)

        } catch (error) {
            if (this.deleteButton.current !== null) {
                this.deleteButton.current.setLoading(false);
            }
            console.error(error);
            this.checkAndHandleLogOut(error);
        }
    }

    handleDeleteAccount() {
        Auth.currentAuthenticatedUser()
            .then(user => {
                if (user != null) {
                    user.deleteUser(error => {
                        if (!error) {
                            document.location.href = '/signIn';
                        }
                    });
                }
            })
            .catch(err => {
                this.setState({ user: null });
            });
    }

    handleDeleteConfirm() {
        this.setState(prevState => ({ deleteConfirm: !prevState.deleteConfirm }));
    }

    render() {
        return (
            <PageGrid className="allPage-rootPanel">
                <Grid.Row>
                    <PanelGridColumn>
                        <Forms header={I18n.get('deleteAccountConfirmationHeader')} autoComplete='off'>
                            <div className='bodyLabel section24'>{I18n.get('deleteAccountSubTitle')}</div>

                            <div className='bodyTitleLabel'>{I18n.get('deleteAccountProfileTitle')}</div>
                            <ul className='bodyLabel section24'>
                                {this.state.profiles.map(result => {
                                    return (
                                        <li className='bodyLabel section6' key={result.id}>{`${result.firstName}-${result.lastName}`}</li>
                                    );
                                })}
                            </ul>

                            <Checkbox onChange={this.handleDeleteConfirm} className='section48'
                                checked={this.state.deleteConfirm} id='deleteConfirm'
                                label={<label>{I18n.get('deleteAccountAgreement')}</label>} />

                            <CTAButton ref={this.deleteButton} submit='true' warning='true' onClick={this.handleAccountDeletion}
                                disabled={!this.state.deleteConfirm}>
                                {I18n.get('deleteAccountRequest')}
                            </CTAButton>

                            <CancelButton onClick={this.handleGoAccount} margintop='true'>
                                {I18n.get('deleteAccountCancel')}
                            </CancelButton>

                        </Forms>
                    </PanelGridColumn>
                </Grid.Row>
            </PageGrid>
        );
    }
}

export default withRouter(DeleteAccountConfirmation);
