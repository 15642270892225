import React, { Component } from 'react';
import { I18n } from 'aws-amplify';
import { Grid, Message } from 'semantic-ui-react';
import ValidationUtil from '../../utils/ValidationUtil';
import { NavLink as Link, withRouter } from 'react-router-dom';
import CTAButton from '../../widgets/button/CTAButton';
import Input from '../../widgets/Input/Input';
import PageGrid from '../../widgets/grid/PageGrid';
import PanelGridColumn from '../../widgets/grid/PanelGridColumn';
import Forms from '../../widgets/form/Forms';
import ApiUtil from "../../utils/ApiUtil";


class RecoverUsername extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            errors: [],
            isFirstTimeValidation: false,
            validationPass: true,
        };
        this.submitButton = React.createRef();
        this.emailInput = React.createRef();
        this.handleChange = this.handleChange.bind(this);
        this.handleRecoverUsername = this.handleRecoverUsername.bind(this);
    }

    checkValidation = async () => {
        // Don't validate any field until user click submit button at the first time.
        if (!this.state.isFirstTimeValidation) {
            return;
        }
        const emailValidation = await ValidationUtil.validateOverridePass(this.state.email);
        this.emailInput.current.setError(!emailValidation.status, emailValidation.msg);

        await this.setState({
            validationPass: emailValidation.status
        });
    };

    async handleRecoverUsername(event) {
        event.preventDefault();
        this.submitButton.current.setLoading(true);
        await this.setState({ isFirstTimeValidation: true, errors: [] });
        await this.checkValidation();
        if (!this.state.validationPass) {
            if (this.submitButton.current !== null) {
                this.submitButton.current.setLoading(false);
            }
            return;
        }

        try {
            await ApiUtil.getAccountByEmail(this.state.email);

            this.props.history.push('/recoverUsernameConfirmation');
            if (this.submitButton.current !== null) {
                this.submitButton.current.setLoading(false);
            }
        } catch (error) {
            if (this.submitButton.current !== null) {
                this.submitButton.current.setLoading(false);
            }
        }
    }

    async handleChange(event) {
        event.preventDefault();

        await this.setState({
            [event.target.id]: event.target.value,
        });
        await this.checkValidation();
    }

    render() {
        const showErrorMessage = (this.state.errors.length === 0) ? { display: "none" } : { display: "" };
        const isActivateButtonDisabled = !this.state.validationPass || !this.state.email;
        return (
            <PageGrid className="allPage-rootPanel">
                <Grid.Row>
                    <PanelGridColumn>
                        <Forms className='section18' header={I18n.get('recoverUsernameTitle')} onSubmit={this.handleRecoverUsername}>

                            <div className='bodyLabel section12' >{I18n.get('recoverUsernameText')}</div>

                            <Input ref={this.emailInput} type='text' id='email' className='section36'
                                placeholder={I18n.get('recoverUsernamePlaceholder')} onChange={this.handleChange} />

                            <CTAButton ref={this.submitButton} submit='true' margintop='true' onClick={this.signIn}
                                disabled={isActivateButtonDisabled}>
                                {I18n.get('recoverUsernameSubmitText')}
                            </CTAButton>

                            <Message color='red' style={showErrorMessage}>
                                {this.state.errors}
                            </Message>

                        </Forms>

                        <div className='bodyLabel textAlignCenter'>
                            {I18n.get('recoverUsernameRememberText')}
                            <Link className='blueLink' to='/signIn'>{I18n.get('createAccountSignInText')}</Link>
                        </div>

                    </PanelGridColumn>
                </Grid.Row>
            </PageGrid>
        );
    }
}

export default withRouter(RecoverUsername);
