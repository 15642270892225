import React, { Component } from 'react';
import { Auth } from 'aws-amplify/lib/index';
import { I18n } from 'aws-amplify';
import { Grid, Message } from 'semantic-ui-react';
import ValidationUtil from '../../utils/ValidationUtil';
import { NavLink as Link, withRouter } from 'react-router-dom';
import CTAButton from '../../widgets/button/CTAButton';
import Input from '../../widgets/Input/Input';
import PageGrid from '../../widgets/grid/PageGrid';
import PanelGridColumn from '../../widgets/grid/PanelGridColumn';
import Forms from '../../widgets/form/Forms';


class ResetPassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            username: '',
            errors: [],
            isFirstTimeValidation: false,
            validationPass: true,
        };
        this.submitButton = React.createRef();
        this.userNameInput = React.createRef();
        this.handleChange = this.handleChange.bind(this);
        this.handleForgotPassword = this.handleForgotPassword.bind(this);
    }

    checkValidation = async () => {
        // Don't validate any field until user click submit button at the first time.
        if (!this.state.isFirstTimeValidation) {
            return;
        }
        const usernameValidation = await ValidationUtil.validateOverridePass(this.state.username);
        this.userNameInput.current.setError(!usernameValidation.status, usernameValidation.msg);

        await this.setState({
            validationPass: usernameValidation.status
        });
    };

    async handleForgotPassword(event) {
        event.preventDefault();
        this.submitButton.current.setLoading(true);
        await this.setState({ isFirstTimeValidation: true, errors: [] });
        await this.checkValidation();
        if (!this.state.validationPass) {
            if (this.submitButton.current !== null) {
                this.submitButton.current.setLoading(false);
            }
            return;
        }

        if(this.state.username.includes("@")) {
            if (this.submitButton.current !== null) {
                this.submitButton.current.setLoading(false);
            }
            this.setState({ errors: [I18n.get('resetPasswordInvalidUsername')] });
            return;
        }

        Auth.forgotPassword(this.state.username)
            .then(data => {
                this.props.setEmail(data.CodeDeliveryDetails.Destination);
                this.props.switchComponent('ForgotPasswordSubmit', this.state.username);
                this.props.history.push('/resetPasswordConfirmation');
                if (this.submitButton.current !== null) {
                    this.submitButton.current.setLoading(false);
                }
            })
            .catch(err => {
                //if (this.submitButton.current !== null) {
                //    this.submitButton.current.setLoading(false);
                //}
                //this.setState({ errors: [err.message] });
                this.props.switchComponent('ForgotPasswordSubmit', this.state.username);
                this.props.history.push('/resetPasswordConfirmation');
                if (this.submitButton.current !== null) {
                    this.submitButton.current.setLoading(false);
                }
            });
    }

    async handleChange(event) {
        event.preventDefault();

        await this.setState({
            [event.target.id]: event.target.value,
        });
        await this.checkValidation();
    }

    render() {
        const showErrorMessage = (this.state.errors.length === 0) ? { display: "none" } : { display: "" };
        const isActivateButtonDisabled = !this.state.validationPass || !this.state.username;
        return (
            <PageGrid className="allPage-rootPanel">
                <Grid.Row>
                    <PanelGridColumn>
                        <Forms className='section18' header={I18n.get('resetPasswordTitle')} onSubmit={this.handleForgotPassword}>

                            <div className='bodyLabel section12' >{I18n.get('resetPasswordText')}</div>

                            <Input ref={this.userNameInput} type='text' id='username' className='section36'
                                placeholder={I18n.get('createAccountUserName')} name={I18n.get('createAccountUserName')} onChange={this.handleChange} />

                            <CTAButton ref={this.submitButton} submit='true' margintop='true' onClick={this.signIn}
                                disabled={isActivateButtonDisabled}>
                                {I18n.get('resetPasswordSubmitText')}
                            </CTAButton>

                            <Message color='red' style={showErrorMessage}>
                                {this.state.errors}
                            </Message>

                        </Forms>

                        <div className='bodyLabel textAlignCenter'>
                            {I18n.get('resetPasswordRememberText')}
                            <Link className='blueLink' to='/signIn'>{I18n.get('createAccountSignInText')}</Link>
                        </div>

                    </PanelGridColumn>
                </Grid.Row>
            </PageGrid>
        );
    }
}

export default withRouter(ResetPassword);
