import React from 'react';
import PropTypes from 'prop-types';
import { DateInput } from 'semantic-ui-calendar-react';
import Moment from 'moment';
import './DatePicker.css'

class DatePicker extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            date:  (props.defaultDate) ? Moment(props.defaultDate, 'DD-MM-YYYY').format('MMMM DD, YYYY') : '',
            isError: false,
            errorMessage: ""
        }
    }

    setError = async (isError, errorMessage) => {
        await this.setState({ isError: isError, errorMessage: errorMessage });
    }

    onChange = (event, { name, value }) => {
        const date = (value === '') ? '' : Moment(value, 'DD-MM-YYYY').format('MMMM DD, YYYY')
        this.setState({date: date})
        this.props.onChange(event, name, date)
    }

    render() {
        const animation = (this.props.animation) ? this.props.animation : "fade";
        const autoComplete = (this.props.autoComplete) ? this.props.autoComplete : "off";
        const errorClassName = this.state.isError ? " calendar-style-error " : " calendar-style ";
        const className = (this.props.className) ? errorClassName + this.props.className : errorClassName;
        const datePickerLabelClass = 'datePickerLabel';
        const localization = this.props.localization ? this.props.localization : "en";
        const iconPosition = this.props.iconPosition ? this.props.iconPosition : "left";
        const name = this.props.name ? this.props.name : "date";
        const popupPosition = this.props.popupPosition ? this.props.popupPosition : "top right";
        const startMode = this.props.startMode ? this.props.startMode : "year";
        const errorMessage = (this.state.errorMessage) ? (this.state.errorMessage) : "";
        const isPlaceholderLabelVisible = (this.state.date === '')? "" : {visible: ""};


        return (

            <div className="datePicker">
                <DateInput id='datePicker' animation={animation} autoComplete={autoComplete}
                    className={className} clearable={true} closeOnMouseLeave={false} closable
                    duration={300} hideMobileKeyboard iconPosition={iconPosition} ocalization={localization}
                    maxDate={this.props.maxDate} name={name} onChange={this.onChange} placeholder={this.props.placeholder}
                    preserveViewMode={false} popupPosition={popupPosition} startMode={startMode}
                    value={this.state.date} label={<div {...isPlaceholderLabelVisible} className={datePickerLabelClass} htmlFor='datePicker'>{this.props.placeholder}</div>} />

                <div className="bodyLabel" error='true' dangerouslySetInnerHTML={{__html: errorMessage}}></div>
            </div>
        );
    }
}

DatePicker.prototypes = {
    animation: PropTypes.string,
    autoComplete: PropTypes.string,
    className: PropTypes.string,
    hintMessage: PropTypes.string,
    iconPosition: PropTypes.string,
    maxDate: PropTypes.string,
    name: PropTypes.string,
    onChange: PropTypes.func,
    placeholder: PropTypes.string,
    popupPosition: PropTypes.string,
    startMode: PropTypes.string
};

export default DatePicker;