import React, { Component } from 'react';
import { Grid } from 'semantic-ui-react';
import { I18n } from 'aws-amplify';
import ApiUtil from "../../utils/ApiUtil";

class BuyKitAdvertising extends Component {

    render() {
        return (
            <Grid>
                <Grid.Row>
                    <Grid.Column textAlign='center'>
                        <div className='bodyLabel textAlignCenter'> {I18n.get('buyKitAdvertisingMsg1')}
                            <a target='_blank' rel="noopener noreferrer" href={ApiUtil.getBuyKitUrl()} className='blueLink'>
                                {I18n.get('buyKitAdvertisingMsg2')}
                            </a>
                        </div>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        )
    }
}

export default BuyKitAdvertising;
