import React from 'react';
import { Dimmer, Grid, Loader } from 'semantic-ui-react'
import PageGrid from '../components/widgets/grid/PageGrid';
import PanelGridColumn from '../components/widgets/grid/PanelGridColumn';
import ApiUtil from "../components/utils/ApiUtil";

import '../pages/Page.css'
import {Auth} from "aws-amplify/lib";

class Bibligraphy extends React.Component {
    constructor(props) {
        super(props);
        this.iframe = React.createRef();
        this.state = {
            url: ApiUtil.getBibliographyUrl(),
            isIframeLoaded: false
        }
    }

    componentDidMount() {
        const self = this;
        ApiUtil.checkAndHandleLogOut().then(function(response) {
            if(!response) {
                self.props.history.replace('/signIn');
            }
        });

    }

    oniFrameLoaded = () => {
        this.setState({ isIframeLoaded: true })
    }

    render() {
        return (
            <PageGrid className="allPage-rootPanel">
                <Grid.Row>
                    <PanelGridColumn className={'bibliographyPage'}>
                        <Dimmer active={!this.state.isIframeLoaded}>
                            <Loader />
                        </Dimmer>
                        <iframe ref={this.iframe} title={'bibligraphyIframe'} className={'bibliographyPage-iframe'} onLoad={this.oniFrameLoaded} frameBorder='0' src={this.state.url} />
                    </PanelGridColumn>
                </Grid.Row>
            </PageGrid>
        )
    }
}

export default Bibligraphy;
