import React, { Component } from 'react';
import { Auth, JS } from 'aws-amplify/lib/index';
import { I18n } from 'aws-amplify';
import { Grid, Message } from 'semantic-ui-react';
import { withRouter } from 'react-router-dom';
import CTAButton from '../../widgets/button/CTAButton';
import CancelButton from '../../widgets/button/CancleButton';
import Input from '../../widgets/Input/Input';
import PageGrid from '../../widgets/grid/PageGrid';
import PanelGridColumn from '../../widgets/grid/PanelGridColumn';
import Forms from '../../widgets/form/Forms';
class DeleteAccountVerify extends Component {
    constructor(props, context) {
        super(props, context);
        this.submitButton = React.createRef();
        this.passwordInput = React.createRef();
        this.state = {
            username: '',
            inputPassword: '',
            isWrongPassword: false,
            errors: [],
        };

        this.signIn = this.signIn.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleDismiss = this.handleDismiss.bind(this);
        this.handleGoBack = this.handleGoBack.bind(this);
    }

    componentWillMount() {
        this.getUser();
    }

    async getUser() {
        const user = await Auth.currentAuthenticatedUser();
        this.setState({ username: user.username });
    }

    handleDismiss(event) {
        event.preventDefault();
        this.setState({ errors: [] });
    }

    handleChange(event) {
        this.setState({
            [event.target.id]: event.target.value,
        });
        this.passwordInput.current.setError(false, "");
        this.setState({isWrongPassword: false});
    }

    checkContact(user) {
        if (!user) {
            return;
        }
        if (!Auth || typeof Auth.verifiedContact !== 'function') {
            throw new Error('No Auth module found, please ensure @aws-amplify/auth is imported');
        }
        Auth.verifiedContact(user).then(data => {
            if (!JS.isEmpty(data.verified)) {
                this.setState({ user: user });
                this.props.history.push('/deleteAccountConfirmation');
            } else {
                this.setState({ errors: [data] });
            }
        });
    }

    async signIn(event) {
        event.preventDefault();
        const username = this.state.username;
        const password = this.state.inputPassword;
        // Clean the error message
        this.submitButton.current.setLoading(true)
        this.setState({ errors: [] });
        try {
            const user = await Auth.signIn(username, password);
            this.checkContact(user);
        } catch (err) {
            if (err.message === undefined) {
                this.setState({ errors: [err] });

            } else {
                if (err.code ===  "NotAuthorizedException") {
                    this.passwordInput.current.setError(true, I18n.get("deleteAccountWrongPasswordError"));
                    this.setState({isWrongPassword: true});

                } else {
                    this.setState({ errors: [err.message] });
                }
                
            }
        } finally {
            this.submitButton.current.setLoading(false)
        }
    }

    handleGoBack() {
        this.props.history.goBack();
    }

    render() {
        const showErrorMessage = this.state.errors.length === 0 ? { display: 'none' } : { display: '' };
        const userNameVisibility = this.state.isWrongPassword ? {display: 'none'} : {display: ''};
        return (
            <PageGrid className="allPage-rootPanel">
                <Grid.Row>
                    <PanelGridColumn>
                        <Forms header={I18n.get('deleteAccountVerifyPassword')} autoComplete='new-password'>

                            <div className='bodyLabel section12'>{I18n.get('deleteAccountVerifyPasswordDes')}</div>
                            <div className='bodyLabel' hint='true' style={userNameVisibility}>{I18n.get('accountUserName')}</div>
                            <div className='bodyTitleLabel section12'  style={userNameVisibility}>{this.state.username}</div>
                            <Input ref={this.passwordInput} autoComplete="new-password" type='password' id='inputPassword' className='section36'
                                placeholder={I18n.get('loginPasswordText')} name={I18n.get('loginPasswordText')}
                                onChange={this.handleChange} />

                            <CancelButton onClick={this.handleGoBack}>
                                {I18n.get('deleteAccountCancel')}
                            </CancelButton>

                            <CTAButton ref={this.submitButton} submit='true' margintop='true' onClick={this.signIn}
                                disabled={!this.state.inputPassword}>
                                {I18n.get('deleteAccountContinue')}
                            </CTAButton>

                            <Message color='red' style={showErrorMessage}>
                                {this.state.errors}
                            </Message>

                        </Forms>
                    </PanelGridColumn>
                </Grid.Row>
            </PageGrid>
        );
    }
}

export default withRouter(DeleteAccountVerify);
