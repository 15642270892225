import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Image } from 'semantic-ui-react'
import GridRow from '../../grid/GridRow';
import CTAButton from '../../button/CTAButton';
import { I18n } from 'aws-amplify';

import './ProfileCard.css';
import '../../label/Label.css';

class ProfileCardSimple extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.approveButton = React.createRef();
        this.removeButton = React.createRef();
    }

    renderApproveButton = () => {
        //console.log('renderApproveButton');
        //console.log(this.props);
        if (this.props.isShared && ! this.props.profile.approvedShare) {
            return (
                <div>
                    <CTAButton ref={this.approveButton} className='profileCardSimple-approveButton' small='true' onClick={() => this.props.onApproveClick(this.props.profile, this.approveButton)}>{I18n.get('profileCardSimpleApproveButton')}</CTAButton><br />
                    <CTAButton ref={this.removeButton} className='profileCardSimple-removeButton' warning='red' small='true' onClick={() => this.props.onCancelClick(this.props.profile, this.removeButton)}>{I18n.get('profileCardSimpleDenyButton')}</CTAButton>
                </div>
            )
        }
    }

    renderRemoveButton = () => {
        if (this.props.isShared && this.props.profile.approvedShare) {
            return (
                <div>
                    <CTAButton ref={this.removeButton} className='profileCardSimple-removeButton' warning='red' small='true' onClick={() =>  this.props.onCancelClick(this.props.profile, this.removeButton)}>{I18n.get('profileCardSimpleRemoveButton')}</CTAButton>
                </div>
            )
        }
    }

    renderViewButton = () => {
	if (typeof this.props.profile != "undefined") {
		//console.log(this.props);
		//console.log(this.props.profile);
		if (this.props.profile.cliaApproved == "P" || (this.props.isShared && this.props.profile.approvedShare)) {
		    return (
			<CTAButton className='profileCardSimple-viewButton' small='true' onClick={() =>  this.props.onClick(this.props.profile)}>{I18n.get('profileCardSimpleViewButton')}</CTAButton>
		    );
		}
	}
    }

    render() {
        if (this.props.profile.currentStatus == 'REPORTS_READY' && this.props.profile.cliaApproved != "P") {
            this.props.profile.currentStatus = 'ANALYZED';
        }
        return (
            <Grid className="profileCardSimple-grid">
                <GridRow className="profileCardSimple-statusImageColumn" columns={3} >
                    <Grid.Column width={2} className='gridColumn'>
                        <Image className='profileCardSimple-statusImage' src={I18n.get(this.props.profile.currentStatus + '_icon')} />
                    </Grid.Column>

                    <Grid.Column width={10} className='profileCardSimple-profileColumn'>
                        <div className="bodyTitleLabel">{`${this.props.profile.firstName} ${this.props.profile.lastName}`}</div>
                        <div className="bodyLabel">{I18n.get(this.props.profile.currentStatus)}</div>
                    </Grid.Column>

                    <Grid.Column width={4} floated='right' className='profileCardSimple-actionColumn'>
                        <div>
                            <div>
                            {this.renderApproveButton()}
				{this.renderViewButton()}
                            </div>
                            {this.renderRemoveButton()}
                        </div>
                    </Grid.Column>
                </GridRow>
            </Grid>
        );
    }
}

ProfileCardSimple.prototypes = {
    profile: PropTypes.object,
    onClick: PropTypes.func,
    onCancelClick: PropTypes.func,
    isShared: PropTypes.bool
};

export default ProfileCardSimple;
