import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Image } from 'semantic-ui-react';

class AccountInfoCard extends React.Component {
    render() {
        return (
            <Grid columns={2} className='formCard'>
                <Grid.Row columns={2} className='padding0 formCard-gridRow '>
                    <Grid.Column width={14}>
                        <div className='bodyTitleLabel'>{this.props.title}</div>
                        <p className='bodyLabel'>
                            {this.props.subTitle}
                            <span className='bodyLabel' hint='true'> {this.props.subTitleDec}</span>
                        </p>
                    </Grid.Column>
                    <Grid.Column width={2} floated='right'>
                        {this.props.isConfigurable && (
                            <Image className='card-icon icon-clickable' src={'../images/icon-configure.svg'} onClick={this.props.onClick}/>
                        )}
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        );
    }
}

AccountInfoCard.prototypes = {
    title: PropTypes.string,
    subTitle: PropTypes.string,
    subTitleDec: PropTypes.string,
    isConfigurable: PropTypes.string,
    onClick: PropTypes.func
};

export default AccountInfoCard;