import React, { PureComponent } from 'react';

import { BrowserRouter as Router, Route, Switch, Redirect, useHistory } from 'react-router-dom';
import { createBrowserHistory } from "history";

import ResponsiveContainer from '../components/widgets/container/ResponsiveContainer';
import routes from '../routes';
import NoMatch from "./NoMatch";
import ChangeEmailLanding from '../components/auth/changeEmail/ChangeEmailLanding';
import ResetPasswordLanding from '../components/auth/resetPassword/ResetPasswordLanding';

import IdleTimer from 'react-idle-timer'
import { Button, Header, Icon, Modal } from 'semantic-ui-react'
import {Auth, Hub} from "aws-amplify";

const history = createBrowserHistory();

export default class Authentication extends PureComponent {
    constructor(props) {
        super(props);

        this.idleTimer = null
        this.handleOnAction = this.handleOnAction.bind(this)
        this.handleOnActive = this.handleOnActive.bind(this)
        this.handleOnIdle = this.handleOnIdle.bind(this)
        this.handleLogout = this.handleLogout.bind(this)
        this.setOpen = this.setOpen.bind(this)
        this.timer = 0;
        this.startTimer = this.startTimer.bind(this);
        this.resetTimer = this.resetTimer.bind(this);
        this.countDown = this.countDown.bind(this);

        const self = this;

        Hub.listen('userLoggedIn', (data) => {
            self.setState({
                location: null,
            });
            self.idleTimer.reset();
        });

        Hub.listen('userLoggedOut', (data) => {
            self.setState({
                location: null,
            });
            self.idleTimer.pause();
        });

    }

    state = {
        username: '',
        email: '',
        password: '',
        phone_number: '',
        code: '',
        user: null, // will contain our user data object when signed in
        status: '',
        open: false,
        seconds: 30,
        redirectToLogin: false,
        location: null
    };


    AuthComponent = () => {
        return (
            <Router className='' history={history}>

                <ResponsiveContainer>

                    <IdleTimer
                        ref={ref => { this.idleTimer = ref }}
                        timeout={1000 * 60 * 30}
                        onActive={this.handleOnActive}
                        onIdle={this.handleOnIdle}
                        onAction={this.handleOnAction}
                        debounce={250}
                    />
                    <Modal
                        basic
                        onClose={() => this.setOpen(false)}
                        onOpen={() => this.setOpen(true)}
                        open={this.state.open}
                        size='small'
                        dimmer='blurring'
                        closeOnDimmerClick={false}
                    >
                        <Header icon>
                            <Icon name='lock' />
                            Your Session is about to Timeout
                        </Header>
                        <Modal.Content>
                            <p className="ui center aligned container">
                                You are about to be logged out, if you don't continue in {this.state.seconds} seconds you will be logged out.
                            </p>
                        </Modal.Content>
                        <Modal.Actions>
                            <div className="ui center aligned container">
                                <Button basic color='green' inverted onClick={() => this.setOpen(false)}>
                                    <Icon name='checkmark' /> Continue
                                </Button>
                                <Button color='red' inverted onClick={() => this.handleLogout()}>
                                    <Icon name='sign-out' /> Logout
                                </Button>
                            </div>
                        </Modal.Actions>
                    </Modal>
                    <Switch location={this.state.location}>
                        {routes.map((route, i) => {
                            const { path, exact, routes } = route;
                            return (
                                <Route
                                    key={i}
                                    path={path}
                                    exact={exact}
                                    render={routeProps => (
                                        <route.component
                                            routes={routes}
                                            {...routeProps}
                                            {...this.props}
                                            switchComponent={this.switchComponent}
                                            handleFormInput={this.handleFormInput}
                                            setEmail={this.setEmail}
                                            inputs={this.state}
                                        />
                                    )}
                                >
                                </Route>
                            );
                        })}
                        <Route path="/resetPasswordLanding" component={ResetPasswordLanding} />
                        <Route path="/changeEmailLanding" component={ChangeEmailLanding} />
                        <Route component={NoMatch} />
                    </Switch>
                </ResponsiveContainer>
            </Router>
        );
    };

    setEmail = (email) => {
        this.setState({ email: email});
    };

    switchComponent = (status, username) => {
        this.setState({ status: status, username: username });
    };

    handleOnAction (event) {
        //console.log('user did something')
        //console.log('time remaining', this.idleTimer.getRemainingTime())
    };

    handleOnActive (event) {
        //console.log('user is active')
        //console.log('time remaining', this.idleTimer.getRemainingTime())
    };

    handleOnIdle (event) {
        this.setOpen(true);
        this.startTimer();
        //console.log('user is idle')
        //console.log('last active', this.idleTimer.getLastActiveTime())
    };

    setOpen(value) {
        this.setState({open: value});
        if(!value) {
            this.resetTimer();
        }
    };

    startTimer() {
        if (this.timer == 0 && this.state.seconds > 0) {
            this.timer = setInterval(this.countDown, 1000);
        }
    }

    resetTimer() {
        clearInterval(this.timer);
        this.setState({
            time: 0,
            seconds: 30,
        });
        this.timer = 0;
    };

    countDown() {
        // Remove one second, set state so a re-render happens.
        let seconds = this.state.seconds - 1;
        this.setState({
            time: this.secondsToTime(seconds),
            seconds: seconds,
        });

        // Check if we're at zero.
        if (seconds == 0) {
            clearInterval(this.timer);
            this.handleLogout();
        }
    };

    secondsToTime(secs){
        let hours = Math.floor(secs / (60 * 60));

        let divisor_for_minutes = secs % (60 * 60);
        let minutes = Math.floor(divisor_for_minutes / 60);

        let divisor_for_seconds = divisor_for_minutes % 60;
        let seconds = Math.ceil(divisor_for_seconds);

        let obj = {
            "h": hours,
            "m": minutes,
            "s": seconds
        };
        return obj;
    };

    handleLogout() {
        Auth.signOut().then(() => {
            localStorage.clear();
            sessionStorage.clear();
            this.setOpen(false);
            Hub.dispatch( 'userLoggedOut', {});
            history.replace('/signIn');
            this.setState({
                location: history.location,
            });
        });
    };

    render() {
        return <div>{this.AuthComponent()}</div>;
    }
}
