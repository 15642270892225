import { Auth, Hub } from 'aws-amplify/lib/index';
import axios from 'axios';

const defaultRowSize = 50;

class ApiUtil {

    static configure = async (url) => {
        this.state = {
            url: url,
            kitServiceUrl: url,
            userManagementServiceUrl: url,
            resultsServiceUrl: url,
            uiOrchsUrl: url,
            deleteAccountConfirmation: process.env.REACT_APP_UI_ORCHS_DELETE_ACCOUNT_CONFIRMATION_API_PATH,
            deleteAccountPath: process.env.REACT_APP_UI_ORCHS_DELETE_ACCOUNT_API_PATH,
            initProfilePath: process.env.REACT_APP_UI_ORCHS_INIT_API_PATH,
            getAllProfilesPath: process.env.REACT_APP_USER_PROFILE_API_PATH,
            getAllKitsPath: process.env.REACT_APP_KITS_API_PATH,
            shareKitPath: process.env.REACT_APP_SHARE_KIT_API_PATH,
            getSharedKitByKitIDPath: process.env.REACT_APP_SHARE_KIT_GET_KIT_API_PATH,
            getSharedKitByAccountIDPath: process.env.REACT_APP_SHARE_KIT_GET_SHARE_ACCOUNTS_API_PATH,
            deleteSharedKitByOwnerPath: process.env.REACT_APP_SHARE_KIT_DELETE_API_PATH,
            validateTubeKitPath: process.env.REACT_APP_KITS_VALIDATE_API_PATH,
            activateKitPath: process.env.REACT_APP_UI_ORCHS_ACTIVATE_KIT_API_PATH,
            updateKitPath: process.env.REACT_APP_KITS_SLASH_API_PATH,
            resultsPath: process.env.REACT_APP_RESULTS_API_PATH,
            kitPath: process.env.REACT_APP_KITS_SLASH_API_PATH,
            fileInfo: process.env.REACT_APP_UI_ORCHS_FILEINFO_API_PATH,
            verifyConfirmCode: process.env.REACT_APP_UI_VERIFY_CONFIRM_CODE_PATH,
            education: process.env.REACT_APP_UI_GET_EDUCATION_PATH,
            config: {
                headers: {
                    Accept: 'application/vnd.strategene.v1+json',
                    'Content-Type': 'application/vnd.strategene.v1+json',
                    Authorization: ''
                },
            },
            embeddedUrl: {
                educationUrl: "",
                bibliographyUrl: "",
                homeUrl: ""
            },
            externalUrl: {
                buyKitUrl: "",
                advertisingPathwayUrl: "",
                advertisingCourseUrl: "",
                advertisingPurchaseUrl: "",
                dirtyGeneBookUrl: "",
            },
            policyUrl: {
                tosUrl: "",
                privacyPolicyUrl: "",
                researchConsentPolicyUrl: "",
                biobankingPolicyUrl: "",
                sharingPolicyUrl: ""
            },
            redirectAfterLogin: ""
        };
        if (process.env.REACT_APP_ENV === 'development') {
            this.state.kitServiceUrl = 'http://localhost:8080/api';
            this.state.userManagementServiceUrl = 'http://localhost:8180/api';
            this.state.resultsServiceUrl = 'http://localhost:8280/api';
            this.state.uiOrchsUrl = 'http://localhost:3001';
        }
    };

    static setPolicyUrl = async (policyUrlUrl) => {
        this.state.tosUrl = policyUrlUrl.tosUrl;
        this.state.privacyPolicyUrl = policyUrlUrl.privacyPolicyUrl;
        this.state.researchConsentPolicyUrl = policyUrlUrl.researchConsentPolicyUrl;
        this.state.biobankingPolicyUrl = policyUrlUrl.biobankingPolicyUrl;
        this.state.sharingPolicyUrl = policyUrlUrl.sharingPolicyUrl;
    }

    static getTosUrl = () => {
        return this.state.tosUrl;
    }

    static getPrivacyPolicyUrl = () => {
        return this.state.privacyPolicyUrl;
    }

    static getResearchConsentPolicyUrl = () => {
        return this.state.researchConsentPolicyUrl;
    }

    static getBiobankingPolicyUrl = () => {
        return this.state.biobankingPolicyUrl;
    }

    static getSharingPolicyUrl = () => {
        return this.state.sharingPolicyUrl;
    }

    static setExternalUrl = async (externalUrl) => {
        this.state.buyKitUrl = externalUrl.buyKitUrl;
        this.state.advertisingPathwayUrl = externalUrl.advertisingPathwayUrl;
        this.state.advertisingCourseUrl = externalUrl.advertisingCourseUrl;
        this.state.advertisingPurchaseUrl = externalUrl.advertisingPurchaseUrl;
        this.state.dirtyGeneBookUrl = externalUrl.dirtyGeneBookUrl;
    }

    static getBuyKitUrl = () => {
        return this.state.buyKitUrl;
    }

    static getAdvertisingPathwayUrl = () => {
        return this.state.advertisingPathwayUrl;
    }

    static getAdvertisingCourseUrl = () => {
        return this.state.advertisingCourseUrl;
    }

    static getAdvertisingPurchaseUrl = () => {
        return this.state.advertisingPurchaseUrl;
    }

    static getDirtyGeneBookUrl = () => {
        return this.state.dirtyGeneBookUrl;
    }

    static setEmbeddedUrl = async (embeddedUrl) => {
        this.state.educationUrl = embeddedUrl.educationUrl;
        this.state.bibliographyUrl = embeddedUrl.bibliographyUrl;
        this.state.homeUrl = embeddedUrl.homeUrl;
    }

    static getEducationUrl = async () => {
        await this.updateIdToken();
        await Auth.currentAuthenticatedUser();
        const self = this;
        return new Promise(function (resolve, reject) {
            try {
                axios.get(self.state.userManagementServiceUrl + self.state.education, self.state.config).then(function(response) {
                    resolve(response);
                });
            } catch (e) {
                throw e;
            }
        }.bind(this));
    }

    static getBibliographyUrl = () => {
        return this.state.bibliographyUrl;
    }

    static getHomeUrl = () => {
        return this.state.homeUrl;
    }

    static updateIdToken = async () => {
        try {
            let currentSession = await Auth.currentSession();
            if (currentSession) {
                this.state.config.headers.Authorization = 'Bearer ' + currentSession.idToken.jwtToken
            }
        } catch(e) {

        }
    }

    static queryRawData = async (kitId, shared ,rsid, page) => {
        try {

            let { resultsPath } = this.state;
            let url = '';
            if (rsid === '') {
                url = `${resultsPath}/${kitId}/rawdata?page=${page}&size=${defaultRowSize}&shared=${shared}`
            } else {
                url = `${resultsPath}/${kitId}/rawdata?rsid=${rsid}&page=${page}&size=${defaultRowSize}&shared=${shared}`
            }
            const response = await axios.get(this.state.resultsServiceUrl + url, this.state.config)
            return response;
        } catch (error) {
            console.error(error.response);
            throw error.response;
        }
    }

    static queryFileInfo = async (kitId) => {
        try {
            const url = `${this.state.kitPath}/${kitId}${this.state.fileInfo}`
            const response = await axios.get(this.state.kitServiceUrl + url, this.state.config)
            return response;
        } catch (error) {
            console.error(error.response);
            throw error.response;
        }
    }

    static verifyConfirmCode = async (code) => {
        let body = {
            confirmCode: code
        };
        try {
            const response = await axios.post(
                this.state.userManagementServiceUrl + this.state.verifyConfirmCode,
                body,
                this.state.config,
            );
            return response;
        } catch (error) {
            console.error(error.response);
            throw error.response;
        }
    };

    static deleteAccount = async (code) => {
        const user = await Auth.currentUserInfo();

        this.state.config.data = { iamIdentity: user.id, confirmCode: code }

        try {
            const response = await axios.delete(
                this.state.uiOrchsUrl + this.state.deleteAccountPath,
                this.state.config,
            );
            return response;
        } catch (error) {
            console.error(error.response);
            throw error.response;
        }
    };

    static deleteAccountConfirmation = async () => {
        try {
            const user = await Auth.currentUserInfo();
            let body = {
                email: user.attributes.email,
                username: user.username
            };
            const response = await axios.post(
                this.state.uiOrchsUrl + this.state.deleteAccountConfirmation,
                body,
                this.state.config,
            );
            return response;
        } catch (error) {
            console.error(error.response);
            throw error.response;
        }
    };

    static getKitAndProfileJointList = async () => {
        try {
            const kitList = await this.getAllkits();
            const profileList = await this.getAllProfiles();
            return this.joinKitsAndProfiles(profileList, kitList)

        } catch (error) {
            console.error(error);
            console.error(error.response);
            console.error(error.message);
            console.error(error.data);
            if (error.response != null && error.response.data != null && error.response.data.status === 401) {
                throw error.response;
            } 
            if (error.data != null && error.data.status == 401) {
                throw error.data;
            } 
            throw error;
        }
    }

    static getUnusedProfieList = async () => {
        const self = this;
        return new Promise(function (resolve, reject) {
            self.getAllkits().then(function(kitList) {
                //var profileList = self.getAllProfiles();
                self.getAllProfiles().then(function(profileList) {
                    var unusedProfieList = profileList;
                    kitList.forEach((kit) => {
                        profileList.forEach((profile) => {
                            if (profile.id === kit.userProfileId) {
                                var index = unusedProfieList.indexOf(profile)
                                unusedProfieList.splice(index, 1)
                            }
                        })
                    });
                    resolve(unusedProfieList);
                })
            }).catch(function(error) {
                console.error('mj', error);
                //console.error(error.response);
                if (error.response.data.status === 401) {
                    //throw error.response;
                    reject(error.response);
                } else {
                    //throw error;
                    reject(error);
                }
            });
        });
    }

    static initProfile = async () => {
        try {
            const user = await Auth.currentUserInfo();
            if (user === null || user === undefined) {
                throw {
                    status: 401
                }
                //return [];
            }

            let data = {
                iamIdentity: user.id,
                firstName: user.attributes.given_name,
                lastName: user.attributes.family_name,
                birthDate: user.attributes.birthdate,
                gender: user.attributes.gender
            }

            const kitList = await this.getAllkits();
            const response = await axios.post(this.state.userManagementServiceUrl + this.state.initProfilePath, data, this.state.config);
            return this.joinKitsAndProfiles(response.data.elements, kitList);

        } catch (error) {
            console.error(error);
            console.error(error.response);
            console.error(error.message);
            console.error(error.data);
            if (error.response != null && error.response.data != null && error.response.data.status === 401) {
                throw error.response;
            } 
            if (error.data != null && error.data.status == 401) {
                throw error.data;
            } 
            throw error;
            //return this.joinKitsAndProfiles(this.mockProfileList(), this.mockKitList());
        }
    };

    static queryProfiles = async (profileId) => {

        let url = '';
        if (profileId === undefined || profileId === null) {
            url = `${this.state.userManagementServiceUrl}${this.state.getAllProfilesPath}`
        } else {
            url = `${this.state.userManagementServiceUrl}${this.state.getAllProfilesPath}/${profileId}`
        }
        try {
            const response = await axios.get(url, this.state.config)
            return response.data;

        } catch (error) {
            console.error(error);
            throw error.response;
            //return this.mockProfile();
        }
    }

    static getAllProfiles = async () => {
        const self = this;
        return new Promise(function (resolve, reject) {
            try {
                axios.get(self.state.userManagementServiceUrl + self.state.getAllProfilesPath, self.state.config).then(function(response) {
                    resolve(response.data.elements);
                });
            } catch (error) {
                console.error(error.response);
                //throw error.response;
                reject(error.response);
                //return this.mockProfileList();
            }
        });
    };

    static queryKits = async (profileId) => {
        let url = '';
        if (profileId === undefined || profileId === null) {
            url = `${this.state.kitServiceUrl}${this.state.getAllKitsPath}`
        } else {
            url = `${this.state.kitServiceUrl}${this.state.getAllKitsPath}/${profileId}`
        }
        try {
            const response = await axios.get(url, this.state.config)
            return this.integrateProfiles(response.data.elements);

        } catch (error) {
            console.error(error.response);
            throw error.response;
        }
    }

    static getAllkits = async () => {
        const self = this;
        return new Promise(async function (resolve, reject) {
            try {
                await axios.get(self.state.kitServiceUrl + self.state.getAllKitsPath, self.state.config).then(function(response) {
                    resolve(response.data.elements);
                }).catch(function(error) {
                    throw error;
                    reject(error);
                });
            } catch (error) {
                console.error(error.response);
                reject(error.response);
                //throw error.response;
                //return this.mockKitList();
            }
        });
    }

    static getKitByKitId = async (kitId) => {
        try {
            const response = await axios.get(this.state.kitServiceUrl + this.state.kitPath + "/" + kitId, this.state.config)
            return response.data;

        } catch (error) {
            console.error(error.response);
            throw error.response;
            //return this.mockSharedKitByKitId()[0];
        }
    }

    static shareKit = async (body) => {
        try {
            const response = await axios.post(this.state.userManagementServiceUrl + this.state.shareKitPath, body, this.state.config)
            return response.data;

        } catch (error) {
            console.error(error.response);
            throw error.response;
        }
    };

    static getSharedKitByKitId = async (kitId) => {
        try {
            const response = await axios.get(this.state.userManagementServiceUrl + this.state.getSharedKitByKitIDPath + kitId, this.state.config)
            console.debug(response.data)
            return response.data;

        } catch (error) {
            console.error(error);
            console.error(error.response);
            if (error.response.data.status === 401) {
                throw error.response;
            } else {
                throw error;
            }
            //return this.mockSharedKitByKitId();
        }
    }

    static getSharedKits = async () => {
        try {
            const response = await axios.get(this.state.userManagementServiceUrl + this.state.getSharedKitByAccountIDPath, this.state.config)
            return response.data;

        } catch (error) {
            console.error(error.response);
            throw error.response;
            //return this.mockKitList();
        }
    }

    static deleteSharedKit = async (kitId) => {
        try {
            const response = await axios.delete(this.state.userManagementServiceUrl + this.state.deleteSharedKitByOwnerPath + kitId, this.state.config)
            console.debug(response);
            return response.data;

        } catch (error) {
            console.error(error.response);
            throw error.response;
        }
    }

    static validateTubeId = async (tubeId) => {
        try {
            const response = await axios.get(this.state.kitServiceUrl + this.state.validateTubeKitPath + tubeId, this.state.config);
            return response.data;

        } catch (error) {
            console.error(error.response);
            throw error.response;
        }
    };

    static activateKit = async (body) => {
        try {
            const response = await axios.post(
                this.state.url + this.state.activateKitPath,
                body,
                this.state.config,
            );
            console.debug(response);
            Hub.dispatch('loadEducation', {});
            return response.data;

        } catch (error) {
            console.error(error.response);
            throw error.response;
        }
    };

    static updateKit = async (body) => {
        try {
            const response = await axios.put(
                this.state.url + this.state.updateKitPath, body, this.state.config,
            );
            console.debug(response);
            return response.data;

        } catch (error) {
            console.error(error.response);
            throw error.response;
        }
    }

    static joinKitsAndProfiles = async (profileList, kitList) => {
        profileList = profileList.sort((a, b) => {
            if (a.firstName + a.lastName < b.firstName + b.lastName) {
                return -1;
            }
            if (a.firstName + a.lastName > b.firstName + b.lastName) {
                return 1;
            }
            return 0;
        });

        var kitAndProfileJoinList = [];
        kitList.forEach(kit => {
            profileList.forEach((profile) => {
                if (profile.id === kit.userProfileId) {
                    kit = { ...profile, ...kit }
                    kitAndProfileJoinList.push(kit);
                }
            })
        })
        return kitAndProfileJoinList;
    }

    static getAccountByEmail = async (email) => {
        try {
            const response = await axios.get(this.state.userManagementServiceUrl + '/recover/username/' + email)
            return response.data;

        } catch (error) {
            console.error(error.response);
            throw error.response;
            //return this.mockKitList();
        }
    }

    static mockProfile = () => {
        var profile = { id: '1', cliaApproved: 'P', chosenAlias: 'Batman', collectedDate: '07-11-2020', firstName: 'Joann', lastName: 'Woodworth', birthDate: '07-31-1997', gender: 'Female' };
        return profile;
    }

    static mockProfileList = () => {
        // Hardcoded the mock data
        var profileList = [
            { id: '1', cliaApproved: 'P', chosenAlias: 'Batman', collectedDate: '07-11-2020', firstName: 'Joann', lastName: 'Woodworth', birthDate: '07-31-1997', gender: 'Female' },
            { id: '2', cliaApproved: 'P', chosenAlias: 'Superman', collectedDate: '07-12-2020', firstName: 'Ash', lastName: 'Woodworth', birthDate: '04-15-1980', gender: 'Female' },
            { id: '3', cliaApproved: 'P', chosenAlias: 'Spiderman', collectedDate: '07-l3-2020', firstName: 'Sable', lastName: 'Woodworth', birthDate: '11-23-1994', gender: 'Female' },
            { id: '4', cliaApproved: 'P', chosenAlias: 'Gandalf', collectedDate: '07-14-2020',  firstName: 'Serenity', lastName: 'Woodworth', birthDate: '01-09-1972', gender: 'Male' },
            { id: '5', cliaApproved: 'P', chosenAlias: 'JackSparrow', collectedDate: '07-15-2020', firstName: 'Jack', lastName: 'Donald', birthDate: '09-11-1976', gender: 'Male' },
            { id: '6', cliaApproved: 'P', chosenAlias: 'SamIAm', collectedDate: '07-16-2020', firstName: 'Sam', lastName: 'Applegate', birthDate: '06-30-2010', gender: 'Male' },
        ];
        return profileList;
    };

    static mockKitList = () => {
        // Hardcoded the mock data
        var kitList =
        [
            { id: "1234567890111", currentStatus: "NO_KIT", tubeId: "1234567890111", userProfileId: "1",
                tosConsent: true, tosIsMyself: true, tosIsLegallyAuthorized: false, tosTimestamp: "07-01-2019",
                researchConsent: false, researchIsMyself: true, researchIsLegallyAuthorized: false, researchConsentTimestamp: "07-01-2019",
                storeSample: false, storeIsMyself: true, storeIsLegallyAuthorized: false, biobankingTimestamp: "07-01-2019",
                firstName: 'Chun', lastName: 'Yang', birthDate: '07-31-1997', gender: 'Female' },

            { id: "1234567890112", currentStatus: "ACTIVATED", tubeId: "1234567890112", userProfileId: "3",
                tosConsent: true, tosIsMyself: true, tosIsLegallyAuthorized: false, tosTimestamp: "07-01-2019",
                researchConsent: true, researchIsMyself: false, researchIsLegallyAuthorized: true, researchConsentTimestamp: "07-01-2019",
                storeSample: true, storeIsMyself: false, storeIsLegallyAuthorized: true, biobankingTimestamp: "07-01-2019",
                firstName: 'E', lastName: 'Fan', birthDate: '09-09-1988', gender: 'Male' },

            { id: "1234567890113", currentStatus: "REPORTS_READY", tubeId: "1234567890113", userProfileId: "4",
                tosConsent: true, tosIsMyself: true, tosIsLegallyAuthorized: false, tosTimestamp: "07-01-2019",
                researchConsent: true, researchIsMyself: true, researchIsLegallyAuthorized: false, researchConsentTimestamp: "07-01-2019",
                storeSample: false, storeIsMyself: true, storeIsLegallyAuthorized: false, biobankingTimestamp: "07-01-2019",
                firstName: 'Ko', lastName: 'Beko', birthDate: '01-13-2001', gender: 'Female' },

            { id: "1234567890114", currentStatus: "TESTED", tubeId: "1234567890114", userProfileId: "6",
                tosConsent: true, tosIsMyself: true, tosIsLegallyAuthorized: false, tosTimestamp: "07-01-2019",
                researchConsent: true, researchIsMyself: true, researchIsLegallyAuthorized: false, researchConsentTimestamp: "07-01-2019",
                storeSample: true, storeIsMyself: true, storeIsLegallyAuthorized: false, biobankingTimestamp: "07-01-2019",
                firstName: 'Fifty', lastName: 'Lan', birthDate: '02-24-1990', gender: 'Male' }
        ]

        return kitList;
    }

    static mockResults = async (page) => {
        const response = await axios.get('http://localhost:3000/mockData/results.json');
        return response.data;
    }

    static mockSharedKit = () => {
        // Hardcoded the mock data
        var kitList = [
            { id: "1234567890115", currentStatus: "RECEIVED", tubeId: "1234567890111", userProfileId: "7" },
            { id: "1234567890116", currentStatus: "ACTIVATED", tubeId: "1234567890112", userProfileId: "8" },
            { id: "1234567890117", currentStatus: "DNA_EXTRACTED", tubeId: "1234567890113", userProfileId: "9" }
        ]
        return kitList;
    }

    static mockSharedKitByKitId = () => {
        var kitList = [{id: "123231789", currentStatus: "RECEIVED", tubeId: "1234567890122", userProfileId: "18",
                tosConsent: true, tosIsMyself: true, tosIsLegallyAuthorized: false, tosTimestamp: "07-01-2019",
                researchConsent: true, researchIsMyself: true, researchIsLegallyAuthorized: false, researchConsentTimestamp: "07-01-2019",
                storeSample: true, storeIsMyself: true, storeIsLegallyAuthorized: false, biobankingTimestamp: "07-01-2019", rwaDataShared: true}]

        return kitList
    }

    static mockKitId = (kitId) => {
        const kits = this.mockKitList();
        var resultKit = {};
        kits.forEach((kit) => {
            if (kit.id === kitId) {
                resultKit = kit;
            }
        })
        return resultKit;
    }

    static checkAndHandleLogOut = async () => {
        try {
            const user = await Auth.currentAuthenticatedUser();

            if (user === null || user === undefined || !sessionStorage.getItem('userSubId') || user.attributes.sub != sessionStorage.getItem('userSubId')) {
                throw 'error';
            }

            Hub.dispatch( 'userLoggedIn', {});
            return true;
        } catch (e) {
            this.state.redirectAfterLogin = window.location.pathname;
            Hub.dispatch( 'userLoggedOut', {});
            console.error(e);

            return false;
        }
        /*if (user === null || user === undefined || !sessionStorage.getItem('userSubId') || user.attributes.sub != sessionStorage.getItem('userSubId')) {

        }*/
    }
}

export default ApiUtil;
