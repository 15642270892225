import React, { Component } from 'react';
import { Auth, I18n } from 'aws-amplify';
import ApiUtil from "../../utils/ApiUtil";
import { withRouter } from 'react-router-dom';

class CourseAdvertising extends Component {

    constructor(props) {
        super(props);
        this.state = {
            hasKits: false
        };
    }

    componentDidMount() {
        this.getEducationUrl();
    }

    async getEducationUrl() {
        this.checkIfStillLoggedIn();
        try {
            let url = await ApiUtil.getEducationUrl();
            if (url !== '') {
                this.setState({ hasKits: true });
            }
        } catch (e) {
            this.setState({ hasKits: false });
            this.checkAndHandleLogOut(e);
        }
    }
    
    checkIfStillLoggedIn() {
        if (localStorage.getItem('innerFlag') === null || localStorage.getItem('innerFlag') === false) {
            Auth.signOut()
            .then(() => {
                this.props.history.replace('/signIn');
            })
            .catch(error => {
                console.error(error);
                if (error.code === 'NotAuthorizedException') {
                    this.props.history.replace('/signIn');
                }
            });
        } 
    }

    checkAndHandleLogOut = (error) => {
        var logout = false
        if (error.data != null) {
            console.error("Error status : " + error.data.status);
            if (error.data.status === 401) {
                logout = true;
            }
        } else if (error.status != null) {
            console.error("Error status : " + error.status);
            if (error.status === 401) {
                logout = true;
            }
        }
        
        if (logout) {
            Auth.signOut()
            .then(() => {
                this.props.history.replace('/signIn');
            })
            .catch(error => {
                console.error(error);
                if (error.code === 'NotAuthorizedException') {
                    this.props.history.replace('/signIn');
                }
            });
        }
    }
    
    render() {
        return (
            <div>
                {
                    this.state.hasKits &&
                    (
                        <div className='bodyLabel textAlignCenter'>
                            {I18n.get('courseAdvertisingMsg1')}
                            <a rel="noopener noreferrer" target='_blank' href={ApiUtil.getAdvertisingPathwayUrl()} className='blueLink'>
                                {I18n.get('courseAdvertisingMsg2')}
                            </a>
                            {I18n.get('courseAdvertisingMsg3')}
                            <a rel='noopener noreferrer' target='_blank' href={ApiUtil.getAdvertisingCourseUrl()} className='blueLink'>
                                {I18n.get('courseAdvertisingMsg4')}
                            </a>
                        </div>
                    )
                }
            </div>
        );
    }
}


export default withRouter(CourseAdvertising);
