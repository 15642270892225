import React from 'react';
import PropTypes from 'prop-types';
import { Message } from 'semantic-ui-react';
import './Message.css';

class CustomMessage extends React.Component {
    render() {
        const className = (this.props.className) ? this.props.className : "";
        const display = this.props.hide ? "none" : "block";
        return (
            <Message className={className} style={{ 'color': this.props.color, display: display  }}>
                {this.props.text}
            </Message>
        )
    }
}

CustomMessage.prototypes = {
    className: PropTypes.string,
    size: PropTypes.string,
    color: PropTypes.string,
    text: PropTypes.string,
    hide: PropTypes.bool
};

export default CustomMessage;