import React, { Component } from 'react';
import ApiUtil from "../components/utils/ApiUtil";
import {Dimmer, Grid, Loader, Segment} from 'semantic-ui-react';
import { Auth } from 'aws-amplify/lib/index';
import axios from "axios";

declare var jQuery: any;

class Education extends Component {
    constructor(props) {
        super(props);
        this.state = {
            url: '',
            isIframeLoaded: false,
            content: '',
            isContentLoaded: false
        }
    }

    componentDidMount() {
        const self = this;
        ApiUtil.checkAndHandleLogOut().then(function(response) {
            if(!response) {
                this.props.history.replace('/signIn');
            } else {
                let pushableEl = document.getElementsByClassName('pushable')[0];
                if(pushableEl)
                    pushableEl.classList.add('height-auto');
                this.getEducationUrl();
            }
        }.bind(this));
    }

    componentWillUnmount() {
        let pushableEl = document.getElementsByClassName('pushable')[0];
        if(pushableEl)
            pushableEl.classList.remove('height-auto');
    }

    getEducationUrl() {
        this.checkIfStillLoggedIn();
        //try{
            //ApiUtil.getEducationUrl().then(function(response) {

                axios({
                    method: 'post',
                    url: 'https://strategene.me/wp-admin/admin-ajax.php',
                    transformRequest: [function (data, headers) {
                        delete headers.post['Content-Type'];

                        var bodyFormData = new FormData();
                        var origin = window.location.origin;
                        if (process.env.REACT_APP_ENV === 'development') {
                            origin = 'https://staging.strategene.me';
                        }

                        bodyFormData.append('action', 'get_secure_app_content');
                        bodyFormData.append('top-domain', origin);
                        bodyFormData.append('post-id', '12');
                        bodyFormData.append('course-id', '');

                        return bodyFormData;
                    }],
                }).then(function(response) {
                    this.setState({ content: response.data, isContentLoaded: true }, function() {
                        var $educationCategorySliders = jQuery('.education-category-slider');

                        var applyEdCatSlickToElement = function ($el) {

                            let $sliderNav = $el.siblings('.slider-nav-container');
                            $el.slick({
                                slidesToShow: 3,
                                slidesToScroll: 3,
                                infinite: false,
                                variableWidth: true,
                                dots: true,
                                arrows: true,
                                appendArrows: $sliderNav.find('.slider-nav'),
                                appendDots: $sliderNav.find('.slider-nav'),
                                prevArrow: $sliderNav.find('.slick-prev'),
                                nextArrow: $sliderNav.find('.slick-next'),
                                responsive: [
                                    {
                                        breakpoint: 1266,
                                        settings: {
                                            slidesToShow: 2,
                                            slidesToScroll: 2
                                        }
                                    },
                                    {
                                        breakpoint: 845,
                                        settings: {
                                            slidesToShow: 1,
                                            slidesToScroll: 1
                                        }
                                    },
                                    {
                                        breakpoint: 425,
                                        settings: {
                                            centerMode: true,
                                        }
                                    }
                                ],
                            });
                        };

                        $educationCategorySliders.each(function () {
                            applyEdCatSlickToElement(jQuery(this));
                        });

                        var $expandoTriggers = jQuery('.expando-trigger');
                        $expandoTriggers.on('click', function () {
                            var $this = jQuery(this),
                                $target = jQuery('#' + $this.data('expando-target'));

                            if ('false' === $this.attr('aria-expanded')) {
                                $target.slideDown();
                                $this.attr('aria-expanded', 'true');
                                $target.attr('aria-hidden', 'false');
                            } else {
                                $target.slideUp();
                                $this.attr('aria-expanded', 'false');
                                $target.attr('aria-hidden', 'true');
                            }
                        });

                    });

                }.bind(this))
                .catch(function(error) {
                    console.log(error);
                    const errorContent = '<div class="myGeneticsPage-contentPanel"><div class="ui negative message" style="margin-top: 100px;">\n' +
                        '  <div class="header textAlignCenter center">\n' +
                        '    We are having trouble accessing the education portal- please try again in a few minutes.\n' +
                        '  </div>\n' +
                        '  </div></div>';
                    this.setState({ content: errorContent, isContentLoaded: true });
                }.bind(this));

            //}.bind(this));

        //} catch (error) {
            //this.checkAndHandleLogOut(error);
        //}
    }
    
    checkIfStillLoggedIn() {
        if (localStorage.getItem('innerFlag') === null || localStorage.getItem('innerFlag') === false) {
            Auth.signOut()
            .then(() => {
                this.props.history.replace('/signIn');
            })
            .catch(error => {
                console.error(error);
                if (error.code === 'NotAuthorizedException') {
                    this.props.history.replace('/signIn');
                }
            });
        } 
    }
    
    checkAndHandleLogOut = (error) => {
        var logout = false
        if (error.data != null) {
            console.error("Error status : " + error.data.status);
            if (error.data.status === 401) {
                logout = true;
            }
        } else if (error.status != null) {
            console.error("Error status : " + error.status);
            if (error.status === 401) {
                logout = true;
            }
        }
        
        if (logout) {
            Auth.signOut()
            .then(() => {
                this.props.history.replace('/signIn');
            })
            .catch(error => {
                console.error(error);
                if (error.code === 'NotAuthorizedException') {
                    this.props.history.replace('/signIn');
                }
            });
        }
    }

    oniFrameLoaded = () => {
        this.setState({ isIframeLoaded: true });
        this.setState({ isContentLoaded: true })
    }

    render() {
        return (
            <Segment vertical className="allPage-rootSegment">
                <Grid className='educationPage-grid allPage-rootPanel'>
                    <Grid.Row className='educationPage-gridRow'>
                        <Grid.Column className='educationPage-gridColumn'>
                            <Dimmer active={!this.state.isContentLoaded}>
                                <Loader />
                            </Dimmer>
                            <div dangerouslySetInnerHTML={{__html: this.state.content}} />
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Segment>
        );
    }
}

export default Education;
