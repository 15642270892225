import React from 'react';
import { Divider, Form, Grid, Checkbox, Radio } from 'semantic-ui-react'
import { I18n } from 'aws-amplify';
import Moment from 'moment';
import CancelButton from '../../components/widgets/button/CancleButton';
import CTAButton from '../../components/widgets/button/CTAButton';
import Forms from '../../components/widgets/form/Forms';
import PageGrid from '../../components/widgets/grid/PageGrid';
import PanelGridColumn from '../../components/widgets/grid/PanelGridColumn';
import ApiUtil from "../../components/utils/ApiUtil";

class ResearchConsentPolicy extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            privacyPolicy: false,
            ageAgreement: false,
            authAgremment: false,
            validationPass: false,
        }
    }

    componentDidMount() {
        this.setState(this.props.location.state);
    }

    handlePrivacyPolicyChange = async () => {
        await this.setState(prevState => ({ privacyPolicy: !prevState.privacyPolicy }));
        this.checkValidation();
    };

    handleAgeAgreementChange = async () => {
        if (this.state.request.is18YearsRadioButtonDisabled) {
            return;
        }
        await this.setState(prevState => ({
            ageAgreement: !prevState.ageAgreement,
            authAgremment: false
        }));
        this.checkValidation();
    };

    handleAuthAgreementChange = async () => {
        await this.setState(prevState => ({
            ageAgreement: false,
            authAgremment: !prevState.authAgremment
        }));
        this.checkValidation();
    };

    handleAcceptButtonClick = async () => {
        await this.setState({request: {
            ...this.state.request,
            researchConsent: true,
            researchIsMyself: this.state.ageAgreement,
            researchIsLegallyAuthorized: this.state.authAgremment,
            researchConsentTimestamp: Moment(new Date()).format('MM-DD-YYYY'),
            storeSample: false,
            storeIsMyself: true,
            storeIsLegallyAuthorized: true,
            biobankingTimestamp: Moment(new Date()).format('MM-DD-YYYY')
        }})

        //pathname: '/biobanking',
        this.props.history.push({
            pathname: '/summary',
            state: {request: this.state.request}
        });
    }

    handleNotConsentButtonClick = async () => {
        await this.setState({request: {
            ...this.state.request,
            researchConsent: false,
            researchIsMyself: this.state.ageAgreement,
            researchIsLegallyAuthorized: this.state.authAgremment,
            researchConsentTimestamp: Moment(new Date()).format('MM-DD-YYYY'),
        }})

        this.props.history.push({
            pathname: '/biobanking',
            state: {request: this.state.request}
        });
    }

    checkValidation = () => {
        this.setState({ validationPass: this.state.privacyPolicy && (this.state.authAgremment || this.state.ageAgreement) });
    }

    render() {
        const name = this.state.request ? this.state.request.name : ""
        const gender = this.state.request ? this.state.request.gender : I18n.get('tosppDateodSexUnknown')
        const dateOfBirth = this.state.request ? `${gender}${I18n.get('tosppDateodBirthDes')}${this.state.request.dateOfBirth}` : "";
        const is18YearsRadioButtonDisabled = this.state.request ? this.state.request.is18YearsRadioButtonDisabled : true;
        var ageAgreementText = this.state.request ? I18n.get('tosppAgree1').replace("param1", this.state.request.name) : "";
        var authAgreementText = this.state.request ? I18n.get('tosppAgree2').replace("param1", this.state.request.name) : "";

        return (
            <PageGrid className="allPage-rootPanel">
                <Grid.Row>
                    <PanelGridColumn size='large'>
                        <Forms size='large' header={I18n.get('researchPpTitle')}>
                            <Divider />
                            <Grid textAlign='left' verticalAlign='top'>
                                <Grid.Row>
                                    <Grid.Column>
                                        <Checkbox onChange={this.handlePrivacyPolicyChange}
                                            checked={this.state.privacyPolicy}
                                            id="privacyPolicy"
                                            label={<label>{I18n.get('researchPpRead')}
                                                <a rel="noopener noreferrer" target='_blank' className='blueLink' href={ApiUtil.getResearchConsentPolicyUrl()}>{I18n.get('researchPpTitle')}</a>
                                            </label>}>
                                        </Checkbox>
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                            <Divider />

                            <div className="bodyTitleLabel">{name}</div>
                            <div className='bodyLabel' hint='true'>{dateOfBirth}</div>

                            <div className="form-radioButtonPanel section36">
                                <Form.Field>
                                    <Radio name='radioGroup' radio label={ageAgreementText} checked={this.state.ageAgreement} onClick={this.handleAgeAgreementChange} disabled={is18YearsRadioButtonDisabled} />
                                </Form.Field>

                                <Form.Field>
                                    <Radio name='radioGroup' radio label={authAgreementText} checked={this.state.authAgremment} onClick={this.handleAuthAgreementChange} />
                                </Form.Field>
                            </div>

                            <CancelButton disabled={!this.state.validationPass} onClick={this.handleNotConsentButtonClick}>
                                {I18n.get("researchPpCancelButton")}
                            </CancelButton>

                            <CTAButton ref={this.submitButton} submit='true' margintop='true' onClick={this.handleAcceptButtonClick} disabled={!this.state.validationPass}>
                                {I18n.get('researchPpConsentButton')}
                            </CTAButton>

                            <div className="bodyLabel textAlignCenter" hint='true' margin='true' dangerouslySetInnerHTML={{__html: I18n.get('researchPpConsentDes')}}></div>
                        </Forms>
                    </PanelGridColumn>
                </Grid.Row>
            </PageGrid>
        )
    }
}

export default ResearchConsentPolicy;
