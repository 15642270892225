import React from 'react';
import PropTypes from 'prop-types';
import './Menu.css';
import { NavLink as Link, withRouter } from 'react-router-dom';

class MenuItem extends React.Component {
    render() {
        const className = (this.props.className)? "menuItem item" + this.props.className: "menuItem item";
        return (
            <Link to={this.props.path} className={className} style={this.props.style} onClick={this.props.clickHandler}>
                {this.props.text}
            </Link>
        );
    }
}

MenuItem.prototypes = {
    className: PropTypes.string,
    clickHandler: PropTypes.func,
    path: PropTypes.string,
    style: PropTypes.string,
    text: PropTypes.string
};

export default withRouter(MenuItem);