import React, { Component } from 'react';
import { Auth } from 'aws-amplify/lib/index';
import { NavLink as Link, withRouter } from 'react-router-dom';
import { Grid, Loader } from 'semantic-ui-react';
import { I18n } from 'aws-amplify';
import PageGrid from '../../widgets/grid/PageGrid';
import PanelGridColumn from '../../widgets/grid/PanelGridColumn';
import Forms from '../../widgets/form/Forms';
import ApiUtil from '../../utils/ApiUtil'
import Amplify from 'aws-amplify';

const queryString = require('query-string');

class ChangeEmailLanding extends Component {


    constructor(props) {
        super(props);
        this.state = {
            email: '',
            username: '',
            verifyCodeLoader: false
        };
    }

    componentDidMount() {
        this.verifyEmailCode();
    }

    async verifyEmailCode() {

        var path = '';
        if (process.env.REACT_APP_ENV === 'production' || process.env.REACT_APP_ENV === 'staging') {
            path = window.location.origin + '/aws-exports.json';
        } else if (process.env.REACT_APP_ENV === 'development') {
            path = window.location.origin + '/aws-exports-dev.json';
        }

        ApiUtil.configure('');
        this.setState({ verifyCodeLoader: true });
        fetch(path)
            .then(response => {
                return process.env.REACT_APP_ENV === 'development' ? response.json() : response.text();
            })
            .then(
                async result => {
                    try {
                        let decodeResult = window.atob(result);
                        const decodedJason = JSON.parse(decodeResult);
                        Amplify.configure(decodedJason);
                        ApiUtil.configure(decodedJason.API.endpoints[0].endpoint);
                        ApiUtil.updateIdToken();
                        ApiUtil.setEmbeddedUrl(decodedJason.embeddedUrl);
                        ApiUtil.setExternalUrl(decodedJason.externalUrl);
                        ApiUtil.setPolicyUrl(decodedJason.policyUrl);
                    } catch (e) {
                        console.log('dev result:' + JSON.stringify(result));
                        this.checkAndHandleLogOut(e);
                    }

                    const parsed = queryString.parse(this.props.location.search);
                    if (parsed.code !== undefined && parsed.code !== '') {
                        this.setState({ username: parsed.username });
                        try {
                            let result = await Auth.verifyCurrentUserAttributeSubmit('email', parsed.code);
                            if (result === 'SUCCESS') {
                                let user = await Auth.currentAuthenticatedUser({ bypassCache: true })
                                if (user != null) {
                                    this.setState({ email: user.attributes.email });
                                }
                            }
                        } catch (e) {
                            if (e === 'No current user') {
                                this.props.history.push({
                                    pathname: '/signIn',
                                    state: {
                                        code: parsed.code,
                                        username: parsed.username,
                                        status: 'chnageEmail'
                                    }
                                });
                            } else {
                                this.props.history.push({
                                    pathname: '/linkExpired',
                                    state: {
                                        username: parsed.username
                                    }
                                });
                            }
                        }
                    } else {
                        this.setState({ status: this.props.inputs.status });
                        this.props.history.push({
                            pathname: '/linkExpired',
                            state: {
                                username: parsed.username
                            }
                        });
                    }
                    this.setState({ verifyCodeLoader: false });
                },
                error => {
                    console.error(error);
                }
            );
    }
    
    checkAndHandleLogOut = (error) => {
        var logout = false
        if (error.data != null) {
            console.error("Error status : " + error.data.status);
            if (error.data.status === 401) {
                logout = true;
            }
        } else if (error.status != null) {
            console.error("Error status : " + error.status);
            if (error.status === 401) {
                logout = true;
            }
        }
        
        if (logout) {
            Auth.signOut()
            .then(() => {
                this.props.history.replace('/signIn');
            })
            .catch(error => {
                console.error(error);
                if (error.code === 'NotAuthorizedException') {
                    this.props.history.replace('/signIn');
                }
            });
        }
    }

    render() {
        return (
            <PageGrid className="allPage-rootPanel">
                {
                    this.state.verifyCodeLoader &&
                    <Loader style={{ marginTop: 50 }} active />
                }
                <Grid.Row>
                    <PanelGridColumn>
                        <Forms className='section18' header={I18n.get('changeEmailConfirmationTitle')}>
                            <div className='bodyLabel section12'>{I18n.get('changeEmailConfirmationHi')}, {this.state.username}</div>
                            <p className='bodyLabel'>{I18n.get('ChangeEmailSuccessMsg1')}
                                <span className='form-emailLabel'> {this.state.email}</span>
                            </p>
                            <div className='bodyLabel'>{I18n.get('ChangeEmailSuccessMsg2')}</div>
                            <a className='blueLink textAlignLeft section24' href='mailto:support@strategene.me'>
                                {I18n.get('createAccountConfirmationSupport')}
                            </a>
                            <div className='bodyLabel'>{I18n.get('strateGene1')}</div>
                            <div className='bodyLabel section24' dangerouslySetInnerHTML={{__html: I18n.get('strateGene2')}}></div>
                            <div className='bodyLabel textAlignCenter'>{I18n.get('strateGene3')}</div>
                        </Forms>

                        <Link className='blueLink' to='/signIn'>{I18n.get('createAccountConfirmationSignIn')}</Link>

                    </PanelGridColumn>
                </Grid.Row>
            </PageGrid>
        );
    }
}

export default withRouter(ChangeEmailLanding);
