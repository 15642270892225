import React from 'react';
import PropTypes from 'prop-types';
import './SearchBar.css';
import { Image, Button } from 'semantic-ui-react';

class SearchBar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isError: false,
            errorMessage: ""
        }
    }

    render() {
        let input;
        const className = (this.props.className) ? "ui icon input search " + this.props.className : "ui icon input search";
        const handleClick = e => {
            e.preventDefault();
            this.props.onSearch(input.value);
        };

        const handleInputKeyUp = e => {
            e.preventDefault();
            // User press enter
            if (e.keyCode === 13) {
                this.props.onSearch(input.value);
            }
        };

        return (
            <div className={className}>
                <input
                    onKeyUp={handleInputKeyUp}
                    placeholder={this.props.placeholder}
                    type='text'
                    ref={n => (input = n)}
                    autoComplete='off'
                />
                <Button className='searchBar' onClick={handleClick} icon>
                    <Image className='search-bar-icon icon-clickable' src={'../images/icon-search.svg'}/>
                </Button>
            </div>
        );
    }

};

SearchBar.prototypes = {
    className: PropTypes.string,
    placeholder: PropTypes.string
};

export default SearchBar;
