import React from 'react';
import ReactDOM from 'react-dom';
import { Form, Grid, Message, Image } from 'semantic-ui-react'
import { I18n } from 'aws-amplify';
import { Auth, JS } from 'aws-amplify/lib/index';
import ValidationUtil from "../components/utils/ValidationUtil";
import Moment from 'moment';
import CTAButton from '../components/widgets/button/CTAButton';
import DatePicker from "../components/widgets/datePicker/DataPicker";
import Input from "../components/widgets/Input/Input";
import Forms from '../components/widgets/form/Forms';
import PageGrid from '../components/widgets/grid/PageGrid';
import PanelGridColumn from '../components/widgets/grid/PanelGridColumn';
import ApiUtil from "../components/utils/ApiUtil";
import Dropdowns from '../components/widgets/dropdown/Dropdowns';
import BuyKitAdvertising from "../components/widgets/advertising/BuyKitAdvertising";

class ActivateKit extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.activateKitCodeInput = React.createRef();
	this.chosenAliasInput = React.createRef();
	this.collectedDatePicker = React.createRef();
        this.firstNameInput = React.createRef();
        this.lastNameInput = React.createRef();
        this.datePicker = React.createRef();
        this.birthSexDropdown = React.createRef();
        this.profielDropdown = React.createRef();
        this.submitButton = React.createRef();
        this.state = {
            profileId: "",
            kitCode: "",
	    chosenAlias: "",
	    cliaApproved: "",
	    collectedDate: "",
            firstName: "",
            lastName: "",
            gender: "",
            warning: "",
            errors: [],
            date: '',
            datePickerMaxDate: '',
            isFirstTimeValidation: false,
            validationPass: true,
            profileDropdownOptions: [],
            birthSexDropdownOptions: [
                { key: I18n.get('ActivateKitBirthSexFemale'), text: I18n.get('ActivateKitBirthSexFemale'), value: I18n.get('ActivateKitBirthSexFemale') },
                { key: I18n.get('ActivateKitBirthSexMale'), text: I18n.get('ActivateKitBirthSexMale'), value: I18n.get('ActivateKitBirthSexMale') }
            ],
            profileList: []
        };

        this.activateButtonOnClick = this.activateButtonOnClick.bind(this);
        this.profileDropdownOnChange = this.profileDropdownOnChange.bind(this);
        this.handleFormInput = this.handleFormInput.bind(this);
        this.handleDatePickerChange = this.handleDatePickerChange.bind(this);
        this.getAllProfiles = this.getAllProfiles.bind(this);
        this.container = React.createRef();
    }

    componentDidMount() {
        const self = this;
                var today = Moment(new Date(), 'MMMM DD, YYYY');
               this.setState({ datePickerMaxDate: today });
               this.getAllProfiles();
        /*
        ApiUtil.checkAndHandleLogOut().then(function(response) {
            if(response) {
                // Set the maximum date for picker
                var today = Moment(new Date(), 'MMMM DD, YYYY');
                self.setState({ datePickerMaxDate: today });
                self.getAllProfiles();
            } else {
                self.props.history.replace('/signIn');
            }
        });
        */

    }

    checkIfStillLoggedIn() {
        if (localStorage.getItem('innerFlag') === null || localStorage.getItem('innerFlag') === false) {
            Auth.signOut()
            .then(() => {
                this.props.history.replace('/signUp');
            })
            .catch(error => {
                console.error(error);
                if (error.code === 'NotAuthorizedException') {
                    this.props.history.replace('/signUp');
                }
            });
        }
    }
    
    getAllProfiles = async () => {
        this.checkIfStillLoggedIn();
        this.profielDropdown.current.setLoadding(true);

       // const profileList = await ApiUtil.getUnusedProfieList();
        const self = this;
        ApiUtil.getUnusedProfieList().then(function(profileList) {
            var profileDropdownOptions = profileList.map((profile) => {
                const name = profile.firstName + " " + profile.lastName;
                return (
                    { key: profile.id, text: name, value: profile.id }
                )
            });

            profileDropdownOptions.push({
                key: I18n.get('ActivateKitProfileDropdownNewProfile'),
                text: I18n.get('ActivateKitProfileDropdownNewProfile'),
                value: I18n.get('ActivateKitProfileDropdownNewProfile')
            })

            self.setState({
                profileList: profileList,
                profileDropdownOptions: profileDropdownOptions
            })
            self.profielDropdown.current.setLoadding(false);
        }).catch(function(error) {
            console.error('mj2', error);
            if (self.profielDropdown.current !== null) {
                self.profielDropdown.current.setLoadding(false);
            }
            if (self.profielDropdown === null || self.profielDropdown === undefined) {
                error.status = 401;
            }
            self.checkAndHandleLogOut(error);
        });
    }
    
    checkAndHandleLogOut = (error) => {
        var logout = false
        if (error.data != null) {
            console.error("Error status : " + error.data.status);
            if (error.data.status === 401) {
                logout = true;
            }
        } else if (error.status != null) {
            console.error("Error status : " + error.status);
            if (error.status === 401) {
                logout = true;
            }
        }
        
        if (logout) {
            Auth.signOut()
            .then(() => {
                this.props.history.replace('/signUp');
            })
            .catch(error => {
                console.error(error);
                if (error.code === 'NotAuthorizedException') {
                    this.props.history.replace('/signUp');
                }
            });
        }
    }

    activateButtonOnClick = async () => {
        this.checkIfStillLoggedIn();
        await this.setState({ isFirstTimeValidation: true })
        await this.checkValidation();
        if (!this.state.validationPass) {
            let formHandler = ReactDOM.findDOMNode(this.container.current);
            formHandler.scrollTop = 0;
            return;
        }
        this.submitButton.current.setLoading(true)
        try {
            console.log('state');
            console.log(this.state);
            const response = await ApiUtil.validateTubeId(this.state.kitCode);
            if (response.status === 'valid') {
                this.goToTosppPage();
            } else {
                try {
                    if (response.data.errorCode === 'T003') {
                        this.activateKitCodeInput.current.setError(true, undefined, this.renderErrorMsg());
                    } else if (response.data.errorCode === 'T0002') {
                        this.activateKitCodeInput.current.setError(true, undefined, this.renderErrorNotVaildMsg());
                    } else {
                        this.activateKitCodeInput.current.setError(true, undefined, this.renderErrorMsg());
                    }
                    this.submitButton.current.setLoading(false)
                } catch(e) {
                    console.log(e);
                }
            }
        } catch (error) {
            console.log('state in catch');
            console.log(error);
            if (typeof error.data == 'undefined') {
                this.activateKitCodeInput.current.setError(true, undefined, this.renderErrorMsg());
            } else if (error.data.errorCode === 'T003') {
                this.activateKitCodeInput.current.setError(true, undefined, this.renderErrorMsg());
            } else if (error.data.errorCode === 'T0002') {
                this.activateKitCodeInput.current.setError(true, undefined, this.renderErrorNotVaildMsg());
            } else {
                this.activateKitCodeInput.current.setError(true, undefined, this.renderErrorMsg());
            }
            let formHandler = ReactDOM.findDOMNode(this.container.current);
            formHandler.scrollTop = 0;
            this.checkAndHandleLogOut(error);
        }
        this.submitButton.current.setLoading(false)
    }

    profileDropdownOnChange = (event, data) => {
        this.setState({ profileId: data.value });
        if (data.value !== I18n.get('ActivateKitProfileDropdownNewProfile')) {
            this.state.profileList.forEach((profile) => {
                if (profile.id === data.value) {
                    this.setState({
                        profileId: profile.id,
			chosenAlias: profile.chosenAlias,
			cliaApproved: "",
                        collectedDate: Moment(profile.collectedDate, 'MM-DD-YYYY').format('MMMM DD, YYYY'),
                        firstName: profile.firstName,
                        lastName: profile.lastName,
                        gender: profile.gender,
                        date: Moment(profile.birthDate, 'MM-DD-YYYY').format('MMMM DD, YYYY')
                    })
                }
            })
        }

        this.checkValidation();
    }

    birthSexDropdownOnChange = (event, data) => {
        this.setState({ gender: data.value });
        this.checkValidation();
    }

    handleFormInput = async (event) => {
        event.preventDefault();
        await this.setState({ [event.target.id]: event.target.value });
        this.checkValidation();
    }

    handleDatePickerChange = (event, name, value) => {
        //console.log('-=-=-=-=-=-=-=-=-=-=-=-=-=-=');
        //console.log(name, value);
        if (this.state.hasOwnProperty(name)) {
            this.setState({ [name]: value });
        }
        this.checkValidation();
    };

    checkValidation = async () => {
        // Don't validate any field until user click submit button at the first time.
        if (!this.state.isFirstTimeValidation) {
            return;
        }

        const firstNameValidation = await ValidationUtil.validateFirstName(this.state.firstName);
        const lastNameValidation = await ValidationUtil.validateLastName(this.state.lastName);

        var dateOfBirthValidation = { status: false, msg: I18n.get('errorMessageRequiredField') };
        if (this.state.date) {
            var date = Moment(this.state.date, 'MMMM DD, YYYY');
            if (!date.isValid()) {
                dateOfBirthValidation = { status: false, msg: I18n.get('errorMessageRequiredField') };

            } else {
                dateOfBirthValidation = { status: true, msg: undefined };
            }
        }

        this.firstNameInput.current.setError(!firstNameValidation.status, firstNameValidation.msg);
        this.lastNameInput.current.setError(!lastNameValidation.status, lastNameValidation.msg);
        this.datePicker.current.setError(!dateOfBirthValidation.status, dateOfBirthValidation.msg);

        var validationPass = false;
        if (this.state.profileId === I18n.get('ActivateKitProfileDropdownNewProfile')) {
            validationPass = (this.state.profileId.length > 0)
                && firstNameValidation.status
                && lastNameValidation.status
                && dateOfBirthValidation.status
                && this.state.gender.length > 0

        } else {
            validationPass = (this.state.profileId.length > 0)
        }

        await this.setState({
            validationPass: validationPass
        });
    }

    goToTosppPage = async () => {
	    //console.log('goToTosppPage');
        //console.log(this.state);
        const dateOfBirthMoment = Moment(this.state.date, 'MMMM DD, YYYY')
        const is18YearsRadioButtonDisabled = ValidationUtil.validate18YearsOld(dateOfBirthMoment)
        this.props.history.push({
            pathname: '/tos-PrivactPolicy',
            state: {
                request: {
                    kitCode: this.state.kitCode,
                    profileId: this.state.profileId,
                    chosenAlias: this.state.chosenAlias,
	            cliaApproved: "",
                    collectedDate: this.state.collectedDate,
                    firstName: this.state.firstName,
                    lastName: this.state.lastName,
                    name: this.state.firstName + " " + this.state.lastName,
                    gender: this.state.gender,
                    dateOfBirth: this.state.date,
                    is18YearsRadioButtonDisabled: is18YearsRadioButtonDisabled
                }
            }
        });
    }

    renderErrorWrongCharactersMsg = () => {
        return (
            <div>
                {I18n.get('ActivateKitCodeWrongCharactersErrorMsg')}
            </div>
        )
    }

    renderErrorNotVaildMsg = () => {
        return (
            <div>
                {I18n.get('ActivateKitCodeKitCodeNotVaild')}
                <a rel="noopener noreferrer" className='blueLink' target='_blank' href={I18n.get('ActivateKitCodeKitCodeAlreadyInUsedErrorMsg2')}>
                    {I18n.get('ActivateKitCodeKitCodeAlreadyInUsedErrorMsg2')}
                </a>
                {I18n.get('ActivateKitCodeKitCodeAlreadyInUsedErrorMsg3')}
            </div>
        )
    }

    renderErrorMsg = () => {
        return (
            <div>
                {I18n.get('ActivateKitCodeKitCodeAlreadyInUsedErrorMsg1')}
                <a rel="noopener noreferrer" className='blueLink' target='_blank' href={I18n.get('ActivateKitCodeKitCodeAlreadyInUsedErrorMsg2')}>
                    {I18n.get('ActivateKitCodeKitCodeAlreadyInUsedErrorMsg2')}
                </a>
                {I18n.get('ActivateKitCodeKitCodeAlreadyInUsedErrorMsg3')}
            </div>
        )
    }

    render() {
        const isNewProfileSelected = (this.state.profileId === I18n.get('ActivateKitProfileDropdownNewProfile')) ? true : false;
        const showNewProfileRows = isNewProfileSelected ? '' : 'none';
        const isActivateButtonDisabled = (!this.state.validationPass || !this.state.kitCode || !this.state.profileId)
            || (isNewProfileSelected && (!this.state.firstName || !this.state.lastName || this.state.date.length <= 0 || this.state.gender.length <= 0 ))
        const showErrorMessage = (this.state.errors.length === 0) ? { display: "none" } : { display: "" };
        return (
            <PageGrid ref={this.container} className="allPage-rootSegment">
                <Grid.Row>
                    <PanelGridColumn>
                        <Forms className='section18' onSubmit={this.activateButtonOnClick} header={I18n.get('ActivateKitTitle')}>
                            <div className="bodyTitleLabel"> {I18n.get('ActivateKitBarcodeQs')} </div>
                            <Input ref={this.activateKitCodeInput} type="text" id="kitCode"
                                placeholder={I18n.get('ActivateKitBarcode')} name={I18n.get('ActivateKitBarcode')}
                                onChange={this.handleFormInput} />

                            <Image className="activateKitPage-tubeImage" src='./images/activationCodeLocation.png' />
                            <div className="bodyLabel descriptionLabel-formLayout" hint='true'> {I18n.get('ActivateKitBarcodeDs')} </div>

                            <div className="bodyTitleLabel"> {I18n.get('ActivateKitProfileQs')} </div>
                            <Form.Field className="form-label-group">
                                <Dropdowns ref={this.profielDropdown} selection
                                    options={this.state.profileDropdownOptions}
                                    placeholder={I18n.get('ActivateKitProfileDropdownPlaceholder')}
                                    pointing='top right'
                                    onChange={this.profileDropdownOnChange}
                                    value={this.state.profileId} />
                            </Form.Field>

                            <div style={{ display: showNewProfileRows }}>
                                <Input ref={this.chosenAliasInput} type="text" id="chosenAlias"
                                    placeholder={I18n.get('ActivateKitChosenAlias')} name={I18n.get('ActivateKitChosenAlias')}
                                    onChange={this.handleFormInput} />

                                <Input ref={this.firstNameInput} type="text" id="firstName"
                                    placeholder={I18n.get('ActivateKitFirstName')} name={I18n.get('ActivateKitFirstName')}
                                    onChange={this.handleFormInput} />

                                <Input ref={this.lastNameInput} type="text" id="lastName"
                                    placeholder={I18n.get('ActivateKitLastName')} name={I18n.get('ActivateKitLastName')}
                                    onChange={this.handleFormInput} />

                                <DatePicker ref={this.datePicker}
                                    placeholder={I18n.get('ActivateKitDateOfBirth')}
                                    maxDate={this.state.datePickerMaxDate}
                                    onChange={this.handleDatePickerChange}
                                />
                                <Form.Field className="form-label-group">
                                    <Dropdowns ref={this.birthSexDropdown} selection
                                        options={this.state.birthSexDropdownOptions}
                                        placeholder={I18n.get('ActivateKitBirthSex')}
                                        pointing='top right'
                                        onChange={this.birthSexDropdownOnChange}
                                        value={this.state.gender} />
                                </Form.Field>

                                <DatePicker ref={this.collectedDatePicker}
                                    name="collectedDate"
                                    id="collectedDate"
                                    defaultDate={new Date()}
                                    placeholder={I18n.get('ActivateKitCollectedDate')}
                                    maxDate={this.state.datePickerMaxDate}
                                    value={this.state.datePickerMaxDate}
                                    onChange={this.handleDatePickerChange}
                                />
                            </div>

                            <CTAButton ref={this.submitButton} submit='true' margintop='true' disabled={isActivateButtonDisabled} onClick={this.activateButtonOnClick}>
                                {I18n.get('ActivateKitActivateButton')}
                            </CTAButton>

                            <Message color='red' style={showErrorMessage}>
                                {this.state.errors}
                            </Message>
                        </Forms>
                        <BuyKitAdvertising />
                    </PanelGridColumn>
                </Grid.Row>
            </PageGrid>
        )
    }
}

export default ActivateKit
