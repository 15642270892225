/*
 * Copyright 2017-2017 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License"). You may not use this file except in compliance with
 * the License. A copy of the License is located at
 *
 *     http://aws.amazon.com/apache2.0/
 *
 * or in the "license" file accompanying this file. This file is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR
 * CONDITIONS OF ANY KIND, either express or implied. See the License for the specific language governing permissions
 * and limitations under the License.
 */

import { Auth } from 'aws-amplify';
import { SelectMFAType, AmplifyUI } from 'aws-amplify-react';
import {Grid, Image, Message, Segment, Form, Dropdown} from 'semantic-ui-react';

import * as React from 'react';
import InputMask from 'react-input-mask';
import { I18n, ConsoleLogger as Logger } from '@aws-amplify/core';
import PanelGridColumn from "../grid/PanelGridColumn";

import PageGrid from "../grid/PageGrid";
import CTAButton from "../../widgets/button/CTAButton";
import Input from "../Input/Input";
import CustomTOTPSetupComp from "./CustomTOTPSetupComp"

import './CustomSelectMFAType.css';

const logger = new Logger('SelectMFAType');

export class CustomSelectMFAType extends SelectMFAType
{
    constructor(props) {
        super(props);

        this.handlePhoneInputChange = this.handlePhoneInputChange.bind(this);
        this.handleCountryCodeChange = this.handleCountryCodeChange.bind(this);
        this.triggerTOTPEvent = this.triggerTOTPEvent.bind(this);
        this.originalVerify = this.originalVerify.bind(this);
        this.checkShowPhoneNumber = this.checkShowPhoneNumber.bind(this);

        this.inputs = false;
        //console.log('mj', this.props)
        if(this.props.authData && 'preferredMFA' in this.props.authData) {
            this.inputs = [];
            if(this.props.authData.preferredMFA === 'SOFTWARE_TOKEN_MFA') {
                this.inputs['TOTP'] = true;
            } else {
                this.inputs[this.props.authData.preferredMFA] = true;
            }
        }

        this.state.value = false;
        this.state.countryCode = '';
        this.state.phoneNumber = '';
        if(this.props.authData && this.props.authData.attributes && this.props.authData.attributes.phone_number) {
            this.state.countryCode = this.props.authData.attributes.phone_number.substring(0,2);
            this.state.phoneNumber = this.props.authData.attributes.phone_number.substring(2);
        } else {
            this.state.countryCode = '+1'
        }
        this.state.showPhoneNumber = false;
        this.state.user = this.props.authData;
    }

    componentDidMount() {
        this.checkShowPhoneNumber();
    }

    checkShowPhoneNumber() {
        if(this.inputs && ('SMS' in this.inputs || 'SMS_MFA' in this.inputs)) {
            this.setState({
                showPhoneNumber: true
            })
        }
    }

    triggerTOTPEvent(event, data, user) {
        if (this.props.onTOTPEvent) {
            this.props.onTOTPEvent(event, data, user);
        }
    }

    handleInputChange(evt) {
        SelectMFAType.prototype.handleInputChange.call(this, evt);

        this.setState({
            value: this.inputs
        })

        if('SMS' in this.inputs) {
            this.setState({
                showPhoneNumber: true
            })
        } else {
            this.setState({
                showPhoneNumber: false
            })
        }
    }

    handlePhoneInputChange(evt) {
        const {name, value} = evt.target;

        this.setState({
            phoneNumber: value
        })
    }

    handleCountryCodeChange(evt) {
        const {name, value} = evt.target;

        this.setState({
            countryCode: value
        })
    }

    verify() {
        if(this.inputs && 'SMS' in this.inputs) {
            Auth.currentAuthenticatedUser().then(user => {
                const phoneNumber = this.state.countryCode + this.state.phoneNumber.replace(/\D/g,'');;

                Auth.updateUserAttributes(user, {
                    'phone_number': phoneNumber
                }).then(user => {
                    //console.log(user);
                    this.originalVerify();
                });
            });
        }

        this.originalVerify();
    }

    originalVerify()
    {
        logger.debug('mfatypes inputs', this.inputs);
        if (!this.inputs) {
            logger.debug('No mfa type selected');
            return;
        }
        const { TOTP, SMS, NOMFA } = this.inputs;
        let mfaMethod = null;
        if (TOTP) {
            mfaMethod = 'TOTP';
        } else if (SMS) {
            mfaMethod = 'SMS';
        } else if (NOMFA) {
            mfaMethod = 'NOMFA';
        }

        const user = this.props.authData;



        if (!Auth || typeof Auth.setPreferredMFA !== 'function') {
            throw new Error(
                'No Auth module found, please ensure @aws-amplify/auth is imported'
            );
        }

        //console.log('mj inputs', this.inputs)
        Auth.setPreferredMFA(user, mfaMethod)
            .then(data => {
                logger.debug('set preferred mfa success', data);
                //console.log('mj', mfaMethod)
                //if(mfaMethod === 'TOTP') {
                //    this.setState({
                //        TOTPSetup: true,
                //        selectMessage: 'You need to setup TOTP',
                //        showToast: true,
                //    });
                //} else {
                this.setState({
                    selectMessage: 'Success! Your MFA Type is now: ' + mfaMethod,
                    showToast: true,
                    toastColor: 'green'
                });
                //}
            })
            .catch(err => {
                const { message } = err;
                if (
                    (message === 'User has not set up software token mfa' ||
                        message === 'User has not verified software token mfa')
                ) {
                    if(mfaMethod != 'NOMFA') {
                        this.setState({
                            TOTPSetup: true,
                            selectMessage: 'You need to setup TOTP',
                            showToast: true,
                            toastColor: 'blue'
                        });
                    } else {
                        user.setUserMfaPreference(
                            null,
                            {
                                PreferredMfa: false,
                                Enabled: false,
                            },
                            (err, result) => {
                                this.triggerTOTPEvent('Setup TOTP', 'SUCCESS', user);
                            });
                        //this.triggerTOTPEvent('Setup TOTP', 'SUCCESS', user);
                    }
                } else {
                    logger.debug('set preferred mfa failed', err);
                    this.setState({
                        selectMessage: 'Failed! You cannot select MFA Type for now!',
                        showToast: true,
                        toastColor: 'red'
                    });
                }
            });
    }

    selectView(theme) {
        const { MFATypes } = this.props;
        if (!MFATypes || Object.keys(MFATypes).length < 2) {
            logger.debug('less than 2 mfa types available');
            return (
                <div>
                    <a>less than 2 mfa types available</a>
                </div>
            );
        }
        const { SMS, TOTP, Optional } = MFATypes;
        const showPhoneNumber = this.state.showPhoneNumber;
        const dropdownOptions = [
            {
                key: '+1',
                text: '+1',
                value: '+1',
                content: '+1 United States/Canada',
            },
        ]
        return (
            <React.Fragment>

                    {SMS ? (
                        <React.Fragment>
                            <Form.Field>
                                <div className="ui toggle checkbox">
                                    <input type="radio" key="sms" value="SMS" name="MFAType" checked={this.inputs && ('SMS_MFA' in this.inputs || 'SMS' in this.inputs)} onChange={this.handleInputChange} />
                                        <label>{I18n.get('Text me a unique code at login')}</label>
                                </div>
                            </Form.Field>
                            {showPhoneNumber ? (
                                <Form.Field className="mfa-phone">
                                    <Dropdown
                                        inline
                                        header='Country code'
                                        options={dropdownOptions}
                                        defaultValue={this.state.countryCode}
                                        onChange={this.handleCountryCodeChange}
                                    />
                                    <InputMask className="ui inline" mask="(999) 999-9999" name='phone_number' id='inputPhone' placeholder={I18n.get('Phone Number')} onChange={this.handlePhoneInputChange} value={this.state.phoneNumber} />
                                </Form.Field>
                            ) : null}
                        </React.Fragment>
                    ) : null}
                    {TOTP ? (
                            <Form.Field>
                            <div className="ui toggle checkbox">
                                <input type="radio" key="totp" value="TOTP" name="MFAType" checked={this.inputs && 'TOTP' in this.inputs} onChange={this.handleInputChange} />
                                <label>{I18n.get('TOTP')}</label>
                            </div>
                            </Form.Field>
                    ) : null}
                    {Optional ? (
                            <Form.Field>
                            <div className="ui toggle checkbox">
                                <input type="radio" key="noMFA" value="NOMFA" name="MFAType" checked={this.inputs && 'NOMFA' in this.inputs} onChange={this.handleInputChange} />
                                <label>{I18n.get('Turn off Two-Factor Authentication')}</label>
                            </div>
                            </Form.Field>
                    ) : null}

                    <Form.Field>
                        <CTAButton onClick={this.verify}>
                            {I18n.get('Save')}
                        </CTAButton>
                    </Form.Field>

                {this.state.showToast && this.state.selectMessage && (
                    <Form.Field>
                        <Message color={this.state.toastColor}>
                            {I18n.get(this.state.selectMessage)}
                        </Message>
                    </Form.Field>
                )}
            </React.Fragment>
        );
    }

    render() {
        const theme = '';
        return (
            <div>
                {!this.state.TOTPSetup ? this.selectView(theme) : null}
                {this.state.TOTPSetup ? <CustomTOTPSetupComp {...this.props} /> : null}
            </div>
        );
    }

}

/**
 * @deprecated use named import
 */
export default CustomSelectMFAType;
