import React, { Component } from 'react';
import { Auth } from 'aws-amplify/lib/index';
import { I18n } from 'aws-amplify';
import { Grid, Message } from 'semantic-ui-react'
import { withRouter } from 'react-router-dom';
import CTAButton from '../../widgets/button/CTAButton';
import Input from '../../widgets/Input/Input';
import ValidationUtil from '../../utils/ValidationUtil';
import PageGrid from '../../widgets/grid/PageGrid';
import PanelGridColumn from '../../widgets/grid/PanelGridColumn';
import Forms from '../../widgets/form/Forms';

const queryString = require('query-string');

class ResetPasswordLanding extends Component {
    constructor(props) {
        super(props);
        this.state = {
            username: '',
            code: '',
            password: '',
            confirmPassword: '',
            isFirstTimeValidation: false,
            validationPass: true,
            errors: [],
        };

        this.passwordInput = React.createRef();
        this.confirmPasswordInput = React.createRef();
        this.resetButton = React.createRef();

        this.handleChange = this.handleChange.bind(this);
        this.forgotPasswordSubmit = this.forgotPasswordSubmit.bind(this);
    }

    componentDidMount() {
        const parsed = queryString.parse(this.props.location.search);
        if (parsed.code !== '') {
            this.setState({
                code: parsed.code,
                username: parsed.username,
            });
        } else {
            this.setState({
                status: this.props.inputs.status,
            });
        }
        this.checkValidation();
    }

    checkValidation = async () => {

        if (!this.state.isFirstTimeValidation) {
            return;
        }

        const passwordValidation = await ValidationUtil.validatePassword(this.state.password);
        const confirmPasswordValidationStatus = passwordValidation.status && this.state.password === this.state.confirmPassword;
        const confirmPasswordValidation = {
            status: confirmPasswordValidationStatus,
            msg: (confirmPasswordValidationStatus) ? undefined : I18n.get('createAccountConfirmPasswordError')
        };

        this.passwordInput.current.setError(!passwordValidation.status, passwordValidation.msg);
        this.confirmPasswordInput.current.setError(!confirmPasswordValidation.status, confirmPasswordValidation.msg);

        await this.setState({
            validationPass:
                passwordValidation.status &&
                confirmPasswordValidation.status
        });
    };

    async handleChange(event) {
        event.preventDefault();
        await this.setState({
            [event.target.id]: event.target.value,
        });
        this.checkValidation();
    }

    async forgotPasswordSubmit(event) {
        event.preventDefault();

        await this.setState({ isFirstTimeValidation: true })
        await this.checkValidation();
        if (!this.state.validationPass) {
            return;
        }

        const { username, code, confirmPassword } = this.state;

        // Clean the error message
        this.resetButton.current.setLoading(true);
        this.setState({ errors: [] });

        Auth.forgotPasswordSubmit(username, code, confirmPassword)
            .then(data => {
                this.resetButton.current.setLoading(false);
                this.props.history.push('/signIn');
            })
            .catch(err => {
                this.resetButton.current.setLoading(false);
                this.props.history.push({
                    pathname: '/linkExpired',
                    state: {
                        username: username
                    }
                });
            });
    }

    render() {
        const showErrorMessage = (this.state.errors.length === 0) ? { display: "none" } : { display: "" };
        const isActivateButtonDisabled = !this.state.validationPass || !this.state.password || !this.state.confirmPassword;
        return (
            <PageGrid className="allPage-rootPanel">
                <Grid.Row>
                    <PanelGridColumn>
                        <Forms header={I18n.get('resetPasswordTitle')} onSubmit={this.forgotPasswordSubmit}>

                            <Input ref={this.passwordInput} type='password' id='password'
                                placeholder={I18n.get('createAccountPasswordText')} name={I18n.get('createAccountPasswordText')}
                                onChange={this.handleChange} autoComplete='new-password' />

                            <Input ref={this.confirmPasswordInput}
                                type='password' id='confirmPassword'
                                placeholder={I18n.get('createAccountConfirmPasswordText')}
                                name={I18n.get('createAccountConfirmPasswordText')} onChange={this.handleChange}
                                className='form-label-section' autoComplete='new-password'
                                hintMessage={I18n.get('createAccountConfirmPasswordHintText')}
                            />

                            <CTAButton ref={this.resetButton} submit='true' disabled={isActivateButtonDisabled}>
                                {I18n.get('resetPasswordTitle')}
                            </CTAButton>

                            <Message color='red' style={showErrorMessage}>
                                {this.state.errors}
                            </Message>
                        </Forms>

                    </PanelGridColumn>
                </Grid.Row>
            </PageGrid>
        );
    }
}

export default withRouter(ResetPasswordLanding);
