import SignIn from './components/auth/signIn/SignIn';
import SignUp from './components/auth/signUp/SignUp';
import ResetPassword from './components/auth/resetPassword/ResetPassword';
import ResetPasswordLanding from './components/auth/resetPassword/ResetPasswordLanding';
import CreateAccountConfirmation from './components/auth/signUp/CreateAccountConfirmation';

import ResetPasswordConfirmation from './components/auth/resetPassword/ResetPasswordConfirmation';

import RecoverUsername from './components/auth/recoverUsername/RecoverUsername';
import RecoverUsernameConfirmation from './components/auth/recoverUsername/RecoverUsernameConfirmation';

import MyGeneticsPage from './pages/MyGeneticsPage';
import ProfilePage from './pages/ProfilePage';
import Account from './pages/Account';
import Education from './pages/Education';
import ActivateKit from './pages/ActivateKit';
import Buy from './pages/Buy';
import ChangePassword from './components/auth/changePassword/ChangePassword';
import RawData from './pages/RawData';
import ChangeEmail from './components/auth/changeEmail/ChangeEmail';
import ChangeEmailConfirmation from './components/auth/changeEmail/ChangeEmailConfirmation';
import ChangePasswordConfirmation from './components/auth/changePassword/ChangePasswordConfirmation';
import ChangeMFAStatus from './components/auth/changeMFAStatus/ChangeMFAStatus';
import ConfirmSignIn from './components/auth/confirmSignIn/ConfirmSignIn';
import DeleteAccountVerify from './components/auth/deleteAccount/DeleteAccountVerify';
import DeleteAccountConfirmation from './components/auth/deleteAccount/DeleteAccountConfirmation';
import DeleteAccountVerifyCode from './components/auth/deleteAccount/DeleteAccountVerifyCode';
import ToSPP from './pages/policy/ToSPrivacyPolicy';
import ResearchConsentPolicy from './pages/policy/ResearcgConsentPolicy';
import Biobanking from './pages/policy/Biobanking';
import Sumamry from './pages/policy/Summary';
import ShareResult from './pages/ShareResult';
import ConfigureSharedProfile from './pages/ConfigureSharedProfile';
import UpdateResearchConsent from './pages/policy/UpdateResearchConsent';
import LinkExpired from './components/auth/resetPassword/LinkExpired';
import ChangeEmailResend from './components/auth/changeEmail/ChangeEmailResend';
import Bibliography from './pages/Bibliography';

const routes = [
    {
        path: '/',
        component: MyGeneticsPage,
        exact: true,
        header: true,
        authAccess: true,
        breadcrumbName: 'MyGenetics',
    },
    {
        path: '/myGenetics',
        component: MyGeneticsPage,
        exact: false,
        header: true,
        authAccess: true,
        breadcrumbName: 'MyGenetics',
    },
    {
        path: '/account',
        component: Account,
        exact: false,
        header: true,
        authAccess: true,
        breadcrumbName: 'Account',
    },
    {
        path: '/profilePage/:id',
        component: ProfilePage,
        exact: false,
        header: true,
        authAccess: true,
        breadcrumbName: 'Profile',
    },
    {
        path: '/education',
        component: Education,
        exact: false,
        header: true,
        authAccess: true,
        breadcrumbName: 'education',
    },
    {
        path: '/changePassword',
        component: ChangePassword,
        exact: false,
        header: true,
        authAccess: true,
        breadcrumbName: 'changePassword',
    },
    {
        path: '/rawData',
        component: RawData,
        exact: false,
        header: true,
        authAccess: true,
        breadcrumbName: 'rawData',
    },
    {
        path: '/buy',
        component: Buy,
        exact: false,
        header: true,
        authAccess: true,
        breadcrumbName: 'buy',
    },
    {
        path: '/activate',
        component: ActivateKit,
        exact: false,
        header: true,
        authAccess: true,
        breadcrumbName: 'activateKit',
    },
    {
        path: '/signIn',
        component: SignIn,
        exact: false,
        header: false,
        authAccess: false,
        breadcrumbName: 'signIn',
    },
    {
        path: '/signUp',
        component: SignUp,
        exact: false,
        header: false,
        authAccess: false,
        breadcrumbName: 'signIn',
    },
    {
        path: '/resetPassword',
        component: ResetPassword,
        exact: false,
        header: false,
        authAccess: false,
        breadcrumbName: 'resetPassword',
    },
    {
        path: '/resetPasswordLanding',
        component: ResetPasswordLanding,
        exact: false,
        header: false,
        authAccess: false,
        breadcrumbName: 'resetPasswordLanding',
    },
    {
        path: '/recoverUsername',
        component: RecoverUsername,
        exact: false,
        header: false,
        authAccess: false,
        breadcrumbName: 'recoverUsername',
    },
    {
        path: '/recoverUsernameConfirmation',
        component: RecoverUsernameConfirmation,
        exact: false,
        header: false,
        authAccess: false,
        breadcrumbName: 'RecoverUsernameConfirmation',
    },
    {
        path: '/confirmation',
        component: CreateAccountConfirmation,
        exact: false,
        header: false,
        authAccess: false,
        breadcrumbName: 'confirmation',
    },
    {
        path: '/resetAccountPassword',
        component: ChangePasswordConfirmation,
        exact: false,
        header: true,
        authAccess: true,
        breadcrumbName: 'ChangePasswordConfirmation',
    },
    {
        path: '/changeEmail',
        component: ChangeEmail,
        exact: false,
        header: true,
        authAccess: true,
        breadcrumbName: 'ChangeEmail',
    },
    {
        path: '/changeEmailConfirmation',
        component: ChangeEmailConfirmation,
        exact: false,
        header: true,
        authAccess: true,
        breadcrumbName: 'ChangeEmailConfirmation',
    },
    {
        path: '/changeMFAStatus',
        component: ChangeMFAStatus,
        exact: false,
        header: true,
        authAccess: true,
        breadcrumbName: 'ChangeMFAStatus',
    },
    {
        path: '/confirmSignIn',
        component: ConfirmSignIn,
        exact: false,
        header: false,
        authAccess: false,
        breadcrumbName: 'ChangeMFAStatus',
    },
    {
        path: '/resetPasswordConfirmation',
        component: ResetPasswordConfirmation,
        exact: false,
        header: false,
        authAccess: false,
        breadcrumbName: 'ResetPasswordConfirmation',
    },
    {
        path: '/deleteAccountVerify',
        component: DeleteAccountVerify,
        exact: false,
        header: true,
        authAccess: true,
        breadcrumbName: 'DeleteAccountVerify',
    },
    {
        path: '/deleteAccountConfirmation',
        component: DeleteAccountConfirmation,
        exact: false,
        header: true,
        authAccess: true,
        breadcrumbName: 'DeleteAccountConfirmation',
    },
    {
        path: '/deleteAccountVerifyCode',
        component: DeleteAccountVerifyCode,
        exact: false,
        header: true,
        authAccess: true,
        breadcrumbName: 'deleteAccountVerifyCode',
    },
    {
        path: '/tos-PrivactPolicy',
        component: ToSPP,
        exact: false,
        header: true,
        authAccess: true,
        breadcrumbName: 'termsOfServiceAndPrivactPolicy',
    },
    {
        path: '/researchConsentPolicy',
        component: ResearchConsentPolicy,
        exact: false,
        header: true,
        authAccess: true,
        breadcrumbName: 'researchConsentPolicy',
    },
    {
        path: '/biobanking',
        component: Biobanking,
        exact: false,
        header: true,
        authAccess: true,
        breadcrumbName: 'biobanking',
    },
    {
        path: '/summary',
        component: Sumamry,
        exact: false,
        header: true,
        authAccess: true,
        breadcrumbName: 'sumamry',
    },
    {
        path: '/shareResult',
        component: ShareResult,
        exact: false,
        header: true,
        authAccess: true,
        breadcrumbName: 'shareResult',
    },
    {
        path: '/configureSharedProfile',
        component: ConfigureSharedProfile,
        exact: false,
        header: true,
        authAccess: true,
        breadcrumbName: 'configureSharedProfile',
    },
    {
        path: '/updateResearchConsent',
        component: UpdateResearchConsent,
        exact: false,
        header: true,
        authAccess: true,
        breadcrumbName: 'updateResearchConsent',
    },
    {
        path: '/linkExpired',
        component: LinkExpired,
        exact: false,
        header: false,
        authAccess: false,
        breadcrumbName: 'linkExpired',
    },
    {
        path: '/changeEmailResend',
        component: ChangeEmailResend,
        exact: false,
        header: false,
        authAccess: false,
        breadcrumbName: 'changeEmailResend',
    },
    {
        path: '/bibliography',
        component: Bibliography,
        exact: false,
        header: true,
        authAccess: false,
        breadcrumbName: 'bibliography',
    }
];

export default routes;
