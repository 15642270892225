import React, { Component } from 'react';
import { Auth } from 'aws-amplify/lib/index';
import { I18n } from 'aws-amplify';
import { Grid, Message } from 'semantic-ui-react';
import { withRouter } from 'react-router-dom';
import CTAButton from '../../widgets/button/CTAButton';
import CancelButton from '../../widgets/button/CancleButton';
import Input from '../../widgets/Input/Input';
import PageGrid from '../../widgets/grid/PageGrid';
import PanelGridColumn from '../../widgets/grid/PanelGridColumn';
import Forms from '../../widgets/form/Forms';

const queryString = require('query-string');

class ChangePassword extends Component {
    constructor(props) {
        super(props);
        this.submitButton = React.createRef();
        this.state = {
            confirmPassword: '',
            newPassword: '',
            oldPassword: '',
            validatePassword: false,
            errors: []
        };

        this.handleChange = this.handleChange.bind(this);
        this.changePasswordSubmit = this.changePasswordSubmit.bind(this);
        this.handleGoBack = this.handleGoBack.bind(this);
    }

    componentDidMount() {
        const parsed = queryString.parse(this.props.location.search);
        if (parsed.code !== '') {
            this.setState({
                code: parsed.code,
                username: parsed.username,
            });
        } else {
            this.setState({
                status: this.props.inputs.status,
            });
        }
    }

    async changePasswordSubmit(event) {
        event.preventDefault();

        const { oldPassword, newPassword } = this.state;

        // Clean the error message
        this.submitButton.current.setLoading(true);
        this.setState({ errors: [] });

        Auth.currentAuthenticatedUser()
            .then(user => {
                return Auth.changePassword(user, oldPassword, newPassword);
            })
            .then(data => {
                this.submitButton.current.setLoading(false);
                this.props.history.goBack();
            })
            .catch(err => {
                this.submitButton.current.setLoading(false);
                this.setState({ errors: [err.message] });
            });
    }

    handleChange(event) {
        event.preventDefault();
        this.setState({ [event.target.id]: event.target.value }, () => this.checkPassword());
    }

    checkPassword() {
        const { newPassword, confirmPassword } = this.state;

        if (newPassword === confirmPassword && newPassword.length > 7) {
            this.setState({ validatePassword: true });
        } else {
            this.setState({ validatePassword: false });
        }
    }

    handleGoBack() {
        this.props.history.goBack();
    }

    render() {
        const showErrorMessage = this.state.errors.length === 0 ? { display: 'none' } : { display: '' };
        return (
            <PageGrid className="allPage-rootPanel">
                <Grid.Row>
                    <PanelGridColumn>
                        <Forms header={I18n.get('changePasswordBtn')} onSubmit={this.changePasswordSubmit}>
                            <Input ref={this.passwordInput} type='password' id='oldPassword' name={I18n.get('changeCurrentPassword')}
                                placeholder={I18n.get('changeCurrentPassword')} onChange={this.handleChange} autoComplete='new-password' />

                            <Input ref={this.passwordInput} type='password' id='newPassword' name={I18n.get('changeNewPassword')}
                                placeholder={I18n.get('changeNewPassword')} onChange={this.handleChange} />

                            <Input ref={this.passwordInput} type='password' id='confirmPassword' name={I18n.get('changeConfirmNewPassword')} className='section36'
                                placeholder={I18n.get('changeConfirmNewPassword')} onChange={this.handleChange} />

                            <CancelButton onClick={this.handleGoBack}>
                                {I18n.get('changePasswordCancel')}
                            </CancelButton>

                            <CTAButton ref={this.submitButton} submit='true' margintop='true' disabled={!this.state.validatePassword}>
                                {I18n.get('changePasswordBtn')}
                            </CTAButton>

                            <Message color='red' style={showErrorMessage}>
                                {this.state.errors}
                            </Message>
                        </Forms>
                    </PanelGridColumn>
                </Grid.Row>
            </PageGrid>
        );
    }
}

export default withRouter(ChangePassword);
