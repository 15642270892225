import React, { Component } from 'react';
import { Storage, I18n, Auth } from 'aws-amplify';
import { Grid, Button, Image, Pagination, Loader } from 'semantic-ui-react';
import SearchBar from '../components/widgets/searchBar/SearchBar';
import './RawData.css';
import Table from 'react-smart-data-table';
import ApiUtil from "../components/utils/ApiUtil";
import CourseAdvertising from "../components/widgets/advertising/CourseAdvertising";
import { withRouter } from 'react-router-dom';
import { findDOMNode } from 'react-dom';
import $ from 'jquery';

const rawDataFileName = 'DiagnomicsSample.txt';
const ncbiUrl = 'https://www.ncbi.nlm.nih.gov/snp/';

class RawData extends Component {
    constructor(props) {
        super(props);
        this.state = {
            kitId: '',
            useApi: false,
            apiData: '',
            apiIdx: -1,
            data: [],
            filterValue: '',
            perPage: 0,
            showOnRowClick: true,
            activePage: 1,
            totalPages: 0,
            perPageSize: 0,
            rawDataLoading: '',
            tableLoading: false,
            tableEmpty: false,
            paginationDisabled: false
        };
        this.handleSearchBarChange = this.handleSearchBarChange.bind(this);
    }

    componentDidMount() {
        const self = this;
        ApiUtil.checkAndHandleLogOut().then(function(response) {
            if(response) {
                if (self.props.isShared) {
                    if (self.props.selectedKitProfile.kitId !== undefined && self.props.selectedKitProfile.kitId !== '') {
                        self.fetchData(self.props.selectedKitProfile.kitId, true);
                    }
                } else {
                    if (self.props.selectedKitProfile !== undefined && self.props.selectedKitProfile.currentStatus === 'REPORTS_READY') {
                        self.fetchData(self.props.selectedKitProfile.id, false);
                    }
                }
            } else {
                self.props.history.replace('/signIn');
            }
        });


    }

    componentWillUnmount() {
        window.$('.rsdt-container > table').stickyTableHeaders('destroy');
    }

    componentWillReceiveProps(props) {
        this.checkIfStillLoggedIn();
        this.setState({ activePage: 1, paginationDisabled: false });
        if (props.isShared) {
            if (props.selectedKitProfile.kitId !== undefined && props.selectedKitProfile.kitId !== '') {
                this.setState({ activePage: 1, paginationDisabled: false });
                this.fetchData(props.selectedKitProfile.kitId, true);
            }
        } else {
            if (props.selectedKitProfile !== undefined && props.selectedKitProfile.currentStatus === 'REPORTS_READY') {
                this.setState({ activePage: 1, paginationDisabled: false });
                this.fetchData(props.selectedKitProfile.id, false);
            }
        }
    }

    async fetchData(id, shared) {
        this.checkIfStillLoggedIn();
        if (id === '') {
            return;
        }
        this.setState({
            tableEmpty: false,
            paginationDisabled: true,
            tableLoading: true,
            data: []
        });
        try {
            let result = await ApiUtil.queryRawData(id, shared, this.state.filterValue, this.state.activePage - 1);
            let data = result.data;
            let totalPages = data.totalPages;
            let size = data.size;
            let elements = data.elements;

            this.setState({
                data: elements,
                totalPages: totalPages,
                perPageSize: size
            });

            if (data.elements.length === 0) {
                this.setState({ tableEmpty: true });
            }
            this.setState({ tableLoading: false });
        } catch (e) {
            console.error(e);
            this.setState({ tableEmpty: true, tableLoading: false });
            this.checkAndHandleLogOut(e);
        }
        this.setState({ paginationDisabled: false});
    }

    downloadRawFile = async () => {
        this.checkIfStillLoggedIn();
        this.setState({ rawDataLoading: ' loading' });
        let fileUrl = '';
        if (this.props.isShared) {
            fileUrl = `${this.props.selectedKitProfile.kitId}/${this.props.RawDataPath}`
        } else {
            fileUrl = `${this.props.selectedKitProfile.id}/${this.props.RawDataPath}`
        }
        try {
            const result = await Storage.vault.get(fileUrl, { bucket: this.props.bucket, level: 'private', expires: 60 });
            let a = document.createElement('a');
            a.href = result;
            a.download = rawDataFileName;
            a.click();
        } catch (e) {
            console.error(e);
            if (e instanceof "CredentialsError") {
                sessionStorage.clear();
                Auth.signOut()
                .then(() => {
                    this.props.history.replace('/signIn');
                })
                .catch(error => {
                    console.error(error);
                    if (error.code === 'NotAuthorizedException') {
                        this.props.history.replace('/signIn');
                    }
                });
            } else {
                this.checkAndHandleLogOut(e);
            }
        } finally {
            this.setState({ rawDataLoading: '' });
        }
    };
    
    checkIfStillLoggedIn() {
        if (localStorage.getItem('innerFlag') === null || localStorage.getItem('innerFlag') === false) {
            sessionStorage.clear();
            Auth.signOut()
            .then(() => {
                this.props.history.replace('/signIn');
            })
            .catch(error => {
                console.error(error);
                if (error.code === 'NotAuthorizedException') {
                    this.props.history.replace('/signIn');
                }
            });
        } 
    }

    getHeaders() {
        return {
            id: {
                text: 'RSID',
                invisible: true,
                filterable: false,
            },
            rsid: {
                text: 'RSID',
                invisible: false,
                filterable: false,
                transform: value => (<p>{value}</p>),
                //transform: value => <a
                //    target='_blank'
                //    rel='noopener noreferrer'
                //    href={ncbiUrl + value}>
                //    {value}
                //</a> 
                //,
            },
            chromosome: {
                text: 'Chromosome',
                invisible: false,
                filterable: false,
                transform: value => (<p>{value}</p>),
            },
            position: {
                text: 'Position',
                sortable: false,
                filterable: false,
                transform: value => (<p>{value}</p>),
            },
            genotype: {
                text: 'Genotype',
                sortable: false,
                filterable: false,
                transform: value => (<p>{value}</p>),
            }
        };
    }

    handleSearchBarChange = async (value) => {
        this.checkIfStillLoggedIn();
        await this.setState({ filterValue: value, data: [], activePage: 1, paginationDisabled: false });
        if (this.props.isShared) {
            if (this.props.selectedKitProfile.kitId !== undefined && this.props.selectedKitProfile.kitId !== '') {
                this.fetchData(this.props.selectedKitProfile.kitId, true);
            }
        } else {
            if (this.props.selectedKitProfile !== undefined && this.props.selectedKitProfile.currentStatus === 'REPORTS_READY') {
                this.fetchData(this.props.selectedKitProfile.id, false);
            }
        }
    }

    handlePaginationChange = async (e, { activePage }) => {
        this.checkIfStillLoggedIn();
        await this.setState({ activePage });
        if (this.props.isShared) {
            if (this.props.selectedKitProfile.kitId !== undefined && this.props.selectedKitProfile.kitId !== '') {
                this.fetchData(this.props.selectedKitProfile.kitId, this.props.isShared);
            }
        } else {
            if (this.props.selectedKitProfile !== undefined && this.props.selectedKitProfile.currentStatus === 'REPORTS_READY') {
                this.fetchData(this.props.selectedKitProfile.id, this.props.isShared);
            }
        }
    }

    checkAndHandleLogOut = (error) => {
        var logout = false
        if (error.data != null) {
            console.error("Error status : " + error.data.status);
            if (error.data.status === 401) {
                logout = true;
            }
        } else if (error.status != null) {
            console.error("Error status : " + error.status);
            if (error.status === 401) {
                logout = true;
            }
        }
        
        if (logout) {
            Auth.signOut()
            .then(() => {
                this.props.history.replace('/signIn');
            })
            .catch(error => {
                console.error(error);
                if (error.code === 'NotAuthorizedException') {
                    this.props.history.replace('/signIn');
                }
            });
        }
    }

    render() {
        const { data, filterValue, totalPages, perPageSize } = this.state;
        const headers = this.getHeaders();
        window.$('.rsdt-container > table').on('enabledStickiness.stickyTableHeaders', function(event){
            window.$('.pushable').addClass('transform-fix');
        }).on('disabledStickiness.stickyTableHeaders', function(event){
            window.$('.pushable').removeClass('transform-fix');
        });
        window.$('.rsdt-container > table').stickyTableHeaders({scrollableArea: $('.pushable, .pageGrid')[0]});
        const CustomComponent = () => {

            return (<Pagination
                disabled={this.state.PaginationDisabled}
                activePage={this.state.activePage}
                onPageChange={this.handlePaginationChange}
                totalPages={this.state.totalPages}
            />)
        }
        return (
            <Grid className='profilePage-tab-contentPanel'>
                <Grid.Row columns={1}>
                    <Grid.Column className='padding0' textAlign='center'>
                        {this.props.selectedKitProfile.currentStatus === 'REPORTS_READY' || this.props.RawDataPath !== '' ? (
                            <Grid>
                                <Grid.Row textAlign='right'>
                                    <Grid.Column textAlign='right'>
                                        <Button onClick={this.downloadRawFile}
                                            className={this.state.rawDataLoading + ' float'}
                                            type='button'>
                                            {I18n.get('rawDataPageDownloadBtn')}
                                        </Button>
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row className='section18'>
                                    <Grid.Column >
                                        <SearchBar placeholder={I18n.get('rawDataPageSearchPlacehold')}
                                            onKeyUp={this.handleSearchBarChange}
                                            onSearch={this.handleSearchBarChange}
                                            value={filterValue} />
                                        {
                                            this.state.tableLoading &&
                                            <Loader style={{ marginTop: 32 }} active />
                                        }
                                        <Table
                                            paginator={CustomComponent}
                                            headers={headers}
                                            perPage={perPageSize}
                                            totalPages={totalPages}
                                            data={data}
                                            className='ui compact selectable table'
                                            withLinks
                                            withHeader
                                            dynamic
                                            emptyTable={
                                                this.state.tableEmpty &&
                                                <div className='ui message'>{I18n.get('tableNoData')}</div>
                                            }
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        )
                            :
                            (
                                <div>
                                    <div className='tabPageTitleLabel section36'>{I18n.get('NoReportTitle')}</div>
                                    <Image className='reportPage-imagePanel section18' src='../../images/KitProcessDiagram.png' />
                                </div>
                            )}
                            <CourseAdvertising />
                    </Grid.Column>

                </Grid.Row>
            </Grid>
        );
    }
}

export default withRouter(RawData);
