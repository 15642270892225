import React from 'react';
import PropTypes from 'prop-types';
import { Dropdown } from 'semantic-ui-react';
import './Dropdown.css';

class Dropdowns extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoadding: false,
            isError: false,
            errorMessage: ""
        }
    }

    setLoadding = async (isLoadding) => {
        await this.setState({isLoadding: isLoadding})
    }

    setError = async (isError, errorMessage) => {
        await this.setState({ isError: isError, errorMessage: errorMessage });
    }

    render() {
        const className = (this.props.className) ? "dropdown  " + this.props.className : "dropdown";
        const dropdownProps = {...this.props, loading: this.state.isLoadding, disabled: this.state.isLoadding, error: this.state.isError};
        const errorMessage = (this.state.errorMessage) ? (this.state.errorMessage) : "";
        return (
            <div className='field form-label-group'>
                <Dropdown {...dropdownProps} className={className} />
                <div className="bodyLabel" error='true'>{errorMessage}</div>
            </div>
        )
    }
}

Dropdowns.prototypes = {
    className: PropTypes.string
};

export default Dropdowns;