import React from 'react';
import PropTypes from 'prop-types';
import './Menu.css';
import { NavLink as Link, withRouter } from 'react-router-dom';

class MenuExternalItem extends React.Component {
    render() {
        const className = (this.props.className)? "menuItem item" + this.props.className: "menuItem item";
        const target = (this.props.href) ? "_blank" : "";
        return (
            <a className={className} style={this.props.style} href={this.props.href} target={target} onClick={this.props.onClick}>
                {this.props.text}
            </a>
        );
    }
}

MenuExternalItem.prototypes = {
    className: PropTypes.string,
    href: PropTypes.string,
    onClick: PropTypes.func,
    style: PropTypes.string,
    text: PropTypes.string
};

export default withRouter(MenuExternalItem);