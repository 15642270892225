import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from 'semantic-ui-react';
import './Grid.css';

class GridRow extends React.Component {
    render() {
        const className = (this.props.className) ? "center aligned gridRow " + this.props.className : "center aligned gridRow";
        return (
            <Grid.Row {...this.props} className={className}>
                {this.props.children}
            </Grid.Row>
        )
    }
}

GridRow.prototypes = {
    className: PropTypes.string
};

export default GridRow;