import React from 'react';
import PropTypes from 'prop-types';
import './Input.css';

class Input extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isError: false,
            errorMessage: ""
        }
    }

    setError = async (isError, errorMessage, errorWidget) => {
        await this.setState({ isError: isError, errorMessage: errorMessage, errorWidget: errorWidget });
    }

    renderErrorMessage = () => {
        if (this.state.errorWidget !== undefined) {
            return this.state.errorWidget

        } else {
            return this.state.errorMessage
        }
    }

    render() {
        const autoComplete = (this.props.autoComplete) ? this.props.autoComplete : "off";
        const autoCapitalize = (this.props.autoCapitalize) ? this.props.autoCapitalize : "";
        const className = (this.props.className) ? "form-label-group " + this.props.className : "form-label-group";
        const inputClassName = this.state.isError ? "form-control" : "form-control";
        const isError = this.state.isError ? {error: ""} : "";

        return (
            <div className={className}>
                <div>
                    <input {...isError}
                        name={this.props.name}
                        type={this.props.type}
                        id={this.props.id}
                        className={inputClassName}
                        placeholder={this.props.placeholder}
                        required
                        autoComplete={autoComplete}
                        autoCapitalize={autoCapitalize}
                        onChange={this.props.onChange}
                        value={this.props.value}/>
                    <label htmlFor={this.props.id}>{this.props.name}</label>
                </div>
                <div className="bodyLabel" error='true'>{this.renderErrorMessage()}</div>
                <div className="bodyLabel" hint='true'>{this.props.hintMessage}</div>
            </div>
        );
    }
}

Input.prototypes = {
    autoComplete: PropTypes.string,
    autoCapitalize: PropTypes.string,
    className: PropTypes.string,
    hintMessage: PropTypes.string,
    name: PropTypes.string,
    id: PropTypes.string,
    inputClassName: PropTypes.string,
    onChange: PropTypes.func,
    placeholder: PropTypes.string,
    type: PropTypes.string,
};

export default Input;