import React, { Component } from 'react';
import { Auth } from 'aws-amplify/lib/index';
import { I18n } from 'aws-amplify';
import { Grid, Button } from 'semantic-ui-react';
import { NavLink as Link, withRouter } from 'react-router-dom';
import PageGrid from '../../widgets/grid/PageGrid';
import PanelGridColumn from '../../widgets/grid/PanelGridColumn';
import Forms from '../../widgets/form/Forms';

class ResetPasswordConfirmation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            username: '',
            resendLoading: ''
        };
        this.handleResendForgotPassword = this.handleResendForgotPassword.bind(this);
    }

    async handleResendForgotPassword() {
        try {
            if (this.state.username != null) {
                this.setState({ 'resendLoading': 'loading' });
                await Auth.forgotPassword(this.state.username);
                this.setState({ 'resendLoading': '' });
            }
        } catch (e) {
            this.setState({ 'resendLoading': '' });
            console.debug(e);
        }
    }

    componentDidMount() {
        if (this.props.inputs !== null) {
            this.setState({ email: this.props.inputs.email, username: this.props.inputs.username });
        }
    }

    render() {
        return (
            <PageGrid className="allPage-rootPanel">
                <Grid.Row>
                    <PanelGridColumn>
                        <Forms className='section18' header={I18n.get('resetPasswordTitle')}>

                            <div className='bodyLabel section12'>{I18n.get('createAccountConfirmationSentText')}</div>
                            <div className='bodyLabel section12'>{I18n.get('forgotPasswordConfirmationExpireText')}</div>

                            <div className='bodyLabel section12'>
                                {I18n.get('resetPasswordConfirmationMsg1Text')}
                                <Button onClick={this.handleResendForgotPassword} className={this.state.resendLoading + ' linkButton'}>
                                    {I18n.get('createAccountConfirmationMsg2Text')}
                                </Button>
                            </div>

                            <div className='bodyLabel'>{I18n.get('createAccountConfirmationMsg3Text')}</div>
                            <div className='textAlignLeft'>
                                <a className='blueLink textAlignLeft' href='mailto:support@strategene.me'>
                                    {I18n.get('createAccountConfirmationSupport')}
                                </a>
                            </div>

                        </Forms>

                        <Link className='blueLink' to='/signIn'>{I18n.get('createAccountConfirmationSignIn')}</Link>
                    </PanelGridColumn>
                </Grid.Row>
            </PageGrid>
        );
    }
}

export default withRouter(ResetPasswordConfirmation);
