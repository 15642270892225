import React, { Component } from 'react';
import RawData from './RawData';
import Report from './Report';
import { Grid, Tab } from 'semantic-ui-react';
import { I18n, Auth } from 'aws-amplify';
import Profile from './Profile';
import PageGrid from '../components/widgets/grid/PageGrid';
import SharePage from './SharePage';
import ApiUtil from "../components/utils/ApiUtil";

import '../pages/Page.css';
import './ProfilePage.css'
import Moment from "moment";


class ProfilePage extends Component {

    state = {
        selectedKitProfile: {},
        selectedKit: {},
        profileList: [],
        activeIndex: 0,
        reportShared: false,
        rawDataShared: false,
        height: 0,
        RawDataPath: '',
        ReprotPath: '',
        bucket: ''
    };

    constructor(props) {
        super(props);
        this.buildRawFilePath = this.buildRawFilePath.bind(this);
        this.buildReportFilePath = this.buildReportFilePath.bind(this);
        this.fetchFileInfo = this.fetchFileInfo.bind(this);
    }

    async componentDidMount() {
        const self = this;
        ApiUtil.checkAndHandleLogOut().then(async function(response) {
            if(response) {

                await self.setState({ ...self.props.location.state, activeIndex: 0 });

                let id = window.atob(self.props.match.params.id);
                let kit = {};
                try {
                    if (self.state.isShared) {
                        kit = await ApiUtil.getSharedKitByKitId(id);
                        if (self.state.selectedKitProfile.rawDataShared) {
                            self.buildRawFilePath(self.state.selectedKitProfile.rawFileLocation);
                        }
                        if (self.state.selectedKitProfile.reportShared) {
                            self.buildReportFilePath(self.state.selectedKitProfile.reportFileLocation);
                        }
                    } else {
                        kit = await ApiUtil.getKitByKitId(id);
                        await self.fetchFileInfo(id);
                        let profile = await ApiUtil.queryProfiles(kit.userProfileId);
                        let profileList = await ApiUtil.getKitAndProfileJointList();
                        let selected = { ...profile, ...kit }
                        await self.setState({ selectedKitProfile: selected, profileList: profileList })
                    }
                } catch (error) {
                    self.checkAndHandleLogOut(error);
                }

            } else {
                self.props.history.replace('/signIn');
            }
        });
    }

    async fetchFileInfo(id) {
        try {
            let result = await ApiUtil.queryFileInfo(id);
            if (result.data['raw-file'] === undefined) {
                return;
            }
            this.buildRawFilePath(result.data['raw-file']);
            this.buildReportFilePath(result.data['report-file']); 
        } catch(error) {
            this.checkAndHandleLogOut(error);
        }
    }

    async buildRawFilePath(rawPath) {
        const rawFilsStrAry = rawPath.split('/');
        rawFilsStrAry.forEach((res) => {
            if (res.indexOf('user-file-pdf') !== -1) {
                const bucket = res.replace('.s3.us-west-2.amazonaws.com', '');
                this.setState({ bucket: bucket });
                return true;
            }
            if (res.indexOf('.zip') !== -1) {
                this.setState({ RawDataPath: res });
                return true;
            }
        });
    }

    async buildReportFilePath(reportPath) {
        const reportFilsStrAry = reportPath.split('/');

        reportFilsStrAry.forEach((res) => {
            if (res.indexOf('user-file-pdf') !== -1) {
                const bucket = res.replace('.s3.us-west-2.amazonaws.com', '');
                this.setState({ bucket: bucket });
                return true;
            }
            if (res.indexOf('.pdf') !== -1) {
                this.setState({ ReprotPath: res });
                return true;
            }
        });
    }

    handleTabChange = (e, { activeIndex }) => this.setState({ activeIndex });

    handleProfileChange = async (selectedKitProfile) => {
        await this.setState({
            selectedKitProfile: {},
            selectedKit: {},
            profileList: [],
            activeIndex: 0,
            reportShared: false,
            rawDataShared: false,
            height: 0,
            RawDataPath: '',
            ReprotPath: '',
            bucket: '' });
        try {
            this.checkIfStillLoggedIn();
            let kit = await ApiUtil.getKitByKitId(selectedKitProfile.id);
            await this.fetchFileInfo(selectedKitProfile.id);
            let profile = await ApiUtil.queryProfiles(kit.userProfileId);
            let profileList = await ApiUtil.getKitAndProfileJointList();
            let selected = { ...profile, ...kit }
            await this.setState({ selectedKitProfile: selected, profileList: profileList })
        } catch (error) {
            this.checkAndHandleLogOut(error);
        }
    }
    
    checkIfStillLoggedIn() {
        if (localStorage.getItem('innerFlag') === null || localStorage.getItem('innerFlag') === false) {
            Auth.signOut()
            .then(() => {
                this.props.history.replace('/signIn');
            })
            .catch(error => {
                console.error(error);
                if (error.code === 'NotAuthorizedException') {
                    this.props.history.replace('/signIn');
                }
            });
        } 
    }
    
    checkAndHandleLogOut = (error) => {
        var logout = false
        if (error.data != null) {
            console.error("Error status : " + error.data.status);
            if (error.data.status === 401) {
                logout = true;
            }
        } else if (error.status != null) {
            console.error("Error status : " + error.status);
            if (error.status === 401) {
                logout = true;
            }
        }
        
        if (logout) {
            Auth.signOut()
            .then(() => {
                this.props.history.replace('/signIn');
            })
            .catch(error => {
                console.error(error);
                if (error.code === 'NotAuthorizedException') {
                    this.props.history.replace('/signIn');
                }
            });
        }
    }

    renderTab = () => {
        var tabs = [];

        if (!this.state.isShared || this.state.reportShared) {
            tabs.push({
                menuItem: I18n.get('geneticTabReports'),
                render: () => (
                    <Tab.Pane attached={false}>{<Report kitId={this.state.selectedKitProfile.kitId} isShared={this.state.isShared} bucket={this.state.bucket} ReprotPath={this.state.ReprotPath} selectedKitProfile={this.state.selectedKitProfile} profileStatus={this.state.selectedKitProfile.currentStatus}/>}</Tab.Pane>
                )
            });
        }

        if (!this.state.isShared || this.state.rawDataShared) {
            tabs.push({
                menuItem: I18n.get('geneticTabRawData'),
                render: () => (
                    <Tab.Pane attached={true}>{<RawData isShared={this.state.isShared} bucket={this.state.bucket} RawDataPath={this.state.RawDataPath} selectedKitProfile={this.state.selectedKitProfile} />}</Tab.Pane>
                )
            });
        }

        if (!this.state.isShared) {
            tabs.push({
                menuItem: I18n.get('geneticTabShare'),
                render: () => (
                    <Tab.Pane attached={true}>{<SharePage kitStatus={this.state.selectedKitProfile.currentStatus} kitId={this.state.selectedKitProfile.id} profileName={`${this.state.selectedKitProfile.firstName} ${this.state.selectedKitProfile.lastName}`} />}</Tab.Pane>
                )
            });
        }

        return tabs;
    }

    render() {
        const { activeIndex } = this.state;
        return (
            <PageGrid className='here'>
                <Grid.Row className='gridRow'>
                    <Grid.Column className='profilePage-pageColumn'>
                        <Profile isShared={this.state.isShared} profileList={this.state.profileList}
                            selectedKitProfile={this.state.selectedKitProfile} onChange={this.handleProfileChange} />
                        <Tab activeIndex={activeIndex} className='custom-tab-style' menu={{ secondary: true, pointing: true }}
                            panes={this.renderTab()} onTabChange={this.handleTabChange}
                        />
                    </Grid.Column>
                </Grid.Row>
            </PageGrid>
        );
    }
}

export default ProfilePage;
