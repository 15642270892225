import React from 'react';
import PropTypes from 'prop-types';
import './Menu.css';

class MenuButton extends React.Component {
    render() {
        const className = (this.props.className) ? " menu-button " + this.props.className : " menu-button ";
        const target = (this.props.href) ? "_blank" : "";
        return (
            <a className={className} style={this.props.style} href={this.props.href} target={target} onClick={this.props.onClick}>
                {this.props.text}
            </a>
        );
    }
}

MenuButton.prototypes = {
    className: PropTypes.string,
    href: PropTypes.string,
    onClick: PropTypes.func,
    style: PropTypes.string,
    text: PropTypes.string
};

export default MenuButton;