import React from 'react';
import PropTypes from 'prop-types';
import { Form, Grid, Radio, Checkbox } from 'semantic-ui-react'
import { I18n, Auth } from 'aws-amplify';
import CTAButton from '../components/widgets/button/CTAButton';
import Forms from '../components/widgets/form/Forms';
import PageGrid from '../components/widgets/grid/PageGrid';
import PanelGridColumn from '../components/widgets/grid/PanelGridColumn';
import ApiUtil from '../components/utils/ApiUtil';
import Moment from "moment";

class ConfigureSharedProfile extends React.Component {
    constructor(props) {
        super(props);
        this.removeButton = React.createRef();
        this.shareButton = React.createRef();

        this.state = {
            sgReportAggrement: true,
            sgReportAndRawDataAggrement: false,
            sharingPolicy: false,
            rawDataAggrement: false,
            validationPass: false
        }
    }

    componentDidMount() {
        const self = this;
        ApiUtil.checkAndHandleLogOut().then(function(response) {
            if(response) {
                self.setState(self.props.location.state);
                self.setState({
                    sgReportAggrement: !self.props.location.state.kit.rawDataShared,
                    sgReportAndRawDataAggrement: self.props.location.state.kit.rawDataShared
                })
                self.checkValidation();
            } else {
                self.props.history.replace('/signIn');
            }
        });

    }

    handlesgReportAggrementChange = async (event) => {
        await this.setState(prevState => ({
            sgReportAggrement: !prevState.sgReportAggrement,
            sgReportAndRawDataAggrement: !prevState.sgReportAndRawDataAggrement,
            rawDataAggrement: (!prevState.sgReportAndRawDataAggrement) ? prevState.rawDataAggrement : !prevState.sgReportAndRawDataAggrement
        }));
        this.checkValidation();
    }

    handlesgReportAndRawDataAggrementChange = async () => {
        await this.setState(prevState => ({
            sgReportAggrement: !prevState.sgReportAggrement,
            sgReportAndRawDataAggrement: !prevState.sgReportAndRawDataAggrement,
            rawDataAggrement: (!prevState.sgReportAndRawDataAggrement) ? prevState.rawDataAggrement : !prevState.sgReportAndRawDataAggrement
        }));
        this.checkValidation();
    };

    handleSharingPolicyChange = async () => {
        await this.setState(prevState => ({ sharingPolicy: !prevState.sharingPolicy }));
        this.checkValidation();
    };

    handleRawDataAggrementChange = async () => {
        await this.setState(prevState => ({ rawDataAggrement: !prevState.rawDataAggrement }));
        this.checkValidation();
    };

    handleRemoveButtonClick = async () => {
        this.removeButton.current.setLoading(true);
        try {
            await ApiUtil.deleteSharedKit(this.state.kit.id);
            this.removeButton.current.setLoading(false);
            this.props.history.goBack();

        } catch (error) {
            this.removeButton.current.setLoading(false);
            this.checkAndHandleLogOut(error);
        }
    }
    
    checkAndHandleLogOut = (error) => {
        var logout = false
        if (error.data != null) {
            console.error("Error status : " + error.data.status);
            if (error.data.status === 401) {
                logout = true;
            }
        } else if (error.status != null) {
            console.error("Error status : " + error.status);
            if (error.status === 401) {
                logout = true;
            }
        }
        
        if (logout) {
            Auth.signOut()
            .then(() => {
                this.props.history.replace('/signIn');
            })
            .catch(error => {
                console.error(error);
                if (error.code === 'NotAuthorizedException') {
                    this.props.history.replace('/signIn');
                }
            });
        }
    }

    handleShareButtonClick = async () => {
        this.shareButton.current.setLoading(true);

        const body = {
            userName: this.state.kit.userName,
            kitId: this.state.kit.kitId,
            reportShared: true,
            rawDataShared: this.state.sgReportAndRawDataAggrement
        }

        try {
            var xmlHttp = new XMLHttpRequest();
            var shareRaw = "false";
            if (this.state.sgReportAndRawDataAggrement) {
                shareRaw = "true";
            }
            xmlHttp.open( "GET", 'https://clia-approval.strategene-dashboard.montanab.com/send-share/'+this.state.kit.kitId+'/'+this.state.kit.userName+'/'+shareRaw, false); // false for synchronous request
            xmlHttp.send( null );

            await ApiUtil.shareKit(body);
            this.shareButton.current.setLoading(false);
            this.props.history.goBack();

        } catch (error) {
            console.error(error);
            this.shareButton.current.setLoading(false);
            this.checkAndHandleLogOut(error);
        }
    }

    checkValidation = () => {

        this.setState({
            validationPass:
                (this.state.sgReportAggrement || this.state.sgReportAndRawDataAggrement)
                && (this.state.sgReportAndRawDataAggrement === this.state.rawDataAggrement)
                && this.state.sharingPolicy
        });
    }

    render() {
        const accountName = this.state.kit ? `${this.state.kit.firstName} ${this.state.kit.lastName}` : '';

        return(
            <PageGrid className="allPage-rootPanel">
                <Grid.Row>
                    <PanelGridColumn>
                        <Forms header={I18n.get('configureSharingTitle')} onSubmit={this.handleAcceptButtonClick}>
                            <div className="bodyTitleLabel">{I18n.get('configureSharingSharingWithTitle')}</div>
                            <div className="bodyLabel section24"> {accountName} </div>

                            <div className="bodyTitleLabel">{I18n.get('configureSharingSharingResultFor')}</div>
                            <div className="bodyLabel section24"> {this.state.profileName} </div>

                            <div className="bodyTitleLabel">{I18n.get('configureSharingWhatYouShare')}</div>
                            <Grid textAlign='left' verticalAlign='top'>
                                <Grid.Row>
                                    <Grid.Column>
                                        <Form.Field>
                                            <Radio radio label={<label dangerouslySetInnerHTML={{__html: I18n.get('configureSharingSGReport')}}></label>} checked={this.state.sgReportAggrement} onClick={this.handlesgReportAggrementChange} />
                                        </Form.Field>

                                        <Form.Field>
                                            <Radio radio label={<label dangerouslySetInnerHTML={{__html: I18n.get('configureSharingSGReportAndRawData')}}></label>} checked={this.state.sgReportAndRawDataAggrement} onClick={this.handlesgReportAndRawDataAggrementChange} />
                                        </Form.Field>
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                            <div className="bodyLabel section24" hint='true'>{I18n.get('shareResultQ3Des')}</div>

                            <Grid textAlign='left' verticalAlign='top' className='section16'>
                                <Grid.Row>
                                    <Grid.Column>
                                        <Checkbox onChange={this.handleSharingPolicyChange}
                                            checked={this.state.privacyPolicy} id="sharingPolicy"
                                            label={<label>{I18n.get('configureSharingAggrement')}
                                                <a rel="noopener noreferrer" target='_blank' className='blueLink' href={ApiUtil.getSharingPolicyUrl()}>{I18n.get('configureSharingSharingPolicy')}</a>
                                            </label>}>
                                        </Checkbox>

                                        <Checkbox onChange={this.handleRawDataAggrementChange}
                                            checked={this.state.rawDataAggrement} disabled={!this.state.sgReportAndRawDataAggrement} id="rawDataAggrement"
                                            label={<label>{I18n.get('configureSharingRawDataAggrement')}</label>}>
                                        </Checkbox>
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>

                            <CTAButton ref={this.removeButton} submit='true' warning='true' onClick={this.handleRemoveButtonClick}>{I18n.get('configureSharingRemoveSharing')}
                            </CTAButton>

                            <CTAButton ref={this.shareButton} submit='true' margintop='true' onClick={this.handleShareButtonClick}
                                disabled={!this.state.validationPass}>{I18n.get('configureSharingUpdateSharing')}
                            </CTAButton>

                        </Forms>
                    </PanelGridColumn>
                </Grid.Row>
            </PageGrid>

        )
    }
}

ConfigureSharedProfile.prototypes = {
    kit: PropTypes.object,
    onClick: PropTypes.func
};

export default ConfigureSharedProfile
