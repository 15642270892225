import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Image } from 'semantic-ui-react'
import { I18n } from 'aws-amplify';

import './ShareAccountCard.css';
import '../../layout/layout.css';

class ShareAccountCard extends React.Component {
    render() {
        const accountName = `${this.props.kitInfo.firstName} ${this.props.kitInfo.lastName}`
        const strateGeneReportStatusPath = this.props.kitInfo.reportShared ? '../images/icon-selected.svg' : '../images/icon-unselected.svg';
        const rawDataStatusPath = this.props.kitInfo.rawDataShared ? '../images/icon-selected.svg' : '../images/icon-unselected.svg';
        return (
            <Grid className="shareAccountCard">
                <Grid.Row columns={2} >
                    <Grid.Column width={9}>
                        <div className='bodyTitleLabel'>{accountName}</div>
                        <div className='fit-content'>
                            <Image className='card-icon marginRight4' src={strateGeneReportStatusPath}/>
                            <div className='bodyLabel card-statusLabel' dangerouslySetInnerHTML={{__html: I18n.get('shareAccountCardStrateGeneReport')}}></div>
                        </div>
                        <div className='fit-content'>
                            <Image className='card-icon marginRight4' src={rawDataStatusPath}/>
                            <div className='bodyLabel card-statusLabel'>{I18n.get('shareAccountCardRawData')}</div>
                        </div>
                    </Grid.Column>

                    <Grid.Column width={2} floated='right'>
                        <Image className='card-icon icon-clickable' src={'../images/icon-configure.svg'} onClick={() => this.props.onClick(this.props.kitInfo)}/>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        );
    }
}

ShareAccountCard.prototypes = {
    kitInfo: PropTypes.object,
    onClick: PropTypes.func
};

export default ShareAccountCard;