import React, { Component } from "react";
import ReactDOM from 'react-dom';
import { Auth } from "aws-amplify/lib/index";
import { I18n } from 'aws-amplify';
import {Form, Grid, Checkbox, Message, Image} from 'semantic-ui-react'
import { withRouter } from "react-router-dom";
import Moment from 'moment';
import ValidationUtil from "../../utils/ValidationUtil";
import Input from "../../widgets/Input/Input";
import CTAButton from '../../widgets/button/CTAButton';
import DatePicker from "../../widgets/datePicker/DataPicker";
import Dropdowns from '../../widgets/dropdown/Dropdowns';
import PageGrid from '../../widgets/grid/PageGrid';
import PanelGridColumn from '../../widgets/grid/PanelGridColumn';
import Forms from '../../widgets/form/Forms';
import ApiUtil from '../../../components/utils/ApiUtil';
import './SignUp.css';

class SignUp extends Component {

    constructor(props, context) {
        super(props, context);
        this.userNameInput = React.createRef();
        this.emailInput = React.createRef();
        this.passwordInput = React.createRef();
        this.confirmPasswordInput = React.createRef();
        this.firstNameInput = React.createRef();
        this.lastNameInput = React.createRef();
        this.datePicker = React.createRef();
        this.submitButton = React.createRef();
        this.state = {
            username: "",
            email: "",
            password: "",
            confirmPassword: "",
            firstName: "",
            lastName: "",
            gender: "",
            privacyPolicy: false,
            hubspotOptIn: false,
            user: null,
            status: "SignUp",
            warning: "",
            errors: [],
            date: '',
            datePickerMaxDate: '',
            isFirstTimeValidation: false,
            validationPass: true,
            birthSexDropdownOptions: [
                { key: I18n.get('ActivateKitBirthSexFemale'), text: I18n.get('ActivateKitBirthSexFemale'), value: I18n.get('ActivateKitBirthSexFemale') },
                { key: I18n.get('ActivateKitBirthSexMale'), text: I18n.get('ActivateKitBirthSexMale'), value: I18n.get('ActivateKitBirthSexMale') }
            ],
            title: I18n.get('crateAccountTitle')
        };

        this.handleSignUp = this.handleSignUp.bind(this);
        this.handleFormInput = this.handleFormInput.bind(this);
        this.handlePrivacyPolicyChange = this.handlePrivacyPolicyChange.bind(this);
        this.container = React.createRef();
        this.signIn = this.signIn.bind(this)
    }

    componentWillUnmount() {
        this.setState = (state, callback) => {
            return;
        };
    }

    componentDidMount() {
        // Set the maximum date for picker
        var today = Moment(new Date(), 'MMMM DD, YYYY');
        this.setState(this.props.location.state);
        this.setState({ datePickerMaxDate: today });
        if (this.props.location.state) {
            if (this.props.location.state.status === 'activate')
            this.setState({ 'title': I18n.get('ActivateKitTitle')});
        }
    }


    checkValidation = async () => {

        // Don't validate any field until user click submit='true' button at the first time.
        if (!this.state.isFirstTimeValidation) {
            return;
        }

        const emailValidation = await ValidationUtil.validateEmail(this.state.email);
        const usernameValidation = await ValidationUtil.validateUsername(this.state.username);
        const passwordValidation = await ValidationUtil.validatePassword(this.state.password);
        const firstNameValidation = await ValidationUtil.validateFirstName(this.state.firstName);
        const lastNameValidation = await ValidationUtil.validateLastName(this.state.lastName);
        const confirmPasswordValidationStatus = passwordValidation.status && this.state.password === this.state.confirmPassword;
        const confirmPasswordValidation = {
            status: confirmPasswordValidationStatus,
            msg: (confirmPasswordValidationStatus) ? undefined : I18n.get('createAccountConfirmPasswordError')
        };

        var dateOfBirthValidation = { status: false, msg: I18n.get('errorMessageRequiredField') };
        if (this.state.date) {
            var date = Moment(this.state.date, 'MMMM DD, YYYY');

            if (!date.isValid()) {
                dateOfBirthValidation = { status: false, msg: I18n.get('errorMessageRequiredField') };

            } else if (ValidationUtil.validate18YearsOld(date)) {
                dateOfBirthValidation = { status: false, msg: I18n.get('createAccountDateOfBirthError') };

            } else {
                dateOfBirthValidation = { status: true, msg: undefined };
            }
        }

        this.userNameInput.current.setError(!usernameValidation.status, usernameValidation.msg);
        this.emailInput.current.setError(!emailValidation.status, emailValidation.msg);
        this.passwordInput.current.setError(!passwordValidation.status, passwordValidation.msg);
        this.confirmPasswordInput.current.setError(!confirmPasswordValidation.status, confirmPasswordValidation.msg);
        this.firstNameInput.current.setError(!firstNameValidation.status, firstNameValidation.msg);
        this.lastNameInput.current.setError(!lastNameValidation.status, lastNameValidation.msg);
        this.datePicker.current.setError(!dateOfBirthValidation.status, dateOfBirthValidation.msg);

        await this.setState({
            validationPass: emailValidation.status
                && usernameValidation.status
                && passwordValidation.status
                && confirmPasswordValidation.status
                && firstNameValidation.status
                && lastNameValidation.status
                && dateOfBirthValidation.status
                && this.state.gender.length > 0
        });
    };

    async handleSignUp(event) {

        event.preventDefault();

        await this.setState({ isFirstTimeValidation: true })
        await this.checkValidation();
        if (!this.state.validationPass) {
            let formHandler = ReactDOM.findDOMNode(this.container.current);
            formHandler.scrollTop = 0;
            return;
        }

        const { username, email, password, firstName, lastName, date, gender, hubspotOptIn } = this.state;
        const dateOfBirth = Moment(date, 'MMMM DD, YYYY').format('MM-DD-YYYY')

        // Clean the error message
        this.submitButton.current.setLoading(true)
        this.setState({ errors: [] });
        try {
            if (this.state.status === 'activate') {
                await Auth.signUp({
                    username: username,
                    password: password,
                    attributes: {
                        name: username,
                        email: email,
                        given_name: firstName,
                        family_name: lastName,
                        birthdate: dateOfBirth,
                        gender: gender,
                        'custom:custom_attribute1': "{\"status\": \"activate\"}",
                        'custom:custom_attribute2': hubspotOptIn.toString()
                    },
                    validationData: [],
                });
            } else {
                await Auth.signUp({
                    username: username,
                    password: password,
                    attributes: {
                        name: username,
                        email: email,
                        given_name: firstName,
                        family_name: lastName,
                        birthdate: dateOfBirth,
                        gender: gender,
                        'custom:custom_attribute2': hubspotOptIn.toString()
                    },
                    validationData: [],
                });
            }

            this.submitButton.current.setLoading(false)

            this.props.setEmail(email);
            this.props.switchComponent('Verify', username);
            this.props.history.push("/confirmation");
        } catch (err) {
            if (err.message === undefined) {
                this.setState({ errors: [err] });
            } else {
                if (err.message === 'User already exists') {
                    this.userNameInput.current.setError(true, I18n.get("usernameInUseError"));
                } else {
                    this.userNameInput.current.setError(true, I18n.get("createAccountUserNameError"));
                }
            }
            this.submitButton.current.setLoading(false)
            let formHandler = ReactDOM.findDOMNode(this.container.current);
            formHandler.scrollTop = 0;
        }
    };

    handleFormInput = async (event) => {
        event.preventDefault();
        await this.setState({
            [event.target.id]: event.target.value
        });
        this.checkValidation();
    };

    handlePrivacyPolicyChange = () => {
        this.setState(prevState => ({ privacyPolicy: !prevState.privacyPolicy }));
    };

    handleHubspotOptInChange = () => {
        this.setState(prevState => ({ hubspotOptIn: !prevState.hubspotOptIn }));
    };


    handleDatePickerChange = (event, name, value) => {
        if (this.state.hasOwnProperty(name)) {
            this.setState({ [name]: value });
        }

        this.checkValidation();
    };

    handleBirthSexDropdownOnChange = (event, data) => {
        this.setState({ gender: data.value });
        this.checkValidation();
    }

    signIn() {
        if (this.state.status === 'activate') {
            this.props.history.replace({
                pathname: '/signIn',
                state: {
                    status: 'activate'
                }
            });
        } else {
            this.props.history.replace({
                pathname: '/signIn'
            });
        }

    }

    render() {
        const showErrorMessage = (this.state.errors.length === 0) ? { display: "none" } : { display: "" };
        const isActivateButtonDisabled = !this.state.validationPass || !this.state.username || !this.state.email
            || !this.state.password || !this.state.confirmPassword || !this.state.firstName
            || !this.state.lastName || !this.state.date || this.state.gender.length <= 0 || !this.state.privacyPolicy
        return (
            <PageGrid ref={this.container}>
                <Grid.Row>
                    <PanelGridColumn>
                        <Image className='signin-logo' src='../../images/StrateGene-logo.svg' />
                        <Forms className='section18' header={this.state.title} onSubmit={this.handleSignUp}>
                            {
                                this.state.status === 'activate' &&
                                <p className='bodyLabel'>{I18n.get('signUpDesc')}</p>
                            }
                            <Input ref={this.userNameInput} type='text'
                                id='username' placeholder={I18n.get('createAccountUserName')}
                                name={I18n.get('createAccountUserName')} onChange={this.handleFormInput} />

                            <Input ref={this.emailInput} type='email' id='email'
                                placeholder={I18n.get('createAccountEmail')} name={I18n.get('createAccountEmail')} onChange={this.handleFormInput} />

                            <Input ref={this.passwordInput} type='password'
                                id='password' placeholder={I18n.get('createAccountPasswordText')}
                                name={I18n.get('createAccountPasswordText')} onChange={this.handleFormInput} autoComplete='new-password' />

                            <Input ref={this.confirmPasswordInput} type='password' id='confirmPassword'
                                placeholder={I18n.get('createAccountConfirmPasswordText')} name={I18n.get('createAccountConfirmPasswordText')}
                                onChange={this.handleFormInput} className='section24' hintMessage={I18n.get('createAccountConfirmPasswordHintText')} />

                            <Input ref={this.firstNameInput} type='text' id='firstName' placeholder={I18n.get('createAccountFirstNameText')}
                                name={I18n.get('createAccountFirstNameText')} onChange={this.handleFormInput} />

                            <Input ref={this.lastNameInput} type='text' id='lastName' placeholder={I18n.get('createAccountLastNameText')}
                                name={I18n.get('createAccountLastNameText')} onChange={this.handleFormInput} />

                            <DatePicker ref={this.datePicker} placeholder={I18n.get('createAccountDateOfBirth')}
                                maxDate={this.state.datePickerMaxDate} onChange={this.handleDatePickerChange} />

                            <Form.Field className="form-label-group">
                                <Dropdowns ref={this.birthSexDropdown} selection
                                    options={this.state.birthSexDropdownOptions}
                                    placeholder={I18n.get('ActivateKitBirthSex')}
                                    pointing='top right'
                                    onChange={this.handleBirthSexDropdownOnChange}
                                    value={this.state.gender} />
                            </Form.Field>

                            <Grid className='marginTop0 section36' textAlign='left' verticalAlign='top'>
                                <Grid.Row className='gridRow'>
                                    <Grid.Column>
                                        <Checkbox onChange={this.handlePrivacyPolicyChange} checked={this.state.privacyPolicy}
                                            id='privacyPolicy'
                                            label={
                                                <label>
                                                    {I18n.get('createAccountAgreement1Text')}
                                                    <a rel="noopener noreferrer" className='blueLink' target='_blank' href={ApiUtil.getTosUrl()}>{I18n.get('createAccountTermsText')}</a>
                                                    {I18n.get('createAccountAgreement2Text')}
                                                    <a rel="noopener noreferrer" className='blueLink' target='_blank' href={ApiUtil.getPrivacyPolicyUrl()}>
                                                        {I18n.get('createAccountPrivacyPolicyText')}
                                                    </a>
                                                </label>
                                            }
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>

                            <Grid className='marginTop0 section36' textAlign='left' verticalAlign='top'>
                                <Grid.Row className='gridRow'>
                                    <Grid.Column>
                                        <Checkbox onChange={this.handleHubspotOptInChange} checked={this.state.hubspotOptIn}
                                                  id='LEGAL_CONSENT.subscription_type_4033685-d79b8357-7c71-47b4-ba89-24078514ade0'
                                                  value='true'
                                                  name='LEGAL_CONSENT.subscription_type_4033685'
                                                  label={
                                                      <label>
                                                          {I18n.get('createAccountNewsletterOptIn')}
                                                      </label>
                                                  }
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>

                            <CTAButton ref={this.submitButton} submit='true' onClick={this.handleSignUp} disabled={isActivateButtonDisabled}>
                                {I18n.get('createAccountButtonText')}
                            </CTAButton>

                            <Message color='red' style={showErrorMessage}>
                                {this.state.errors}
                            </Message>
                        </Forms>

                        <div className='bodyLabel textAlignCenter'>
                            {I18n.get('createAccountHasAccountText')}
                            <button onClick={this.signIn} className='blueLink'>{I18n.get('createAccountSignInText')}</button>
                        </div>

                    </PanelGridColumn>
                </Grid.Row>
            </PageGrid>
        );
    }
}

export default withRouter(SignUp);
