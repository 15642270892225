import React from 'react';
import PropTypes from 'prop-types';
import { Tab } from 'semantic-ui-react';
import './Tab.css';
import MenuButton from "../menu/MenuButton";
import {I18n} from "aws-amplify";
import ApiUtil from "../../utils/ApiUtil";

class Tabs extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoadding: false,
            isError: false,
            errorMessage: ""
        }
    }

    setLoadding = async (isLoadding) => {
        await this.setState({isLoadding: isLoadding})
    }

    setError = async (isError, errorMessage) => {
        await this.setState({ isError: isError, errorMessage: errorMessage });
    }

    render() {
        const kitProfileListDropdownOptions = [
            { key: I18n.get('profileCardSimpleMyProfiles'), text: I18n.get('profileCardSimpleMyProfiles'), value: I18n.get('profileCardSimpleMyProfiles') },
            { key: I18n.get('profileCardSimpleSharedProfiles'), text: I18n.get('profileCardSimpleSharedProfiles'), value: I18n.get('profileCardSimpleSharedProfiles') }
        ]
        const profileDropdownSubDescription = this.props.parent.state.isShared ? I18n.get('myGeneticsProfileDropdownShared') : I18n.get('myGeneticsProfileDropdown')
        const className = (this.props.className) ? "tab  " + this.props.className : "tab";
        //const tabProps = {...this.props, loading: this.state.isLoadding, disabled: this.state.isLoadding, error: this.state.isError};
        const panes = [
            {
                menuItem: kitProfileListDropdownOptions[0].value,
                render: () =>
                    <Tab.Pane loading={this.state.isLoadding}>
                        <div className='bodyLabel section24' hint='true'>{profileDropdownSubDescription}</div>
                        {this.props.parent.renderProfileCard()}

                        {
                            this.props.parent.state.kitProfileList.length === 0 && !this.props.parent.state.isShared &&
                            <div>
                                <MenuButton
                                    text={I18n.get('HomeMenuBuy')}
                                    style={{ marginRight: '16px' }}
                                    href={ApiUtil.getBuyKitUrl()}
                                />
                                <MenuButton
                                    className='menu-ctaButton'
                                    text={I18n.get('HomeMenuActivate')}
                                    onClick={this.props.parent.activateKitButtonOnClick.bind(this)}
                                />
                            </div>
                        }
                    </Tab.Pane>

            },
            {
                menuItem: kitProfileListDropdownOptions[1].value,
                render: () =>
                    <Tab.Pane loading={this.state.isLoadding}>
                        <div className='bodyLabel section24' hint='true'>{profileDropdownSubDescription}</div>
                        {this.props.parent.renderProfileCard()}

                        {
                            this.props.parent.state.kitProfileList.length === 0 && !this.props.parent.state.isShared &&
                            <div>
                                <MenuButton
                                    text={I18n.get('HomeMenuBuy')}
                                    style={{ marginRight: '16px' }}
                                    href={ApiUtil.getBuyKitUrl()}
                                />
                                <MenuButton
                                    className='menu-ctaButton'
                                    text={I18n.get('HomeMenuActivate')}
                                    onClick={this.props.parent.activateKitButtonOnClick.bind(this)}
                                />
                            </div>
                        }
                    </Tab.Pane>

            },
        ];
        const tabProps = {...this.props, panes: panes };
        const errorMessage = (this.state.errorMessage) ? (this.state.errorMessage) : "";
        return (
            <div className='field form-label-group'>
                <Tab {...tabProps} className={className} />
                <div className="bodyLabel" error='true'>{errorMessage}</div>
            </div>
        )
    }
}

Tabs.prototypes = {
    className: PropTypes.string
};

export default Tabs;