import React from 'react';
import PropTypes from 'prop-types';
import {Grid} from 'semantic-ui-react';

const Buy = ({ mobile }) => (
    <Grid>
        <Grid.Column>
            <Grid.Row style={{ height: 150, background: 'lightgray' }} />
        </Grid.Column>
    </Grid>
);

Buy.propTypes = {
    mobile: PropTypes.bool,
};

export default Buy;
