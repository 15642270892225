import React from 'react';
import './Menu.css';
import {Dropdown, Image, Responsive} from 'semantic-ui-react';
import MenuButton from './MenuButton';
import { Auth, I18n } from 'aws-amplify';
import { withRouter } from 'react-router-dom';
import ApiUtil from "../../utils/ApiUtil";
import {Hub} from "@aws-amplify/core";

class Menu extends React.Component {

    constructor(props, context) {
        super(props, context);
        this.state = {
            value: '/',
            userDropdownList: [
                I18n.get('HomeMenuUserMyAccount'),
                I18n.get('HomeMenuUserBibliography'),
                I18n.get('HomeMenuUserTermsOfService'),
                I18n.get('HomeMenuUserPrivacyPolicy'),
                I18n.get('HomeMenuUserLogout'),
            ]
        };
        this.handleOnChange = this.handleOnChange.bind(this);
        this.handleGoHome = this.handleGoHome.bind(this);
    }

    handleOnChange(event, data) {
        switch (data.value) {
            case 0:
                this.props.history.push('/account');
                break;
            case 1:
                this.props.history.push('/bibliography');
                break;
            case 2:
                window.open(ApiUtil.getTosUrl(), '_blank')
                break;
            case 3:
                window.open(ApiUtil.getPrivacyPolicyUrl(), '_blank')
                break;
            case 4:
                Auth.signOut()
                    .then(() => {
                        localStorage.clear();
                        sessionStorage.clear();
                        Hub.dispatch( 'userLoggedOut', {});
                        this.props.history.replace('/signIn');
                    })
                    .catch(error => {
                        localStorage.clear();
                        sessionStorage.clear();
                        console.error(error);
                        Hub.dispatch( 'userLoggedOut', {});
                        if (error.code === 'NotAuthorizedException') {
                            this.props.history.replace('/signIn');
                        }
                    });
                break;
            default:
                break;
        }
    }

    activateKitButtonOnClick = () => {
        this.props.history.push('/activate');
    };

    renderUserDropdown = () => {
        return (
            this.state.userDropdownList.map((dropdownOption, index) => {
                return <Dropdown.Item key={index} onClick={this.handleOnChange} value={index}>{dropdownOption}</Dropdown.Item>
            })
        )
    }

    handleGoHome() {
        this.props.history.push('/myGenetics');
    }

    render() {
        const trigger = <div><Image className='menu-userIcon icon-clickable' src={'../images/icon-account.svg'} onClick={this.props.onClick}/></div>;
        return (
            <div className='menu-basePanel' >
                <Responsive minWidth="1024">
                    <Image className='menu-logo' src='../../images/StrateGene-logo.svg' onClick={this.handleGoHome} />
                    <div className='menu-panel'>
                        <Responsive minWidth='1024'>
                            <div className='ui secondary pointing menu menu-itemPanel'>{this.props.children}</div>
                        </Responsive>
                        <div className='menu-buttonPanel'>
                            <MenuButton
                                text={I18n.get('HomeMenuBuy')}
                                style={{ marginRight: '16px' }}
                                href={ApiUtil.getBuyKitUrl()}
                            />
                            {this.state.value && <MenuButton
                                className='menu-ctaButton'
                                text={I18n.get('HomeMenuActivate')}
                                onClick={this.activateKitButtonOnClick.bind(this)}
                            />}

                        </div>
                    </div>
                    {
                        this.state.value &&
                        <Dropdown onChange={this.handleOnChange} direction='left' trigger={trigger} icon={null}>
                            <Dropdown.Menu>
                                {this.renderUserDropdown()}
                            </Dropdown.Menu>
                        </Dropdown>
                    }
                </Responsive>
                <Responsive maxWidth="1023">
                    <div className='menu-panel'>
                        {
                            this.props.children &&
                            <div className='ui secondary pointing menu menu-itemPanel'>{this.props.children}</div>
                        }
                        <div className='menu-buttonPanel'>
                            <MenuButton
                                text={I18n.get('HomeMenuBuy')}
                                style={{ marginRight: '16px' }}
                                href={ApiUtil.getBuyKitUrl()}
                            />
                            {this.state.value && <MenuButton
                                className='menu-ctaButton'
                                text={I18n.get('HomeMenuActivate')}
                                onClick={this.activateKitButtonOnClick.bind(this)}
                            />}

                        </div>
                        {
                            this.state.value &&
                            <div>
                                {this.renderUserDropdown()}
                            </div>
                        }
                    </div>
                </Responsive>
            </div>
        );
    }
}

export default withRouter(Menu);
