import React, { Component } from 'react';
import { Auth } from 'aws-amplify/lib/index';
import { I18n } from 'aws-amplify';
import { Grid, Button } from 'semantic-ui-react';
import { NavLink as Link, withRouter } from 'react-router-dom';

import PageGrid from '../../widgets/grid/PageGrid';
import PanelGridColumn from '../../widgets/grid/PanelGridColumn';
import Forms from '../../widgets/form/Forms';


class ChangeEmailResend extends Component {

    constructor(props) {
        super(props);
        this.state = {
            username: '',
            resendLoading: ''
        }
        this.handleResendEmail = this.handleResendEmail.bind(this);
    }

    handleResendEmail(event) {
        event.preventDefault();
        try {
            this.setState({ 'resendLoading': 'loading' });
            Auth.verifyCurrentUserAttribute('email')
                .then(() => {
                    this.setState({ 'resendLoading': '' });
                }).catch((e) => {
                    this.setState({ 'resendLoading': '' });
                    console.error('failed with error', e);
                });
        } catch (e) {
            this.setState({ 'resendLoading': '' });
            console.debug(e);
        }
    }

    render() {
        return (
            <PageGrid className="allPage-rootPanel">
                <Grid.Row>
                    <PanelGridColumn>
                        <Forms className='section18' header={I18n.get('createAccountConfirmationTitle')}>
                            <div className='bodyLabel section12'>{I18n.get('createAccountConfirmationSignInError')}</div>

                            <div className='bodyLabel section12'>
                                {I18n.get('createAccountConfirmationMsg1Text')}
                                <Button onClick={this.handleResendEmail} className={this.state.resendLoading + ' linkButton'}>
                                    {I18n.get('createAccountConfirmationMsg2Text')}
                                </Button>
                            </div>

                            <div className='bodyLabel'>{I18n.get('createAccountConfirmationMsg3Text')}</div>
                            <a className='blueLink textAlignLeft' href='mailto:support@strategene.me'>
                                {I18n.get('createAccountConfirmationSupport')}
                            </a>
                        </Forms>
                        <Link className='blueLink' to='/signIn'>{I18n.get('createAccountConfirmationSignIn')}</Link>
                    </PanelGridColumn>
                </Grid.Row>
            </PageGrid>
        );
    }
}

export default withRouter(ChangeEmailResend);
