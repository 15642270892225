import React, { Component } from 'react';
import { Auth } from 'aws-amplify/lib/index';
import { I18n } from 'aws-amplify';
import { Grid, Button, Loader } from 'semantic-ui-react';
import { NavLink as Link, withRouter } from 'react-router-dom';
import Amplify from 'aws-amplify';
import PageGrid from '../../widgets/grid/PageGrid';
import PanelGridColumn from '../../widgets/grid/PanelGridColumn';
import Forms from '../../widgets/form/Forms';
import CustomMessage from '../../widgets/message/CustomMessage';
import HubspotForm from 'react-hubspot-form';

const queryString = require('query-string');

class CreateAccountConfirmation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            username: '',
            code: '',
            status: '',
            verifyCodeLoader: false,
            loading: '',
            CustomMessageHide: true
        };

        this.handleResendEmail = this.handleResendEmail.bind(this);
        this.handleResendForgotPassword = this.handleResendForgotPassword.bind(this);
    }

    componentDidMount() {
        const parsed = queryString.parse(this.props.location.search);
        if (parsed.code !== undefined && parsed.code !== '') {
            this.setState({ status: 'confirmSignUp', verifyCodeLoader: true });
            this.fetchUser(parsed.username, parsed.code);
        } else {
            this.setState({ status: this.props.inputs.status });
        }

        if (this.props.inputs !== null) {
            this.setState({ email: this.props.inputs.email, username: this.props.inputs.username });
        } else {
            Auth.currentAuthenticatedUser()
                .then(user => {
                    if (user != null) {
                        this.setState({ email: user.attributes.email });
                    }
                })
                .catch(err => {
                    this.setState({ user: null });
                });
        }
    }

    async confirmSignUp(username, code) {
        // After retrieving the confirmation code from the user
        Auth.confirmSignUp(username, code, {
            // Optional. Force user confirmation irrespective of existing alias. By default set to True.
            forceAliasCreation: true,
        })
            .then(data => {
                this.setState({ verifyCodeLoader: false });
                this.props.history.push('/signIn');
            })
            .catch(err => {
                console.error(err);
                this.setState({
                    status: 'Expired', verifyCodeLoader: false
                });
            });
    }

    fetchUser(username, code) {
        var path = '';

        if (process.env.REACT_APP_ENV === 'production' || process.env.REACT_APP_ENV === 'staging') {
            path = window.location.origin + '/aws-exports.json';
        } else if (process.env.REACT_APP_ENV === 'development') {
            path = window.location.origin + '/aws-exports-dev.json';
        }

        fetch(path)
            .then(response => {
                return process.env.REACT_APP_ENV === 'development' ? response.json() : response.text();
            })
            .then(
                result => {
                    try {
                        let decodeResult = window.atob(result);
                        if (process.env.REACT_APP_ENV === 'staging') {
                            console.debug(decodeResult);
                        }
                        Amplify.configure(decodeResult);
                        this.confirmSignUp(username, code);

                    } catch (e) {
                        Amplify.configure(result);
                        this.confirmSignUp(username, code);
                    }
                },
                error => {
                    this.setState({ verifyCodeLoader: false });
                    console.error(error);
                },
            );
    }

    handleResendEmail(event) {
        event.preventDefault();
        if (this.state.username !== '') {
            this.setState({ loading: 'loading'});
            Auth.resendSignUp(this.state.username)
                .then(() => {
                    console.debug('code resent successfully');
                    this.setState({ loading: '', CustomMessageHide: false});
                })
                .catch(e => {
                    console.error(e);
                    this.setState({ loading: '' });
                });
        }
    }

    async handleResendForgotPassword() {
        if (this.state.email != null) {
            try {
                this.setState({ loading: 'loading' });
                await Auth.forgotPassword(this.state.email);
            }catch(e) {
                console.error(e);
            }
            this.setState({ loading: '', CustomMessageHide: false });
        }
    }

    handleHubspotOptIn = () => {
        let optinData = queryString.parse(this.props.location.search);
        if(optinData.hubspot_optin) {
            optinData = Buffer.from(optinData.hubspot_optin, 'base64').toString();
            if(optinData) {
                optinData = optinData.split('|');
                if (process.env.REACT_APP_ENV === 'staging') {
                    console.log('hubspot data', optinData);
                }
                if(optinData.length === 3) {
                    if(optinData[0] === 'true') {
                        document.getElementById("email-d79b8357-7c71-47b4-ba89-24078514ade0").value = optinData[1];
                        document.getElementById("firstname-d79b8357-7c71-47b4-ba89-24078514ade0").value = optinData[2];
                        document.getElementById("LEGAL_CONSENT.subscription_type_4033685-d79b8357-7c71-47b4-ba89-24078514ade0").checked = true;
                        document.getElementById("hsForm_d79b8357-7c71-47b4-ba89-24078514ade0").submit();
                    }
                }
            }
        }
    };

    renderHeader = (state) => {
        var header = '';
        var p1 = null;
        var p2 = null
        var p3 = null

        const p1ResetPassword = <div>
            <div className='bodyLabel'>{I18n.get('createAccountConfirmationSentText')}</div>
            <div className='form-emailLabel textAlignLeft section12'>{this.state.email}</div>
        </div>
        const p1Confirm = <div className='bodyLabel section12'>{I18n.get('createAccountConfirmationSignInError')}</div>
        const p1Expired = <div className='bodyLabel section12'>
            {I18n.get('LinkExpiredDescription1')}
            <Link className='blueLink' to='/signIn'>{I18n.get('createAccountConfirmationSignIn')}</Link>
            {I18n.get('LinkExpiredDescription2')}
        </div>

        const p2Confirm = <div className='bodyLabel section12'>{I18n.get('signUpConfirmationExpireText')}</div>

        const p3ResetPassword = <div className='bodyLabel section12'>
            {I18n.get('resetPasswordConfirmationMsg1Text')}
            <Button onClick={this.handleResendForgotPassword} className={this.state.loading + ' linkButton'}>
                {I18n.get('createAccountConfirmationMsg2Text')}
            </Button>
        </div>

        const p3Confirm = <div className='bodyLabel section12'>
            {I18n.get('createAccountConfirmationMsg1Text')}
            <Button onClick={this.handleResendEmail} className={this.state.loading + ' linkButton'}>
                {I18n.get('createAccountConfirmationMsg2Text')}
            </Button>
        </div>

        switch (state) {
            case '':
                header = I18n.get('resetPasswordTitle')
                p1 = p1ResetPassword
                p3 = p3ResetPassword
                break;

            case 'Verify':
                header = I18n.get('createAccountConfirmationTitle')
                p1 = p1ResetPassword
                p2 = p2Confirm
                p3 = p3Confirm
                break;

            case 'confirmSignUp':
                header = I18n.get('createAccountConfirmationTitle')
                p1 = p1Confirm
                p2 = p2Confirm
                p3 = p3Confirm
                break;

            case 'ForgotPasswordSubmit':
                header = I18n.get('resetPasswordTitle')
                p1 = p1ResetPassword
                p3 = p3ResetPassword
                break;

            case 'Expired':
                header = I18n.get('LinkExpiredTitle')
                p1 = p1Expired
                break;

            default:
                break;
        }

        return { header, p1, p2, p3 }
    }

    render() {
        const { header, p1, p2, p3 } = this.renderHeader(this.state.status);

        return (
            <PageGrid className="allPage-rootPanel">
                {
                    this.state.verifyCodeLoader &&
                    <Loader style={{ marginTop: 50 }} active />
                }
                <Grid.Row>
                    <PanelGridColumn>
                        <Forms className='section18' header={header}>
                            {p1}

                            {p2}

                            {p3}

                            <div className='bodyLabel'>{I18n.get('createAccountConfirmationMsg3Text')}</div>
                            <a className='blueLink textAlignLeft' href='mailto:support@strategene.me'>
                                {I18n.get('createAccountConfirmationSupport')}
                            </a>
                            <CustomMessage hide={this.state.CustomMessageHide} text={I18n.get('resendEmailText')} color='#50B948'/>
                        </Forms>
                        <Link className='blueLink' to='/signIn'>{I18n.get('createAccountConfirmationSignIn')}</Link>
                        <div style={{ display: "none" }}><HubspotForm
                            portalId='2823166'
                            formId='d79b8357-7c71-47b4-ba89-24078514ade0'
                            onSubmit={() => ''}
                            onReady={(form) => this.handleHubspotOptIn()}
                            loading={<div>Loading...</div>}
                        /></div>
                    </PanelGridColumn>
                </Grid.Row>
            </PageGrid>
        );
    }
}

export default withRouter(CreateAccountConfirmation);
