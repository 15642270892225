import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from 'semantic-ui-react';
import './Grid.css';

class PageGrid extends React.Component {
    render() {
        const className = (this.props.className) ? "pageGrid " + this.props.className : "pageGrid";
        return (
            <Grid {...this.props} className={className} textAlign='center' verticalAlign='middle'>
                {this.props.children}
            </Grid>
        )
    }
}

PageGrid.prototypes = {
    className: PropTypes.string
};

export default PageGrid;