import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

class NoMatch extends Component {

    render() {
        return (
            <div style={{
                position: 'absolute',
                top: '20%',
                left: '50%',
                fontSize: '-webkit-xxx-large'}}>404</div>
        )
    }
}

export default withRouter(NoMatch);
