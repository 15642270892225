import React from 'react';
import './Policy.css';
import '../../components/widgets/checkbox/Checkbox.css';
import '../../components/widgets/label/Label.css';
import { Divider, Form, Grid, Checkbox, Radio } from 'semantic-ui-react'
import { I18n } from 'aws-amplify';
import { Auth, JS } from 'aws-amplify/lib/index';
import Moment from 'moment';
import CancelButton from '../../components/widgets/button/CancleButton';
import CTAButton from '../../components/widgets/button/CTAButton';
import Forms from '../../components/widgets/form/Forms';
import PageGrid from '../../components/widgets/grid/PageGrid';
import PanelGridColumn from '../../components/widgets/grid/PanelGridColumn';
import ApiUtil from "../../components/utils/ApiUtil";
import ValidationUtil from "../../components/utils/ValidationUtil";

class UpdateResearchConsent extends React.Component {
    constructor(props) {
        super(props)
        this.notConsentButton = React.createRef();
        this.consentButton= React.createRef();
        this.state = {
            loading: false,
            privacyPolicy: false,
            ageAgreement: false,
            authAgremment: false,
            validationPass: false,
            is18YearsRadioButtonDisabled: true,
            kit: {},
        }
    }

    componentDidMount() {
                const dateOfBirthMoment = this.props.location.state.kitProfile ? Moment(this.props.location.state.kitProfile.birthDate, "MM-DD-YYYY") : undefined;

                this.setState({...this.props.location.state,
                    ageAgreement: this.props.location.state.kitProfile.researchIsMythis,
                    authAgremment: this.props.location.state.kitProfile.researchIsLegallyAuthorized,
                    kit: {
                        id: this.props.location.state.kitProfile.id,
                        tubeId: this.props.location.state.kitProfile.tubeId,
                        userProfileId: this.props.location.state.kitProfile.userProfileId,
                        tosConsent: this.props.location.state.kitProfile.tosConsent,
                        tosIsMythis: this.props.location.state.kitProfile.tosIsMythis,
                        tosIsLegallyAuthorized: this.props.location.state.kitProfile.tosIsLegallyAuthorized,
                        tosTimestamp: this.props.location.state.kitProfile.tosTimestamp,
                        researchConsent: this.props.location.state.kitProfile.researchConsent,
                        researchIsMythis: this.props.location.state.kitProfile.researchIsMythis,
                        researchIsLegallyAuthorized: this.props.location.state.kitProfile.researchIsLegallyAuthorized,
                        researchConsentTimestamp: this.props.location.state.kitProfile.researchConsentTimestamp,
                        storeSample: this.props.location.state.kitProfile.storeSample,
                        storeIsMythis: this.props.location.state.kitProfile.storeIsMythis,
                        storeIsLegallyAuthorized: this.props.location.state.kitProfile.storeIsLegallyAuthorized,
                        biobankingTimestamp: this.props.location.state.kitProfile.biobankingTimestamp
                    },
                    is18YearsRadioButtonDisabled: (dateOfBirthMoment !== undefined) ? ValidationUtil.validate18YearsOld(dateOfBirthMoment) : true
                });
        /*
        const self = this;
        ApiUtil.checkAndHandleLogOut().then(function(response) {
            if(response) {
                const dateOfBirthMoment = self.props.location.state.kitProfile ? Moment(self.props.location.state.kitProfile.birthDate, "MM-DD-YYYY") : undefined;

                self.setState({...self.props.location.state,
                    ageAgreement: self.props.location.state.kitProfile.researchIsMyself,
                    authAgremment: self.props.location.state.kitProfile.researchIsLegallyAuthorized,
                    kit: {
                        id: self.props.location.state.kitProfile.id,
                        tubeId: self.props.location.state.kitProfile.tubeId,
                        userProfileId: self.props.location.state.kitProfile.userProfileId,
                        tosConsent: self.props.location.state.kitProfile.tosConsent,
                        tosIsMyself: self.props.location.state.kitProfile.tosIsMyself,
                        tosIsLegallyAuthorized: self.props.location.state.kitProfile.tosIsLegallyAuthorized,
                        tosTimestamp: self.props.location.state.kitProfile.tosTimestamp,
                        researchConsent: self.props.location.state.kitProfile.researchConsent,
                        researchIsMyself: self.props.location.state.kitProfile.researchIsMyself,
                        researchIsLegallyAuthorized: self.props.location.state.kitProfile.researchIsLegallyAuthorized,
                        researchConsentTimestamp: self.props.location.state.kitProfile.researchConsentTimestamp,
                        storeSample: self.props.location.state.kitProfile.storeSample,
                        storeIsMyself: self.props.location.state.kitProfile.storeIsMyself,
                        storeIsLegallyAuthorized: self.props.location.state.kitProfile.storeIsLegallyAuthorized,
                        biobankingTimestamp: self.props.location.state.kitProfile.biobankingTimestamp
                    },
                    is18YearsRadioButtonDisabled: (dateOfBirthMoment !== undefined) ? ValidationUtil.validate18YearsOld(dateOfBirthMoment) : true
                });

            } else {
                self.props.history.replace('/signIn');
            }
        });
        */
    }

    handlePrivacyPolicyChange = async () => {
        await this.setState(prevState => ({
            privacyPolicy: !prevState.privacyPolicy
        }));
        this.checkValidation();
    };

    handleAgeAgreementChange = async () => {
        console.log(this.state.is18YearsRadioButtonDisabled);
        await this.setState(prevState => ({
            ageAgreement: this.state.is18YearsRadioButtonDisabled ? !prevState.ageAgreement : true,
            authAgremment: !prevState.authAgremment
        }));
        this.checkValidation();
    };

    handleAuthAgreementChange = async () => {
        await this.setState(prevState => ({
            ageAgreement: this.state.is18YearsRadioButtonDisabled ? !prevState.ageAgreement : false,
            authAgremment: !prevState.authAgremment
        }));
        this.checkValidation();
    };

    handleAcceptButtonClick = async () => {
        this.consentButton.current.setLoading(true)
        await this.setState({
            kit : {
                ...this.state.kit,
                researchConsent: true,
                researchIsMyself: this.state.ageAgreement,
                researchIsLegallyAuthorized: this.state.authAgremment,
                researchConsentTimestamp: Moment(new Date()).format('MM-DD-YYYY'),
            }
        })

        await this.updateKit(this.state.kit);
        this.consentButton.current.setLoading(false)
    }

    handleNotConsentButtonClick = async () => {
        this.notConsentButton.current.setLoading(true)
        await this.setState({
            kit : {
                ...this.state.kit,
                researchConsent: false,
                researchIsMyself: this.state.ageAgreement,
                researchIsLegallyAuthorized: this.state.authAgremment,
                researchConsentTimestamp: Moment(new Date()).format('MM-DD-YYYY'),
            }
        })

        await this.updateKit(this.state.kit);
        this.notConsentButton.current.setLoading(false)
    }

    updateKit = async (body) => {
        try {
            await ApiUtil.updateKit(body);
            this.props.history.goBack();

        } catch (error) {
            this.checkAndHandleLogOut(error);
        }
    }
    
    checkAndHandleLogOut = (error) => {
        var logout = false
        if (error.data != null) {
            console.error("Error status : " + error.data.status);
            if (error.data.status === 401) {
                logout = true;
            }
        } else if (error.status != null) {
            console.error("Error status : " + error.status);
            if (error.status === 401) {
                logout = true;
            }
        }
        
        if (logout) {
            Auth.signOut()
            .then(() => {
                this.props.history.replace('/signIn');
            })
            .catch(error => {
                console.error(error);
                if (error.code === 'NotAuthorizedException') {
                    this.props.history.replace('/signIn');
                }
            });
        }
    }
    checkValidation = () => {
        this.setState({ validationPass: this.state.privacyPolicy && (this.state.authAgremment || this.state.ageAgreement) });
    }

    render() {
        const name = this.state.kitProfile ? `${this.state.kitProfile.firstName} ${this.state.kitProfile.lastName}` : ''
        const gender = this.state.kitProfile ? this.state.kitProfile.gender : I18n.get('tosppDateodSexUnknown')
        const dateOfBirth = this.state.kitProfile ?
            `${gender}${I18n.get('tosppDateodBirthDes')}${Moment(this.state.kitProfile.birthDate, 'MM-DD-YYYY').format('MMMM DD, YYYY')}` : "";
        const dateOfBirthMoment = this.state.kitProfile ? Moment(this.state.kitProfile.birthDate, "MM-DD-YYYY") : undefined;
        const is18YearsRadioButtonDisabled = (dateOfBirthMoment !== undefined) ? ValidationUtil.validate18YearsOld(dateOfBirthMoment) : true;
        var ageAgreementText = I18n.get('tosppAgree1').replace("param1", name);
        var authAgreementText = I18n.get('tosppAgree2').replace("param1", name);

        return (
            <PageGrid className="allPage-rootPanel">
                <Grid.Row>
                    <PanelGridColumn size='large'>
                        <Forms size='large' header={I18n.get('researchPpTitle')}>
                            <Divider />
                            <Grid textAlign='left' verticalAlign='top'>
                                <Grid.Row>
                                    <Grid.Column>
                                        <Checkbox onChange={this.handlePrivacyPolicyChange}
                                            checked={this.state.privacyPolicy}
                                            id="privacyPolicy"
                                            label={<label>{I18n.get('researchPpRead')}
                                                <a rel="noopener noreferrer" target='_blank' className='blueLink' href={ApiUtil.getResearchConsentPolicyUrl()}>{I18n.get('researchPpTitle')}</a>
                                            </label>}>
                                        </Checkbox>
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                            <Divider />

                            <div className="bodyTitleLabel">{name}</div>
                            <div className='bodyLabel' hint='true'>{dateOfBirth}</div>

                            <Form.Field>
                                <Radio name='authorizeGroup' label={ageAgreementText} checked={this.state.ageAgreement} onClick={this.handleAgeAgreementChange} disabled={is18YearsRadioButtonDisabled}/>
                            </Form.Field>

                            <Form.Field>
                                <Radio name='authorizeGroup' label={authAgreementText} checked={this.state.authAgremment} onClick={this.handleAuthAgreementChange} />
                            </Form.Field>

                            <CancelButton ref={this.notConsentButton} disabled={!this.state.validationPass} margintop='true' onClick={this.handleNotConsentButtonClick}>
                                {I18n.get("researchPpCancelButton")}
                            </CancelButton>

                            <CTAButton ref={this.consentButton} submit='true' margintop='true' onClick={this.handleAcceptButtonClick} disabled={!this.state.validationPass}>
                                {I18n.get('researchPpConsentButton')}
                            </CTAButton>

                            <div className="bodyLabel textAlignCenter" hint='true' margin='true'  dangerouslySetInnerHTML={{__html: I18n.get('researchPpConsentDes')}}></div>
                        </Forms>
                    </PanelGridColumn>
                </Grid.Row>
            </PageGrid>
        )
    }
}

export default UpdateResearchConsent;
