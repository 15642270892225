import React, { Component } from 'react';
import { Storage } from 'aws-amplify';
import { Grid, Image, Loader } from 'semantic-ui-react';
import PDFObject from '../components/widgets/pdf/pdfobject';
import './Report.css';
import { I18n, Auth } from 'aws-amplify';
import CourseAdvertising from "../components/widgets/advertising/CourseAdvertising";
import { withRouter } from 'react-router-dom';
import ApiUtil from "../components/utils/ApiUtil";

class Report extends Component {
    state = { fileUrl: '', file: '', filename: '', numPages: null, pageNumber: 1, loading: false, stopLoad: false };

    componentDidMount() {
        const self = this;
        ApiUtil.checkAndHandleLogOut().then(function(response) {
            if(response) {
                if (self.props.isShared === undefined) {
                    return;
                }
                if (self.props.isShared) {
                    self.getFilePrivate(self.props.selectedKitProfile.kitId, self.props.ReprotPath);
                } else {
                    if (self.props.selectedKitProfile !== undefined && self.props.selectedKitProfile.currentStatus === 'REPORTS_READY') {
                        self.getFilePrivate(self.props.selectedKitProfile.id, self.props.ReprotPath);
                    }
                }
            } else {
                self.props.history.replace('/signIn');
            }
        });

    }

    componentWillReceiveProps(props) {
        PDFObject.embed('', '#pdfViewer');
        if (props.isShared === undefined
            || props.selectedKitProfile === undefined
            || props.ReprotPath === undefined
            || props.ReprotPath === ''
            || props.profileStatus === undefined) {
            return;
        }
        if (props.isShared) {
            this.getFilePrivate(props.selectedKitProfile.kitId, props.ReprotPath);
        } else {
            if (props.selectedKitProfile.currentStatus === 'REPORTS_READY') {
                this.getFilePrivate(props.selectedKitProfile.id, props.ReprotPath);
            }
        }
    }

    getFilePrivate = async (kitId, reprotPath) => {
        try {
            this.checkIfStillLoggedIn();
            if (reprotPath === '') {
                return;
            }
            this.setState({ loading: true });
            let fileUrl = `${kitId}/${reprotPath}`;
            const result = await Storage.vault.get(fileUrl, { bucket: this.props.bucket, level: 'private', expires: 3600 });
            if (result) {
                this.setState({ fileUrl: result });
                var options = {
                    fallbackLink: "<p>This is a <a href='[url]'>fallback link</a></p>",
                };
                PDFObject.embed(result, '#pdfViewer', options);
            }
        } catch (e) {
            console.error(e);
            console.error("message : " + e.message);
            console.error("response : " + e.response);
            console.error("data : " + e.data);
            console.error("code : " + e.code);
            if (e instanceof "CredentialsError") {
                Auth.signOut()
                .then(() => {
                    this.props.history.replace('/signIn');
                })
                .catch(error => {
                    console.error(error);
                    if (error.code === 'NotAuthorizedException') {
                        this.props.history.replace('/signIn');
                    }
                });
            }
        } finally {
            this.setState({ loading: false });
        }
    }
    
    checkIfStillLoggedIn() {
        if (localStorage.getItem('innerFlag') === null || localStorage.getItem('innerFlag') === false) {
            sessionStorage.clear();
            Auth.signOut()
            .then(() => {
                this.props.history.replace('/signIn');
            })
            .catch(error => {
                console.error(error);
                if (error.code === 'NotAuthorizedException') {
                    this.props.history.replace('/signIn');
                }
            });
        } 
    }

    render() {
        return (
            <Grid className='profilePage-tab-contentPanel'>
                <Grid.Row>
                    <Grid.Column className='padding0' textAlign='center'>
                        {this.state.loading && (
                            <Loader style={{ marginTop: 50 }} active />
                        )}
                        {this.props.profileStatus === 'REPORTS_READY' || this.props.ReprotPath !== '' ?
                            (
                                <div style={{ display: this.state.loading ? 'none' : 'block' }}>
                                    <div id='pdfViewer' className='section18' />
                                </div>
                            ) :
                            (
                                <div>
                                    <div className='tabPageTitleLabel section36'>{I18n.get('NoReportTitle')}</div>
                                    <Image className='reportPage-imagePanel section18' src='../../images/KitProcessDiagram.png' />
                                </div>
                            )}
                        <CourseAdvertising />
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        );
    }
}

export default withRouter(Report);
