import React, { Component } from 'react';
import { Auth } from 'aws-amplify/lib/index';
import { I18n } from 'aws-amplify';
import { Grid, Button } from 'semantic-ui-react';
import { NavLink as Link, withRouter } from 'react-router-dom';
import PageGrid from '../../widgets/grid/PageGrid';
import PanelGridColumn from '../../widgets/grid/PanelGridColumn';
import Forms from '../../widgets/form/Forms';

class RecoverUsernameConfirmation extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {

    }

    render() {
        return (
            <PageGrid className="allPage-rootPanel">
                <Grid.Row>
                    <PanelGridColumn>
                        <Forms className='section18' header={I18n.get('recoverUsernameTitle')}>

                            <div className='bodyLabel section12'>{I18n.get('recoverUsernameConfirmationSentText')}</div>

                            <div className='bodyLabel'>{I18n.get('createAccountConfirmationMsg3Text')}</div>
                            <div className='textAlignLeft'>
                                <a className='blueLink textAlignLeft' href='mailto:support@strategene.me'>
                                    {I18n.get('createAccountConfirmationSupport')}
                                </a>
                            </div>

                        </Forms>

                        <Link className='blueLink' to='/signIn'>{I18n.get('createAccountConfirmationSignIn')}</Link>
                    </PanelGridColumn>
                </Grid.Row>
            </PageGrid>
        );
    }
}

export default withRouter(RecoverUsernameConfirmation);
