import React, {Component} from 'react';
import './App.css';
import './css/base.css';
import Authentication from './pages/Authentication';
import {Auth, Hub, I18n} from 'aws-amplify';
import Amplify from 'aws-amplify';
import ApiUtil from './components/utils/ApiUtil';
import PropTypes from 'prop-types'

class App extends Component {

    constructor(props) {
        super(props);

        ApiUtil.configure('');

        var path = '';

        if (process.env.REACT_APP_ENV === 'production' || process.env.REACT_APP_ENV === 'staging') {
            path = window.location.origin + '/aws-exports.json';
        } else if (process.env.REACT_APP_ENV === 'development') {
            path = window.location.origin + '/aws-exports-dev.json';
        }

        fetch(path)
            .then(response => {
                return process.env.REACT_APP_ENV === 'development' ? response.json() : response.text();
            })
            .then(
                result => {
                    try {
                        let decodeResult = window.atob(result);
                        if (process.env.REACT_APP_ENV === 'staging' || process.env.REACT_APP_ENV === 'development') {
                            //console.log('decodeResult : ' + decodeResult);
                        }
                        const decodedJason = JSON.parse(decodeResult);
                        Amplify.configure(decodedJason);
                        ApiUtil.configure(decodedJason.API.endpoints[0].endpoint);
                        ApiUtil.updateIdToken();
                        ApiUtil.setEmbeddedUrl(decodedJason.embeddedUrl);
                        ApiUtil.setExternalUrl(decodedJason.externalUrl);
                        ApiUtil.setPolicyUrl(decodedJason.policyUrl);
                    } catch (e) {
                        if (process.env.REACT_APP_ENV === 'staging') {
                            console.log(e);
                            console.log('dev result:' + JSON.stringify(result));
                        }

                        Amplify.configure(result);
                        ApiUtil.configure(result.API.endpoints[0].endpoint);
                        ApiUtil.updateIdToken();
                        ApiUtil.setEmbeddedUrl(result.embeddedUrl);
                        ApiUtil.setExternalUrl(result.externalUrl);
                        ApiUtil.setPolicyUrl(result.policyUrl);
                    }
                    this.setState({exportLoaded: true});
                    //this.checkStorageSession();
                },
                error => {
                    this.setState({exportLoaded: true});
                    console.error(error);
                    this.checkStorageSession();
                }
            );

        I18n.setLanguage('en');
        let i18nPath = window.location.origin + '/i18n/en.json';
        fetch(i18nPath)
            .then(res => res.json())
            .then(
                result => {
                    I18n.putVocabularies({en: result});
                    this.setState({i18nIsLoaded: true});
                },
                error => {
                    console.error(error);
                },
            );
    }

    state = {
        i18nIsLoaded: false,
        exportLoaded: false
    }

    render() {
        return (
            this.state.i18nIsLoaded && this.state.exportLoaded && <div className='height-fix'>
                <Authentication/>
            </div>)
    }

    checkStorageSession() {
        //console.log('sessionStorage.length : ' + sessionStorage.length);
        // This takes care of logging out the user if the browser window or tab is closed
        if (sessionStorage.length === 0) {
            //console.log('sessionStorage.length is 0. Signing out.');
            this.logout();
        }
    }

    componentDidMount() {
        window.addEventListener('beforeunload', (event) => {
            //console.log('componentDidMount-window close event');
            //this.logout();
        });
    }

    componentWillUnmount() {
        window.addEventListener('beforeunload', (event) => {
            //console.log('componentWillUnmount-window close event');
            //this.logout();
        });
    }

    logout() {
        sessionStorage.clear();
        Auth.signOut()
            .then(() => {
                if (typeof this.props !== "undefined") {
                    if (typeof this.props.history !== "undefined") {
                        this.props.history.replace('/signIn');
                    }
                }
            })
            .catch(error => {
                console.error(error);
                if (error.code === 'NotAuthorizedException') {
                    if (typeof this.props !== "undefined") {
                        if (typeof this.props.history !== "undefined") {
                            this.props.history.replace('/signIn');
                        }
                    }
                }
            });
    }
}

export default App;
