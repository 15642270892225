import { I18n } from 'aws-amplify';
import Moment from 'moment';

const ValidationUtil = {
    validateUsername: function (username) {
        const usernameRegex = /^[a-zA-Z0-9_-]{6,}$/;
        return this.validate({
            fileld: "Username",
            regex: usernameRegex,
            text: username,
            minLength: 6,
            regexErrorMessage: I18n.get('createAccountUserNameError')
        });
    },

    validateOverridePass: function (username) {
        const usernameRegex = /./;
        return this.validate({
            fileld: "Username",
            regex: usernameRegex,
            text: username,
            minLength: 1,
            regexErrorMessage: I18n.get('createAccountUserNameError')
        });
    },

    validatePassword: function (password) {
        const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\\/~`!@#$%^&*(),.?\-_":;{}[\]|<>'+=])[A-Za-z\d\\/~`!@#$%^&*(),.?\-_":;{}[\]|<>'+=]{8,}$/;
        return this.validate({
            fileld: "Password",
            regex: passwordRegex,
            text: password,
            regexErrorMessage: I18n.get('createAccountPasswordError')
        });
    },

    validateEmail: function (email) {
        const emailRegex = /^[a-zA-Z0-9][a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]{1,63}@[a-zA-Z0-9][a-zA-Z0-9.-]{1,252}\.[a-zA-Z]{2,}/;
        return this.validate({
            fileld: "Email Address",
            regex: emailRegex,
            text: email,
            regexErrorMessage: I18n.get('createAccountEmailError')
        });
    },

    validateFirstName: function (firstName) {
        const firstNameRegex = /^[a-zA-Z0-9][a-zA-Z_-]{1,}$/;
        return this.validate({
            fileld: "First Name",
            regex: firstNameRegex,
            text: firstName,
            regexErrorMessage: I18n.get('createAccountNameError')
        });
    },

    validateLastName: function (lastName) {
        const lastNameRegex = /^[a-zA-Z0-9][a-zA-Z_-]{1,}$/;
        return this.validate({
            fileld: "Last Name",
            regex: lastNameRegex,
            text: lastName,
            regexErrorMessage: I18n.get('createAccountNameError')
        });
    },

    validateActivateKitCode: function (kitCode) {
        const kitCodeRegex = /\b[0-9]{13}\b/;
        return this.validate({
            fileld: "Activate Kit Code",
            regex: kitCodeRegex,
            regexErrorMessage: I18n.get('ActivateKitCodeWrongCharactersErrorMsg'),
            text: kitCode,
            length: 13,
            lengthErrorMsg: I18n.get('ActivateKitCodeMinLengthErrorMsg')
        });
    },

    validateDeleteAccountCode: function (code) {
        const codeRegex = /^(.{6,})$/;
        return this.validate({
            fileld: "Activate Kit Code",
            regex: codeRegex,
            text: code,
        });
    },

    validate18YearsOld: function (dateOfBirth) {
        var today = Moment(new Date(), 'MMMM DD, YYYY');
        var minAcceptableDate = Moment(today).year(today.year() - 18);
        return dateOfBirth.isAfter(minAcceptableDate)
    },


    validate: function (config) {
        if (config.text === undefined || config.text === "") {
            return { status: false, msg: I18n.get('errorMessageRequiredField') };
        }

        const lengthErrorMsg = (config.lengthErrorMsg) ? config.lengthErrorMsg : `The length of ${config.fileld} is more or less than ${config.minLength} characters.`;
        if (config.length !== undefined && config.text.length !== config.length) {
            return { status: false, msg: lengthErrorMsg };
        }

        const minLengthErrorMsg = (config.minLengthErrorMsg) ? config.minLengthErrorMsg : `The length of ${config.fileld} is less than ${config.minLength} characters.`;
        if (config.minLength !== undefined && config.text.length < config.minLength) {
            return { status: false, msg: minLengthErrorMsg };
        }

        const status = config.regex.test(config.text);
        const regexErrorMessage = (config.regexErrorMessage) ? config.regexErrorMessage : `${config.fileld} does not match the required format.`;
        return { status: status, msg: (status) ? undefined : regexErrorMessage };
    }
}

export default ValidationUtil;
