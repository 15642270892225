import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Grid, Image, Segment, Tab } from 'semantic-ui-react';
import PageGrid from '../components/widgets/grid/PageGrid';
import GridRow from '../components/widgets/grid/GridRow';
import ProfileCardSimple from '../components/widgets/card/profileCard/ProfileCardSimple';
import { I18n, Hub, Auth } from 'aws-amplify';
import ApiUtil from "../components/utils/ApiUtil";
import Tabs from '../components/widgets/tab/Tabs';
import MenuButton from '../components/widgets/menu/MenuButton';
import './Page.css';

class MyGeneticsPage extends Component {

    constructor(props) {
        super(props);
        this.profileDropdown = React.createRef();
        this.state = {
            kitProfileList: [],
            mediaList: [],
            isShared: false,
            profileDropdownLoading: false,
            url: ApiUtil.getHomeUrl()
        }
    }
    
    componentDidMount() {
        const self = this;
        ApiUtil.checkAndHandleLogOut().then(function(response) {
            if(response) {
                self.fetchMyProfile();
            } else {
                self.props.history.replace('/signIn');
            }
        });
    }

    fetchMyProfile = async () => {
        this.profileDropdown.current.setLoadding(true);
        this.setState({ kitProfileList: [] });

        try {
            /*const response = ApiUtil.initProfile().then(function(data) {
                this.setState({
                    kitProfileList: data
                });
                Hub.dispatch( 'loadEducation', {});
                this.profileDropdown.current.setLoadding(false);
            }.bind(this));*/

            const response = await ApiUtil.initProfile();
            await this.setState({
                kitProfileList: response
            });
            Hub.dispatch( 'loadEducation', {});
            this.profileDropdown.current.setLoadding(false);

        } catch (error) {
            //console.log('fetchMyProfile', error);
            if (this.profileDropdown.current) {
                this.profileDropdown.current.setLoadding(false);
            }
            this.setState({kitProfileList: []});
            this.checkAndHandleLogOut(error);
        }
    }

    fetchSharedProfile = async () => {
        this.profileDropdown.current.setLoadding(true);
        this.setState({ kitProfileList: [] });

        try {
            const response = await ApiUtil.getSharedKits();
            this.setState({ kitProfileList: response });
            this.profileDropdown.current.setLoadding(false);

        } catch (error) {
            this.profileDropdown.current.setLoadding(false);
            this.checkAndHandleLogOut(error);
        }
    }
    
    handleProfileOnChange = (event, data) => {
        this.checkIfStillLoggedIn();
        if (data.value === I18n.get('profileCardSimpleMyProfiles') || data.activeIndex === 0) {
            this.fetchMyProfile();
            this.setState({ isShared: false })

        } else if (data.value === I18n.get('profileCardSimpleSharedProfiles') || data.activeIndex === 1) {
            this.fetchSharedProfile()
            this.setState({ isShared: true })
        }
    }

    handleAdImageClick = async () => {
        const url = await ApiUtil.getDirtyGeneBookUrl();
        window.open(url, '_blank')
    }
    
    checkIfStillLoggedIn() {
        if (localStorage.getItem('innerFlag') === null || localStorage.getItem('innerFlag') === false) {
            Auth.signOut()
            .then(() => {
                this.props.history.replace('/signIn');
            })
            .catch(error => {
                console.error(error);
                if (error.code === 'NotAuthorizedException') {
                    this.props.history.replace('/signIn');
                }
            });
        } 
    }

    handleViewButtonClick = (kitProfile) => {
        this.checkIfStillLoggedIn();
        let id = '';
        if (this.state.isShared) {
            id = kitProfile.kitId;
        } else {
            id = kitProfile.id;
        }
        id = window.btoa(id);
        this.props.history.push({
            pathname: `/profilePage/${id}`,
            state: {
                selectedKitProfile: kitProfile,
                isShared: this.state.isShared,
                reportShared: kitProfile.reportShared,
                rawDataShared: kitProfile.rawDataShared
            }
        });
    }

    handleApproveButtonClick = async (kitProfile, approveButton) => {
        this.checkIfStillLoggedIn();
        //console.log('clicked Approve');
        //console.log(kitProfile);
        approveButton.current.setLoading(true)
        try {
            //await ApiUtil.deleteSharedKit(kitProfile.id);
            var xmlHttp = new XMLHttpRequest();
            xmlHttp.open( "GET", 'https://clia-approval.strategene-dashboard.montanab.com/approve-share/'+kitProfile.id+'/'+kitProfile.accountId+'/'+kitProfile.kitId, false); // false for synchronous request
            xmlHttp.send( null );
            var copyThis = this;
            setTimeout(function() {
                approveButton.current.setLoading(false)
                copyThis.fetchSharedProfile();
            }, 1000, copyThis);

        } catch (error) {
            approveButton.current.setLoading(false)
        }
    }

    handleRemoveButtonClick = async (kitProfile, removeButton) => {
        this.checkIfStillLoggedIn();
        removeButton.current.setLoading(true)
        try {
            var xmlHttp = new XMLHttpRequest();
            xmlHttp.open( "GET", 'https://clia-approval.strategene-dashboard.montanab.com/remove-share/'+kitProfile.id+'/'+kitProfile.accountId+'/'+kitProfile.kitId, false); // false for synchronous request
            xmlHttp.send( null );
            await ApiUtil.deleteSharedKit(kitProfile.id);
            removeButton.current.setLoading(false)
            this.fetchSharedProfile();

        } catch (error) {
            removeButton.current.setLoading(false)
        }
    }

    renderProfileCard = () => {
        const profileCardList = (this.state.kitProfileList) ? this.state.kitProfileList.map((profile, index) => {
            return (
                <div key={index}>
                    <ProfileCardSimple isShared={this.state.isShared} profile={profile} onApproveClick={this.handleApproveButtonClick} onCancelClick={this.handleRemoveButtonClick} onClick={this.handleViewButtonClick} />
                </div>
            )
        }) : [];
        //console.log(profileCardList);
        return profileCardList
    }

    activateKitButtonOnClick = () => {
        this.props.history.push('/activate');
    };
    
    checkAndHandleLogOut = (error) => {
        //console.log('checkAndHandleLogOut', error);
        var logout = false
        if (error.data != null) {
            console.error("Error status : " + error.data.status);
            if (error.data.status === 401) {
                logout = true;
            }
        } else if (error.status != null) {
            console.error("Error status : " + error.status);
            if (error.status === 401) {
                logout = true;
            }
        }
        
        if (logout) {
            Auth.signOut()
            .then(() => {
                this.props.history.replace('/signIn');
            })
            .catch(error => {
                console.error(error);
                if (error.code === 'NotAuthorizedException') {
                    this.props.history.replace('/signIn');
                }
            });
        }
    }

    render() {
        return (
            <Segment vertical className="myGeneticsPage-rootPanel">
                <div className='myGeneticsPage-welcomeBanner'><div>
                    <Image src='./images/welcome.svg' />
                </div></div>
                <PageGrid noscrollbar={'true'} className="myGeneticsPage-contentPanel">
                    <GridRow columns={1} textAlign='left'>
                        <Grid.Column className='myGeneticsPage-leftPanel'>
                            <Tabs ref={this.profileDropdown} menu={{ secondary: true }} onTabChange={this.handleProfileOnChange} parent={this} />
                        </Grid.Column>

                        <Grid.Column className='myGeneticsPage-mediaPanel'  >
                        </Grid.Column>
                    </GridRow>
                </PageGrid>
            </Segment>
        );
    }
}

export default withRouter(MyGeneticsPage);
