import React, { Component } from 'react';
import { Auth } from 'aws-amplify/lib/index';
import {Grid, Image, Loader, Message, Segment} from 'semantic-ui-react';
import {NavLink as Link, withRouter} from 'react-router-dom';
import PageGrid from '../../widgets/grid/PageGrid';
import PanelGridColumn from '../../widgets/grid/PanelGridColumn';
import { SelectMFAType } from 'aws-amplify-react';
import {Hub, JS} from "aws-amplify/lib";
import {I18n} from "aws-amplify";
import Input from "../../widgets/Input/Input";
import CTAButton from "../../widgets/button/CTAButton";
import Forms from "../../widgets/form/Forms";
import ApiUtil from "../../utils/ApiUtil";

class ConfirmSignIn extends Component {
    state = {
        user: this.props.inputs.username,
        errors: [],
        inputMFA: '',
        loading: false
    };

    constructor(props) {
        super(props);

        this.submitButton = React.createRef();

        this.confirmSignIn = this.confirmSignIn.bind(this);
        this.handleGoBack = this.handleGoBack.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        this.getUser();
    }

    getUser = async () => {
        if(!this.state.user) {
            this.props.history.replace('/signIn');
        }
    }

    handleGoBack() {
        this.props.history.goBack();
    }

    handleChange(event) {
        this.setState({
            [event.target.id]: event.target.value
        }, function() {
            if(this.state.inputMFA.length == 6) {
                this.confirmSignIn(event);
            }
        });
    }

    async confirmSignIn(event) {
        // avoid submitting the form
        event.preventDefault();

        // Clean the error message
        this.submitButton.current.setLoading(true);
        this.setState({errors: []});
        this.setState({loading: true});

        const code = this.state.inputMFA;
        const user = this.state.user;

        try {
            const loggedInUser = await Auth.confirmSignIn(
                user,   // Return object from Auth.signIn()
                code,   // Confirmation code
                user.challengeName // MFA Type e.g. SMS_MFA, SOFTWARE_TOKEN_MFA
            );

            this.setState({ user: loggedInUser });
            // Update the new idToken for new login user
            await ApiUtil.updateIdToken();

            const cognitoUser = await Auth.currentAuthenticatedUser();
            localStorage.setItem('innerFlag', true);
            sessionStorage.setItem('innerFlag', true);
            sessionStorage.setItem('userSubId', cognitoUser.attributes.sub);
            Hub.dispatch( 'userLoggedIn', {});

            this.props.switchComponent('SignedIn');
            this.props.history.push('/myGenetics');
        } catch (err) {
            console.log('err', err);
            this.setState({loading: false});
            if (err.message === undefined) {
                this.setState({ errors: [err] });
            } else {
                //console.log('errors set state');
                this.setState({ errors: [err.message] });
            }

        } finally {
            if (this.submitButton.current !== null) {
                this.submitButton.current.setLoading(false);
            }
        }
    }

    render() {
        const showErrorMessage = (this.state.errors.length !== 0);

        return (
            <PageGrid className="allPage-rootPanel">
                <Grid.Row>
                    <PanelGridColumn>
                        <Image className='signin-logo' src='../../images/StrateGene-logo.svg'/>

                            <Forms className='section18' header={I18n.get('Enter your verfication code')}
                                   onSubmit={this.confirmSignIn}>

                                <Input type='text' id='inputMFA' placeholder={I18n.get('Verification code')}
                                       name={I18n.get('Verification code')} onChange={this.handleChange}/>

                                <CTAButton ref={this.submitButton} submit='true'
                                           disabled={!this.state.inputMFA}> {' '}
                                    {I18n.get('login')}
                                </CTAButton>

                                {
                                    showErrorMessage === true && (
                                        <Message color='red'>
                                            {this.state.errors}
                                        </Message>)
                                }

                            </Forms>

                        <Link className='blueLink' to='/signIn'>{I18n.get('loginCreateAccountText')}</Link>
                    </PanelGridColumn>
                </Grid.Row>
            </PageGrid>
        );
    }
}

export default withRouter(ConfirmSignIn);
