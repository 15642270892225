import React, { Component } from 'react';
import { Grid } from 'semantic-ui-react';
import { withRouter } from 'react-router-dom';
import { Auth } from 'aws-amplify/lib/index';
import ApiUtil from '../../utils/ApiUtil';
import { I18n } from 'aws-amplify';
import CTAButton from '../../widgets/button/CTAButton';
import CancelButton from '../../widgets/button/CancleButton';
import Input from '../../widgets/Input/Input';
import PageGrid from '../../widgets/grid/PageGrid';
import PanelGridColumn from '../../widgets/grid/PanelGridColumn';
import Forms from '../../widgets/form/Forms';
import ValidationUtil from "../../utils/ValidationUtil";

class DeleteAccountVerifyCode extends Component {

    constructor(props, context) {
        super(props, context);
        this.deleteButton = React.createRef();
        this.confirmationCodeInput = React.createRef();
        this.state = {
            email: '',
            code: '',
            codeCheck: false,
        }

        this.handleChange = this.handleChange.bind(this);
        this.handleGoAccount = this.handleGoAccount.bind(this);
    }

    componentWillMount() {
        this.getUser();
    }

    async getUser() {
        const user = await Auth.currentAuthenticatedUser();
        console.debug(user);
        this.setState({ email: user.attributes.email });
    }


    handleGoAccount() {
        this.props.history.goBack();
        this.props.history.push('/account');
    }

    handleDeleteAccount = async () => {
        try {
            this.deleteButton.current.setLoading(true)
            const verifyConfirmCodeResponse = await ApiUtil.verifyConfirmCode(this.state.code);
            if (verifyConfirmCodeResponse.status === 200) {
                const response = await ApiUtil.deleteAccount(this.state.code);
                if (response.status === 200) {
                    this.handleDeleteCognitoUser();
                } else {
                    console.debug('Delete account failed.');
                    this.confirmationCodeInput.current.setError(true, I18n.get('deleteErrorMsg'));
                    this.deleteButton.current.setLoading(false)
                }
            } else {
                console.debug('Delete account failed.');
                this.confirmationCodeInput.current.setError(true, I18n.get('deleteErrorMsg'));
                this.deleteButton.current.setLoading(false)
            }
        } catch (error) {
            console.error(error);
            this.confirmationCodeInput.current.setError(true, I18n.get('deleteErrorMsg'));
            this.deleteButton.current.setLoading(false);
            this.checkAndHandleLogOut(error);
        }
    }
    
    checkAndHandleLogOut = (error) => {
        var logout = false
        if (error.data != null) {
            console.error("Error status : " + error.data.status);
            if (error.data.status === 401) {
                logout = true;
            }
        } else if (error.status != null) {
            console.error("Error status : " + error.status);
            if (error.status === 401) {
                logout = true;
            }
        }
        
        if (logout) {
            Auth.signOut()
            .then(() => {
                this.props.history.replace('/signIn');
            })
            .catch(error => {
                console.error(error);
                if (error.code === 'NotAuthorizedException') {
                    this.props.history.replace('/signIn');
                }
            });
        }
    }

    handleDeleteCognitoUser() {
        Auth.currentAuthenticatedUser()
            .then(user => {
                if (user != null) {

                    this.deleteButton.current.setLoading(false)
                    user.deleteUser(error => {
                        if (!error) {
                            document.location.href = '/signIn';
                        }
                    });
                }
            })
            .catch(err => {
                console.error(err);
                this.deleteButton.current.setLoading(false)
                this.setState({ user: null });
            });
    }

    checkValidation = async () => {
        const codeValidation = await ValidationUtil.validateDeleteAccountCode(this.state.code);
        await this.setState({
            validationPass: codeValidation.status
        });
        this.confirmationCodeInput.current.setError(!codeValidation.status, "");

    };

    async handleChange(event) {
        await this.setState({
            [event.target.id]: event.target.value
        });
        this.checkValidation();
    }

    render() {
        const isActivateButtonDisabled = !this.state.validationPass

        return (
            <PageGrid className="allPage-rootPanel">
                <Grid.Row>
                    <PanelGridColumn>
                        <Forms header={I18n.get('deleteAccountTitle')} autoComplete='off'>

                            <div className='bodyLabel'>{I18n.get('deleteAccountVerifyCodeSubTitle')}</div>
                            <div className='bodyTitleLabel section24'>{this.state.email}</div>
                            <div className='bodyLabel section12'>{I18n.get('deleteAccountVerifyCodeDescTitle')}</div>

                            <Input ref={this.confirmationCodeInput} type='text' id='code' placeholder={I18n.get('deleteAccountVerifyCodeInput')} className='section24'
                                name={I18n.get('deleteAccountVerifyCodeInput')} onChange={this.handleChange} />

                            <CTAButton ref={this.deleteButton} submit='true' margintop='true' warning='true' onClick={this.handleDeleteAccount}
                                disabled={isActivateButtonDisabled} className='section4'>
                                {I18n.get('deleteAccountTitle')}
                            </CTAButton>

                            <div className='bodyLabel section12' hint='true'>{I18n.get('deleteAccountVerifyCodeBtnDesc')}<sup>®</sup> {I18n.get('deleteAccountVerifyCodeBtnDesc2')}</div>

                            <CancelButton onClick={this.handleGoAccount}>
                                {I18n.get('deleteAccountCancel')}
                            </CancelButton>
                        </Forms>

                    </PanelGridColumn>
                </Grid.Row>
            </PageGrid>
        );
    }
}

export default withRouter(DeleteAccountVerifyCode);
