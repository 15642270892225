import React, { Component } from 'react';
import {Hub, I18n} from 'aws-amplify';
import { Auth } from 'aws-amplify/lib/index';
import {Grid, Message, Segment} from 'semantic-ui-react';
import {NavLink as Link, withRouter} from 'react-router-dom';
import PageGrid from '../../widgets/grid/PageGrid';
import PanelGridColumn from '../../widgets/grid/PanelGridColumn';
import { SelectMFAType } from 'aws-amplify-react';
import { CustomSelectMFAType } from "../../widgets/mfa/CustomSelectMFAType";
import {JS} from "aws-amplify/lib";

import './ChangeMFAStatus.css';
import Forms from "../../widgets/form/Forms";
import ApiUtil from "../../utils/ApiUtil";

class ChangeMFAStatus extends Component {
    state = {
        user: this.props.inputs.user,
        mfaStatus: null
    };

    constructor(props) {
        super(props);
        this.onTOTPEvent = this.onTOTPEvent.bind(this);
    }

    componentWillMount() {
        ApiUtil.checkAndHandleLogOut().then(function(response) {
            if(response) {
                this.getUser();
            } else {
                this.props.history.replace('/signIn');
            }
        }.bind(this));
    }

    onTOTPEvent() {
        this.props.history.replace('/account');
    }

    getUser = async () => {
        try {
            const user = await Auth.currentAuthenticatedUser();
            if (!user) {
                return;
            }
            const data = await Auth.verifiedContact(user);
            if (!JS.isEmpty(data.verified)) {
                this.setState({ user: user, emailVerified: I18n.get('emailVerified'), disableDeleteAccount: false });
            } else {
                this.setState({ user: user, emailVerified: I18n.get('emailNotVerified'), disableDeleteAccount: true });
            }

            await Auth.getPreferredMFA(user).then((data) => {
                this.setState({ mfaStatus: I18n.get('account' + data) });
            })
        } catch (e) {
            this.setState({ user: null });
        }
    }

    render() {
        const MFATypes = {
            SMS: true, // if SMS enabled in your user pool
            TOTP: false, // if TOTP enabled in your user pool
            Optional: true, // if MFA is set to optional in your user pool
        }

        return (
            <Segment vertical className="allPage-rootSegment">
                <PageGrid className="allPage-rootPanel">
                    <Grid.Row columns={1}>
                        <PanelGridColumn margin='true' className='accountPageGridColumn mfa-container section36'>
                            <Segment basic>
                                <Grid columns={1}>
                                    <Grid.Row className='titleCoulum section24'>
                                        <Grid.Column textAlign='left'>
                                            {this.state.user ? (
                                            <Forms className='section18' header={I18n.get('Select MFA Type')} onSubmit={this.signIn}>
                                                <CustomSelectMFAType authData={this.state.user} MFATypes={MFATypes} onTOTPEvent={this.onTOTPEvent}></CustomSelectMFAType>
                                            </Forms>
                                            ) : null}

                                            <div className='bodyLabel textAlignCenter'>
                                                <Link className='blueLink' to='/account'>{I18n.get('accountRetunMyAccunt')}</Link>
                                            </div>
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                            </Segment>
                        </PanelGridColumn>
                    </Grid.Row>
                </PageGrid>
            </Segment>
        );
    }
}

export default withRouter(ChangeMFAStatus);
