import React, { Component } from 'react';
import { Auth } from 'aws-amplify/lib/index';
import { I18n } from 'aws-amplify';
import { Grid, Button } from 'semantic-ui-react';
import { NavLink as Link, withRouter } from 'react-router-dom';
import PageGrid from '../../widgets/grid/PageGrid';
import PanelGridColumn from '../../widgets/grid/PanelGridColumn';
import Forms from '../../widgets/form/Forms';

class ChangePasswordConfirmation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
        };
        this.handleResendForgotPassword = this.handleResendForgotPassword.bind(this);
    }

    componentDidMount() {
        Auth.currentAuthenticatedUser()
            .then(user => {
                if (user != null) {
                    this.setState({ email: user.attributes.email });
                }
            })
            .catch(err => {
                this.setState({ user: null });
            });
    }

    async handleResendForgotPassword() {
        if (this.state.email != null) {
            await Auth.forgotPassword(this.state.email);
        }
    }

    render() {
        return (
            <PageGrid className="allPage-rootPanel">
                <Grid.Row>
                    <PanelGridColumn>
                        <Forms className='section18' header={I18n.get('resetPasswordTitle')}>

                            <div className='bodyLabel'>{I18n.get('createAccountConfirmationSentText')}</div>
                            <div className='form-emailLabel textAlignLeft section12'>{this.state.email}</div>

                            <div className='bodyLabel section12'>{I18n.get('signUpConfirmationExpireText')}</div>
                            <div className='bodyLabel section12'>
                                {I18n.get('resetPasswordConfirmationMsg1Text')}
                                <Button onClick={this.handleResendForgotPassword} className='linkButton'>
                                    {I18n.get('createAccountConfirmationMsg2Text')}
                                </Button>
                            </div>
                            <div className='bodyLabel'>{I18n.get('createAccountConfirmationMsg3Text')}</div>
                            <a className='blueLink textAlignLeft' href='mailto:support@strategene.me'>
                                {I18n.get('createAccountConfirmationSupport')}
                            </a>
                        </Forms>
                        <Link className='blueLink' to='/account'>{I18n.get('accountRetunMyAccunt')}</Link>
                    </PanelGridColumn>
                </Grid.Row>
            </PageGrid>
        );
    }
}

export default withRouter(ChangePasswordConfirmation);
