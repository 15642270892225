import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Auth, I18n, Hub } from 'aws-amplify';
import { Responsive, Segment, Visibility } from 'semantic-ui-react';
import Menu from '../menu/Menu';
import MenuItem from '../menu/MenuItem';
import MenuExternalItem from '../menu/MenuExternalItem';
import { matchRoutes } from 'react-router-config';
import routes from '../../../routes';
import { withRouter } from 'react-router-dom';
import './Navigator.css';
import ApiUtil from "../../utils/ApiUtil";

/*
const getWidth = () => {
    const isSSR = typeof window === 'undefined';
    return isSSR ? Responsive.onlyTablet.minWidth : window.innerWidth;
};*/


const getHeight = () => {
    const isSSR = typeof window === 'undefined';
    let height = isSSR ? Responsive.onlyTablet.minHeight : window.innerHeight;
    return height- 112;
};

class NavigatorDesktop extends Component {
    hideFixedMenu = () => this.setState({ fixed: false });
    showFixedMenu = () => this.setState({ fixed: true });

    constructor(props) {
        super(props);
        this.menuPanel = React.createRef();
        this.state = {
            hasHeader: false,
            hasRounter: false,
            hasKits: false,
            activeItem: ''
        };
        this.props.history.listen((location, action) => {
            if (action === 'PUSH' || action === 'POP') {
                this.checkAuth(location);
            }
        });
        Hub.listen('loadEducation', (data) => {
            this.getEducationUrl();
        });
        Hub.listen('userLoggedOut', (data) => {
            this.props.location.pathname = '/signIn';
        });
    }

    componentDidMount() {
        this.setState({ menuPanelHeight: getHeight()})

        ApiUtil.checkAndHandleLogOut().then(function(response) {
            if(response) {
                this.getEducationUrl();
                /*ApiUtil.initProfile().then(function() {
                    this.getEducationUrl();
                }.bind(this));*/
            }
        }.bind(this));


    }

    componentWillMount() {
        let matchRouter = matchRoutes(routes, this.props.location.pathname);
        this.checkAuth(matchRouter);
    }

    async getEducationUrl() {
        this.checkIfStillLoggedIn();
        await ApiUtil.initProfile();
        if(this.state.hasKits) {
            return;
        }
        try {
            let url = await ApiUtil.getEducationUrl();
            if (url !== null) {
                this.setState({ hasKits: true });
            } else {
                this.setState({ hasKits: false });
            }
        } catch (e) {
            this.setState({ hasKits: false });
            this.checkAndHandleLogOut(e);            
        }
    }
    
    checkIfStillLoggedIn() {
        if (localStorage.getItem('innerFlag') === null || localStorage.getItem('innerFlag') === false) {
            Auth.signOut()
            .then(() => {
                this.props.history.replace('/signIn');
            })
            .catch(error => {
                console.error(error);
                if (error.code === 'NotAuthorizedException') {
                    this.props.history.replace('/signIn');
                }
            });
        } 
    }
    
    checkAndHandleLogOut = (error) => {
        var logout = false
        if (error.data != null) {
            console.error("Error status : " + error.data.status);
            if (error.data.status === 401) {
                logout = true;
            }
        } else if (error.status != null) {
            console.error("Error status : " + error.status);
            if (error.status === 401) {
                logout = true;
            }
        }
        
        if (logout) {
            Auth.signOut()
            .then(() => {
                this.props.history.replace('/signIn');
            })
            .catch(error => {
                console.error(error);
                if (error.code === 'NotAuthorizedException') {
                    this.props.history.replace('/signIn');
                }
            });
        }
    }

    async checkAuth(location) {
        let matchRouter = matchRoutes(routes, this.props.location.pathname);
        if (matchRouter[0]) {
            this.setState({ activeItem: '' })
            if (matchRouter[0].route.path.indexOf('/profilePage') !== -1) {
                this.setState({ activeItem: ' active' })
            } else {
                if ((matchRouter[0].route.authAccess !== undefined && matchRouter[0].route.authAccess)
                || location.pathname === '/signIn'
                || matchRouter[0].route.path === '/signIn'
                || location.pathname === '/signUp'
                || matchRouter[0].route.path === '/signUp') {
                    if (location.pathname === '/resetPassword' || location.pathname === '/recoverUsername') {
                        return;
                    }
                    try {
                        await Auth.currentAuthenticatedUser();
                        this.setState({ hasHeader: true, hasRounter: true });
                        if (matchRouter[0].route.path === '/signIn'
                        || location.pathname === '/signIn'
                        || matchRouter[0].route.path === '/signUp'
                        || location.pathname === '/signUp') {
                            this.props.history.replace('/myGenetics');
                        }
                    } catch (e) {
                        if (e === 'not authenticated') {
                            this.setState({ hasHeader: false, hasRounter: true });
                            if (location.pathname === '/activate'
                            || location.pathname === '/signUp'
                            || (location[0] !== undefined && location[0].route.path === '/activate')
                            || (location[0] !== undefined && location[0].route.path === '/signUp')) {

                                this.props.history.replace({
                                    pathname: '/signUp',
                                    state: {
                                        status: 'activate'
                                    }
                                });
                            } else {
                                this.props.history.replace("/signIn");
                            }
                        }
                    }
                } else {
                    this.setState({ hasHeader: false, hasRounter: true });
                }
            }
        } else {
            this.setState({ hasHeader: false, hasRounter: true });
        }
    }

    render() {
        const { children } = this.props;
        let desktopStyle = '';
        if (matchRoutes(routes, this.props.location.pathname)[0] === undefined) {
            desktopStyle = "child-desktop-withoutHeader";
        } else {
            desktopStyle = matchRoutes(routes, this.props.location.pathname)[0].route.header ? "child-desktop" : "child-desktop-withoutHeader";
        }
        return (
            <Responsive>
                <Visibility ref={this.menuPanel} once={false}
                className="menu-desktop"
                onBottomPassed={this.showFixedMenu}
                onBottomPassedReverse={this.hideFixedMenu}>
                    {
                        matchRoutes(routes, this.props.location.pathname)[0] &&
                        matchRoutes(routes, this.props.location.pathname)[0].route.header &&
                        (
                            <Segment className='menu-segment' textAlign='center' vertical>
                                <Menu>
                                    <MenuItem className={this.state.activeItem} text={I18n.get('HomeMenuMyGenetics')} path='/myGenetics' />
                                    {
                                        this.state.hasKits &&
                                        (
                                            <MenuItem text={I18n.get('HomeMenuEducation')} path='/education' />
                                        )
                                    }
                                    <MenuExternalItem text={I18n.get('HomeMenuFAQ')} href='https://seekinghealth.zendesk.com/hc/en-us/sections/360006465413-StrateGene-Genetic-Test-Coming-in-2020-' />
                                </Menu>
                            </Segment>
                        )
                    }
                </Visibility>
                {
                    matchRoutes(routes, this.props.location.pathname)[0] &&
                        matchRoutes(routes, this.props.location.pathname)[0].route.header ?
                        (
                            <div className={desktopStyle}>
                                {children}
                            </div>
                        ) : (
                            <div style={{ height: this.state.menuPanelHeight }} className={desktopStyle}>
                                {children}
                            </div>
                        )
                }

            </Responsive>
        );
    }
}

NavigatorDesktop.propTypes = {
    children: PropTypes.node
};

export default withRouter(NavigatorDesktop);
