import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from 'semantic-ui-react';
import './Grid.css';

class PanelGridColumn extends React.Component {
    render() {
        const size = (this.props.size === 'large') ? 'panelGridColumn-large' : 'panelGridColumn';
        const className = (this.props.className) ? `${size} ${this.props.className}` : size;
        return (
            <Grid.Column {...this.props} className={className}>
                {this.props.children}
            </Grid.Column>
        )
    }
}

PanelGridColumn.prototypes = {
    className: PropTypes.string
};

export default PanelGridColumn;