import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'semantic-ui-react';
import './Button.css';

class CancelButton extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoadding: false
        }
    }

    setLoading = (loading) => {
        this.setState({loading: loading})
    }

    render() {
        const className = (this.props.className) ? "cancelButton " + this.props.className : "cancelButton";
        return (
            <Button {...this.props} loading={this.state.loading} disabled={this.state.loading || this.props.disabled} className={className} />
        )
    }
}

CancelButton.prototypes = {
    className: PropTypes.string
};

export default CancelButton;