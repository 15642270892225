import PropTypes from 'prop-types';
import React, { Component } from 'react';
import {
    Container,
    Icon, Image,
    Menu,
    Responsive,
    Segment,
    Sidebar, Visibility,
} from 'semantic-ui-react';
import {Auth, Hub, I18n} from 'aws-amplify';
import { NavLink as Link } from 'react-router-dom';
import { matchRoutes } from 'react-router-config';
import routes from '../../../routes';
import { withRouter } from 'react-router-dom';
import ApiUtil from "../../utils/ApiUtil";
import './Navigator.css';
import MenuWidget from "../menu/Menu";
import MenuItem from "../menu/MenuItem";
import MenuExternalItem from "../menu/MenuExternalItem";

const getWidth = () => {
    const isSSR = typeof window === 'undefined';
    return isSSR ? Responsive.onlyTablet.minWidth : window.innerWidth;
};
class NavigatorMobile extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            sidebarOpened: false,
            hasHeader : false
        };
        this.handleToggle = this.handleToggle.bind(this);
        this.handleGoHome = this.handleGoHome.bind(this);

        this.props.history.listen((location, action) => {
            this.setState({ sidebarOpened: false });
        });

        Hub.listen('loadEducation', (data) => {
            this.getEducationUrl();
        });
        Hub.listen('userLoggedOut', (data) => {
            this.handleSidebarHide();
            this.props.location.pathname = '/signIn';
        });
    }

    componentDidMount() {
        ApiUtil.checkAndHandleLogOut().then(function(response) {
            if(response) {
                this.getEducationUrl();
                /*ApiUtil.initProfile().then(function() {
                    this.getEducationUrl();
                }.bind(this));*/
            }
        }.bind(this));
    }

    async checkAuth() {
        let matchRouter = matchRoutes(routes, this.props.location.pathname);
        if (matchRouter[0]) {
            if (matchRouter[0].route.authAccess) {
                try {
                    await Auth.currentAuthenticatedUser();
                    this.setState({ hasHeader: matchRouter[0].route.header });
                } catch (e) {
                    this.props.history.push('/signIn');
                    this.setState({ hasHeader: false });
                }
            } else {
                this.setState({ hasHeader: false });
            }
        } else {
            this.setState({ hasHeader: false });
        }
    }

    async getEducationUrl() {
        this.checkIfStillLoggedIn();
        await ApiUtil.initProfile();
        try {
            let url = await ApiUtil.getEducationUrl();
            if (url !== null) {
                this.setState({ hasKits: true });
            } else {
                this.setState({ hasKits: false });
            }
        } catch (e) {
            this.setState({ hasKits: false });
            this.checkAndHandleLogOut(e);
        }
    }

    checkIfStillLoggedIn() {
        if (localStorage.getItem('innerFlag') === null || localStorage.getItem('innerFlag') === false) {
            Auth.signOut()
                .then(() => {
                    this.props.history.replace('/signIn');
                })
                .catch(error => {
                    console.error(error);
                    if (error.code === 'NotAuthorizedException') {
                        this.props.history.replace('/signIn');
                    }
                });
        }
    }

    checkAndHandleLogOut = (error) => {
        var logout = false
        if (error.data != null) {
            console.error("Error status : " + error.data.status);
            if (error.data.status === 401) {
                logout = true;
            }
        } else if (error.status != null) {
            console.error("Error status : " + error.status);
            if (error.status === 401) {
                logout = true;
            }
        }

        if (logout) {
            Auth.signOut()
                .then(() => {
                    this.props.history.replace('/signIn');
                })
                .catch(error => {
                    console.error(error);
                    if (error.code === 'NotAuthorizedException') {
                        this.props.history.replace('/signIn');
                    }
                });
        }
    }

    componentWillMount() {
        this.checkAuth();
    }

    handleSidebarHide = () => this.setState({ sidebarOpened: false });

    handleToggle() {

        this.setState({ sidebarOpened: true });
    }

    handleGoHome() {
        this.props.history.push('/myGenetics');
    }

    render() {
        const { children } = this.props;
        const { sidebarOpened, hasHeader } = this.state;
        this.setState(function(state) {
            if(matchRoutes(routes, this.props.location.pathname)[0].route.header && !state.hasHeader) {
                return {
                    hasHeader: matchRoutes(routes, this.props.location.pathname)[0].route.header
                };
            } else if(!matchRoutes(routes, this.props.location.pathname)[0].route.header && state.hasHeader) {
                return {
                    hasHeader: false
                };
            }
        });
        return (
            <Responsive as={Sidebar.Pushable} getWidth={getWidth} maxWidth="1024">
                <Sidebar
                    as={Menu}
                    animation='push'
                    direction='right'
                    onHide={this.handleSidebarHide}
                    vertical
                    visible={sidebarOpened}>
                    <Visibility ref={this.menuPanel} once={false}
                                className="menu-mobile menu-panel"
                                onBottomPassed={this.showFixedMenu}
                                onBottomPassedReverse={this.hideFixedMenu}>
                        {
                            matchRoutes(routes, this.props.location.pathname)[0] &&
                            matchRoutes(routes, this.props.location.pathname)[0].route.header &&
                            (
                                <Menu>
                                    <MenuItem className={this.state.activeItem} text={I18n.get('HomeMenuMyGenetics')} path='/myGenetics' />
                                    {
                                        this.state.hasKits &&
                                        (
                                            <MenuItem text={I18n.get('HomeMenuEducation')} path='/education' />
                                        )
                                    }
                                    <MenuExternalItem text={I18n.get('HomeMenuFAQ')} href='https://seekinghealth.zendesk.com/hc/en-us/sections/360006465413-StrateGene-Genetic-Test-Coming-in-2020-' />
                                </Menu>
                            )
                        }
                    </Visibility>
                    <MenuWidget/>
                </Sidebar>

                <Sidebar.Pusher dimmed={sidebarOpened}>
                    {hasHeader && (
                        <Segment className='clearfix'>
                            <Image className='menu-logo' src='../../images/StrateGene-logo.svg' floated='left' onClick={this.handleGoHome} />
                            <Menu pointing secondary size='large' floated='right'>
                                <Menu.Item onClick={this.handleToggle}>
                                    <Icon name='sidebar' />
                                </Menu.Item>
                            </Menu>
                        </Segment>
                    )}
                    {children}
                </Sidebar.Pusher>
            </Responsive>
        );
    }
}

NavigatorMobile.propTypes = {
    children: PropTypes.node,
};

export default withRouter(NavigatorMobile);
