import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Grid, Dropdown, Image, Loader } from 'semantic-ui-react';
import MenuButton from '../components/widgets/menu/MenuButton';
import { I18n } from 'aws-amplify';
import './Profile.css';
import '../components/widgets/dropdown/Dropdown.css';
import { withRouter } from 'react-router-dom';
import ApiUtil from "../components/utils/ApiUtil";

class Profile extends Component {
    state = {
        selectedKitProfile: {},
        statusImageUrl: null,
        dataFetching: true,
        statusString: '',
        userName: '',
        desc: '',
        profileListDropdownOptions: [],
        selectedDropdownItem: {}
    };

    componentWillReceiveProps(props) {

        if (props.selectedKitProfile.currentStatus !== undefined) {
            const currentStatus = props.selectedKitProfile.currentStatus;
            let userName = `${props.selectedKitProfile.firstName} ${props.selectedKitProfile.lastName}`

            this.setState({
                selectedDropdownItem: {
                    key: props.selectedKitProfile.id,
                    text: userName,
                    value: userName
                }
            });

            var profiles = [];
            for (var index = 0; index < props.profileList.length; index++) {
                const item = {
                    key: props.profileList[index].id,
                    text: `${props.profileList[index].firstName} ${props.profileList[index].lastName}`,
                    value: `${props.profileList[index].firstName} ${props.profileList[index].lastName}`
                };

                profiles.push(item);
            }
            this.setState({
                currentStatus: currentStatus,
                statusImageUrl: '../' + I18n.get(currentStatus + '_img'),
                statusString: I18n.get(currentStatus),
                dataFetching: false,
                userName: userName,
                desc: I18n.get(currentStatus + '_desc'),
                profileListDropdownOptions: profiles
            });
        }
    }

    activateKitButtonOnClick = () => {
        this.props.history.push('/activate');
    };

    handleProfileOnChange = (event, data) => {
        var selectedKitProfile = {};
        this.props.profileList.map((profile) => {
            if (`${profile.firstName} ${profile.lastName}` === data.value) {
                selectedKitProfile = profile;
            }
            return null;
        });
        this.props.onChange(selectedKitProfile);
    }

    render() {

        const dataFetching = this.state.dataFetching;
        return (
            <Grid className='profile-panel-style'>
                {
                    dataFetching === true ?
                        <Loader active /> :
                        <Grid.Row className='profilePage-banner'>
                            <Grid.Column width={12}>
                                <Grid className='profile'>
                                    {
                                        !this.props.isShared && (
                                            <Dropdown className='profile-user-name-style'
                                                onChange={this.handleProfileOnChange}
                                                options={this.state.profileListDropdownOptions}
                                                defaultValue={this.state.selectedDropdownItem.value} />)
                                    }
                                    <div className='bodyTitleLabel padding0' white='true'>{this.state.statusString}</div>
                                    <div className='bodyLabel padding0' white='true' dangerouslySetInnerHTML={{__html: this.state.desc}}></div>
                                    {this.state.currentStatus === 'NO_KIT' && (
                                        <Grid.Row columns={1} textAlign='center' style={{ marginTop: '1rem' }}>
                                            <Grid.Column>
                                                <MenuButton
                                                    className='menu-ctaButton'
                                                    text={I18n.get('HomeMenuBuy')}
                                                    style={{ marginRight: '16px' }}
                                                    href={ApiUtil.getBuyKitUrl()}
                                                />
                                                <MenuButton
                                                    className='menu-ctaButton'
                                                    text={I18n.get('HomeMenuActivate')}
                                                    onClick={this.activateKitButtonOnClick.bind(this)}
                                                />
                                            </Grid.Column>
                                        </Grid.Row>
                                    )}
                                </Grid>
                                <Grid />
                            </Grid.Column>
                            <Grid.Column width={4} className='profileCard-imageColumn'>
                                <Image className='profileCard-profileImage' src={this.state.statusImageUrl} />
                            </Grid.Column>
                        </Grid.Row>
                }
            </Grid>
        );
    }
}

Profile.propTypes = {
    mobile: PropTypes.bool,
    children: PropTypes.node,
    selectedKitProfile: PropTypes.object,
    profileList: PropTypes.array,
    onChange: PropTypes.func
};
export default withRouter(Profile);
